import { SectionListSet } from "../../../components";
import {
  FavoriteKindItem,
  FavoriteProductItem,
  Filter,
  Price,
} from "../../../types";

export const GET_RESULTS = "GET_RESULTS";
export const SET_RESULTS = "SET_RESULTS";
export const SET_FILTERS = "SET_FILTERS";

export const SET_CATEGORIES = "SET_CATEGORIES";

export const SET_FAVORITES = "SET_FAVORITES";
export const SET_FILTER_OPTION = "SET_FILTER_OPTION";

export type FilterState = {
  results: Price[];
  filters: Price[];
  activeFilter: Filter;
  categories: SectionListSet[];
  favorites: (FavoriteKindItem | FavoriteProductItem)[];
  option: Filter | null;
};

export type FilterAction = {
  type: string;
  data: Price[];
};

export type FilterCatAction = {
  type: string;
  categories: SectionListSet[];
};

export type FilterFavAction = {
  type: string;
  favorites: any[];
};

export type FilterOptAction = {
  type: string;
  option: Filter | null;
};
