import {
  faAngleDown,
  faAngleUp,
  faHamburger,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React, { useCallback, useContext, useState } from "react";
import { View } from "react-native";
import BottomDrawer from "react-native-bottom-drawer-view";

import { ThemeContext } from "../utils/contexts";

export const ThemedDrawer = (props: {
  children?:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  visible: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(props.visible);
  const [expanded, setExpanded] = useState(false);

  const themeContext = useContext(ThemeContext);
  const { getStyle } = themeContext.helpers;

  const styles = getStyle("Drawer");
  const sheet = styles?.sheet;

  const { mobileSize } = themeContext.values;

  const onExpanded = (e?: any) => {
    setExpanded(true);
  };

  const onCollapsed = (e?: any) => {
    setExpanded(false);
  };

  if (isVisible) {
    return (
      <BottomDrawer
        style={sheet?.drawer}
        containerHeight={mobileSize.height}
        offset={0}
        borderRadius={5}
        borderBottomLeftRadius={5}
        borderBottomRightRadius={5}
        backgroundColor={"#000"}
        borderTopLeftRadius={5}
        borderTopRightRadius={5}
        onExpanded={onExpanded}
        onCollapsed={onCollapsed}
        // downDisplay={mobileSize.height / 4}
        startUp={true}
      >
        <View
          style={{
            ...sheet?.drawerContainer,
            paddingLeft: mobileSize.width / 2 - 15,
          }}
        >
          <FontAwesomeIcon
            icon={expanded ? faAngleDown : faAngleUp}
            size={30}
            color="gray"
          />
          {props.children}
        </View>
      </BottomDrawer>
    );
  }
  return null;
};
