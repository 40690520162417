import * as Linking from "expo-linking";

export default {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      CategoryDrawerScreen: {
        CategoryScreen: {
          path: "categories-screen",
          screens: {},
        },
      },
      DetailScreen: "categories-screen:kindId",
      ImageDetailScreen: "zoom/:image",
      CheckoutScreen: "checkout",
      OrdersScreen: "orders",
      QrCodeScannerScreen: "select-a-shop",
      QrCodeScannerWebScreen: "shop/:shopId/:tableId",
    },
  },
};
