import { CssSheet } from "../../actions/types";

export const NavigationFilterStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "NavigationFilter",
  sheet: {
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    menuContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
    menuItemsCard: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      borderRadius: 10,
    },
    circleContainer: {
      paddingLeft: "10%",
      justifyContent: "flex-start",
      padding: 10,
    },
  },
};

export const NavigationFilterMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "NavigationFilter",
  sheet: {
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    menuContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
    menuItemsCard: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      borderRadius: 10,
    },
    circleContainer: {
      paddingLeft: "10%",
      justifyContent: "flex-start",
      padding: 10,
    },
  },
};
