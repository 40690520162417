import { CssSheet } from "../../actions/types";

export const OrderTotalStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "OrderNavTotal",
  sheet: {
    orderListTotal: {
      padding: "2%",
    },
  },
};

export const OrderTotalMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "OrderNavTotal",
  sheet: {
    orderListTotal: {
      justifyContent: "flex-start",
    },
  },
};
