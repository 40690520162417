import { RenderOptions } from "../../../types";
import {
  DISABLE_BACK,
  SET_CONFIG,
  SET_RENDER_OPTIONS,
  SET_VERSION,
  ShopConfig,
} from "../types";

const set_config_dispatch = (config: ShopConfig) => ({
  type: SET_CONFIG,
  data: config,
});

const set_version_dispatch = (version: string) => ({
  type: SET_VERSION,
  data: version,
});

const set_hide_button = (state: boolean) => ({
  type: DISABLE_BACK,
  data: state,
});

const set_render_options = (state: RenderOptions) => ({
  type: SET_RENDER_OPTIONS,
  data: state,
});

export {
  set_config_dispatch,
  set_version_dispatch,
  set_hide_button,
  set_render_options,
};
