import { CssSheet } from "../../actions/types";

export const CategoryNewStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "CategorySelectNewScreen",
  sheet: {
    // web
    view: {
      margin: 5,
      alignSelf: "flex-start",
      borderRadius: 5,
      borderWidth: 3,
    },
    container: {
      width: "100%",
      height: "100%",
      // alignItems: "baseline",
      flex: 1,
      // overflow: "scroll",
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
    },
    backgroundImage: {
      flex: 1,
      resizeMode: "contain",
      justifyContent: "center",
    },
    subCategoryTitle: {
      fontWeight: "bold",
      fontSize: 12,
      textAlign: "justify",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      textAlignVertical: "top",
      height: "100%",
      width: "100%",
    },
    button: {
      borderRadius: 100,
      height: 100,
      width: 100,
      alignItems: "center",
      justifyContent: "center",
    },
    row: {
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    wrapper: {
      // overflow: "hidden"
    },
    categoryImage: {
      alignSelf: "center",
      marginVertical: 0,
    },
  },
};

export const CategoryNewMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android"],
  },
  route: "CategorySelectNewScreen",
  sheet: {
    view: {
      margin: 5,
      alignItems: "center",
      alignSelf: "center",
      borderRadius: 20,
      borderWidth: 3,
      borderBottomRightRadius: 20,
      borderBottomLeftRadius: 20,
    },
    cardContainer: {},
    cardImage: {
      flex: 1,
      justifyContent: "flex-start",
      alignContent: "flex-start",
      marginRight: "50%",
      borderRadius: 30,
      borderWidth: 3,
    },
    container: {
      width: "100%",
      height: "100%",
      flex: 1,
      flexDirection: "row",
    },
    list: {
      width: "100%",
      marginLeft: 15,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
    },
    backgroundImage: {
      flex: 1,
      resizeMode: "contain",
      justifyContent: "center",
    },
    subCategoryTitle: {
      fontWeight: "bold",
      flexWrap: "wrap",
      fontSize: 12,
      textAlign: "right",
      justifyContent: "flex-end",
      alignSelf: "flex-end",
      margin: "auto",
      borderWidth: 0,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      height: 25,
      width: "100%",
    },
    button: {
      borderRadius: 100,
      height: 100,
      width: 100,
      alignItems: "center",
      justifyContent: "center",
    },
    row: {
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
    wrapper: {
      paddingTop: 20,
      paddingLeft: "4%",
      paddingRight: "4%",
      width: "100%",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    categoryImage: {
      flexDirection: "row",
      alignSelf: "flex-start",
      marginVertical: 3,
      // border
      borderRadius: 20,
    },
  },
};

export const CategoryNewMobileIosStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["ios"],
  },
  route: "CategorySelectNewScreen",
  sheet: {
    view: {
      margin: 7,
      alignItems: "center",
      alignSelf: "center",
      borderRadius: 10,
      borderWidth: 3,
    },
    container: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
      paddingTop: 20,
    },
    list: {
      width: "100%",
      marginLeft: 15,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
    },
    backgroundImage: {
      flex: 1,
      resizeMode: "contain",
      justifyContent: "center",
    },
    subCategoryTitle: {
      fontWeight: "bold",
      flexWrap: "wrap",
      fontSize: 12,
      textAlign: "center",
      justifyContent: "center",
      alignSelf: "center",
      margin: "auto",
      borderWidth: 0,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      height: 25,
      width: "100%",
    },
    button: {
      borderRadius: 100,
      height: 100,
      width: 100,
      alignItems: "center",
      justifyContent: "center",
    },
    row: {
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
    wrapper: {
      paddingTop: 20,
      paddingLeft: "5%",
    },
    categoryImage: {
      alignSelf: "flex-start",
      marginVertical: 3,
    },
  },
};
