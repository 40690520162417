import React, { memo, useContext, useState } from "react";
import { useEffect } from "react";
import { Platform, Text, View } from "react-native";

import { LanguageContext } from "../utils/Language";
import { switchOnLanguage } from "../utils/Utils";
import { CategoryImage } from "./CategoryImage";

const SectionHeader = (props: any) => {
  const languageContext = useContext(LanguageContext);

  return (
    <View
      style={{
        backgroundColor: props.currentTheme.category.body.backgroundColor,
      }}
    >
      <CategoryImage
        style={props.image}
        key={`${props.title.id}_image`}
        name={
          props.colorTheme === "dark"
            ? String(props.title.category_image_2)
            : String(props.title.category_image_1)
        }
        width={Platform.OS === "web" ? "100%" : props.cardDimensions.width + 20} // Todo make it dynamic again...
        height={props.cardDimensions.height}
      ></CategoryImage>
      <Text
        style={{
          ...props.textInput,
          color: props.currentTheme.category.head.textColor,
          backgroundColor: props.currentTheme.category.head.backgroundColor,
        }}
      >
        {" "}
        {switchOnLanguage(
          props.title.name_en,
          props.title.name,
          languageContext.appLanguage
        )}{" "}
      </Text>
    </View>
  );
};

export default memo(SectionHeader, (prevProps, nextProps) => {
  return prevProps.title === nextProps.title;
});
