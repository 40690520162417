import React, { useEffect, useReducer } from "react";
import {
  Dimensions,
  ImageStyle,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from "react-native";

import { mediaQueryTypes } from "../../constants/Constants";
import {
  set_dimention_dispatch,
  set_orientation_dispatch,
  set_style_sheets,
} from "../actions/dispatches";
import { CssSheet, ThemeState, mediaTypes } from "../actions/types";
import ThemeReducer, { initialThemeState } from "../reducers/ThemeReducer";
import { mediaQueries } from "../Utils";

export type ThemeContextInterface = {
  values: ThemeState;
  newTheme: boolean;
  helpers: {
    setDisplaySize: (width: number, height: number) => void;
    getStyle: (route: string) => CssSheet | undefined;
    setStyle: (route: string, sheet: object) => void;
  };
};

export const ThemeContext = React.createContext<ThemeContextInterface>({
  values: initialThemeState,
  newTheme: true,
  helpers: {
    setDisplaySize: (width: number, height: number) => {},
    getStyle: (route: string) => {
      // route THIS NEEDS TO CHANGE FOR FULL WHITE LABEL! (BACKEND SUPPORT)
      for (const style of initialThemeState.styles) {
        if (style.route === route) {
          for (const os of style.platform.os) {
            for (const stateOs of initialThemeState.platform.os) {
              if (stateOs == os) {
                return style;
              }
            }
          }
        }
      }
    },
    setStyle: (route: string, sheet: object) => {},
  },
});

const ThemeProvider = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  // here is where we will set the theme resize and color pallets
  const [state, dispatch] = useReducer(ThemeReducer, initialThemeState);
  const setDisplaySize = (width: number, height: number) => {
    dispatch(set_dimention_dispatch(width, height));
  };

  // const setDisplayType = ()

  const setPortrait = (isPortrait: boolean) => {
    dispatch(set_orientation_dispatch(isPortrait));
  };

  // listeners
  const handleResize = () => {
    const width = Dimensions.get("window").width;
    const height = Dimensions.get("window").height;
    setDisplaySize(width, height);
  };

  const handleMobileResize = () => {
    const width = Dimensions.get("window").width;
    const height = Dimensions.get("window").height;
    if (width < height) {
      // set Protrait screen mode
      setPortrait(true);
    } else {
      // set Landscape screen mode
      setPortrait(false);
    }
  };

  // for now this fucntion will be called with hard coded data
  // later data moves to server
  const setStyle = (styles: any) => {
    // state
    dispatch(set_style_sheets(styles));
  };

  const mapMediaQueryToSheet = (style: any, mediaQuery: any) => {
    for (const propKey of Object.keys(mediaQuery)) {
      // for (const mediaKey of Object.keys(style.sheet))
      for (const propertyKey of Object.keys(mediaQuery[propKey])) {
        if (style.sheet[propKey]) {
          style.sheet[propKey][propertyKey] = mediaQuery[propKey][propertyKey];
        }
      }
    }
    return style;
  };

  /**
   * These will be the functions that will get the stylesheet from the backend
   * @param route The route coresponding to this stylesheet
   */
  const getStyle = (route: string): CssSheet | undefined => {
    // route
    for (const style of state.styles) {
      if (style.route === route) {
        for (const os of style.platform.os) {
          for (const stateOs of state.platform.os) {
            if (stateOs == os) {
              if (style.queries) {
                for (const query of style.queries) {
                  if (mediaQueries() == query?.view) {
                    if (query?.sheet)
                      return mapMediaQueryToSheet(style, query.sheet);
                  }
                }
              }
              return style;
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (state.platform.isWeb == true)
      Dimensions.addEventListener("change", handleResize);
    if (state.platform.isAndroid || state.platform.isIos)
      Dimensions.addEventListener("change", handleMobileResize);
    return () => {
      if (state.platform.isWeb)
        Dimensions.removeEventListener("change", handleResize);
      if (state.platform.isAndroid || state.platform.isIos)
        Dimensions.removeEventListener("change", handleMobileResize);
    };
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        values: state,
        helpers: {
          setDisplaySize: setDisplaySize,
          getStyle: getStyle,
          setStyle: setStyle,
        },
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
