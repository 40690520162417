import { CssSheet } from "../../actions/types";

export const ImageDetailScreenStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["web"],
  },
  route: "ImageDetailScreen",
  sheet: {
    container: {
      flex: 1,
      backgroundColor: "white",
    },
    slideContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    scroll: {
      alignSelf: "center",
    },
    usageContainer: {
      justifyContent: "center",
      alignItems: "center",
    },
    usageTip: {
      fontStyle: "italic",
      marginBottom: 20,
      fontFamily: "Roboto_400Regular",
    },
  },
};

export const ImageDetailScreenMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "ImageDetailScreen",
  sheet: {
    container: {
      flex: 1,
      backgroundColor: "white",
    },
    slideContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    scroll: {
      alignSelf: "center",
    },
    usageContainer: {
      justifyContent: "center",
      alignItems: "center",
    },
    usageTip: {
      fontStyle: "italic",
      marginBottom: 20,
      fontFamily: "Roboto_400Regular",
    },
  },
};
