import { CssSheet } from "../../actions/types";

export const OrdersScreenStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "OrdersScreen",
  sheet: {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
    },
    containerContent: {
      alignItems: "center",
      justifyContent: "center",
    },
    list: {
      width: "100%",
    },
    title: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 22,
      marginBottom: 10,
    },
    separator: {
      marginVertical: 30,
      marginTop: 1,
      height: 1,
      width: "100%",
    },
    renderItemRow: {
      height: 50,
    },
    contentRow: {
      flex: 1,
      flexDirection: "row",
    },
    price: {
      marginLeft: 10,
      marginRight: 0,
      fontSize: 15,
      width: 75,
    },
    reloadButton: {
      paddingLeft: 20,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
    },
    orderId: {
      fontWeight: "bold",
      width: 100,
    },
    tableName: {
      width: 80,
    },
  },
};

export const OrdersScreenMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "OrdersScreen",
  sheet: {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
    },
    containerContent: {
      alignItems: "center",
      justifyContent: "center",
    },
    list: {
      width: "100%",
    },
    title: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 22,
      marginBottom: 10,
    },
    separator: {
      marginVertical: 30,
      marginTop: 1,
      height: 1,
      width: "100%",
    },
    renderItemRow: {
      height: 50,
    },
    contentRow: {
      flex: 1,
      flexDirection: "row",
    },
    price: {
      marginLeft: 10,
      marginRight: 0,
      fontSize: 15,
      width: 75,
    },
    reloadButton: {
      paddingLeft: 20,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
    },
    orderId: {
      fontWeight: "bold",
      width: 100,
    },
    tableName: {
      width: 80,
    },
  },
};
