import { Apple } from "@material-ui/icons";
import I18n from "i18n-js";
import React, { useContext, useEffect, useState } from "react";
import { Dimensions, Image, Text, View, useColorScheme } from "react-native";

import { AppStoreButton } from "../../components/AppStoreButton";
import { APPSTORE } from "../../constants/Constants";
import { RenderOptions } from "../../types";
import { ShopContext, ThemeContext } from "../../utils/contexts";
import { getTheme } from "../../utils/Utils";

export default function DownloadAppScreen({
  renderCallback,
}: {
  renderCallback: (renderOptions: RenderOptions) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [introVisible, setIntroVisible] = useState(true);
  const colorTheme = useColorScheme();

  const shopContext = useContext(ShopContext);
  const themeContext = useContext(ThemeContext);

  const { setStyle, getStyle } = themeContext.helpers;
  const { styles } = themeContext.values;

  useEffect(() => {
    if (styles.length == 0) {
      setStyle("", {});
    }
  }, [styles]);

  const style = getStyle("DownloadAppScreen");
  const sheet = style?.sheet;

  const height = Dimensions.get("window").height;

  const { theme } = shopContext.values;

  const currentTheme = getTheme(theme)[colorTheme];
  // Ensure QR code scanner state is initial when screen is focused again

  const iOSUrl = APPSTORE.appAppStoreUrl;

  const googleUrl = APPSTORE.googlePlayStoreAppUrl;
  // @ts-ignore
  return (
    <View
      style={{
        ...sheet?.container,
        backgroundColor: currentTheme.appStore?.backgroundColor,
      }}
    >
      <View
        style={{
          ...sheet?.content,
          justifyContent: "center",
          height: height,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "flex-end",
            height: 200,
          }}
        >
          <Text
            style={{
              fontSize: 25,
              justifyContent: "center",
              textAlignVertical: "center",
              fontFamily: "Roboto_400Regular",
              alignSelf: "center",
              textAlign: "center",
              color: currentTheme.appStore.textColor,
            }}
          >
            {I18n.t("appStore.title")}
          </Text>
          <Text
            style={{
              fontSize: 25,
              paddingTop: 10,
              paddingHorizontal: 20,
              justifyContent: "center",
              fontFamily: "Roboto_400Regular",
              textAlign: "center",
              color: currentTheme.appStore.subtitleColor,
            }}
          >
            {I18n.t("appStore.subTitle")}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <AppStoreButton
              Icon={Apple}
              styles={{
                justifyContent: "center",
                padding: 10,
              }}
              title={I18n.t("appStore.button.apple.title")}
              subtitle={I18n.t("appStore.button.apple.subtitle")}
              urlRedirect={iOSUrl}
            />
            <AppStoreButton
              imageUrl={APPSTORE.playStoreImage}
              styles={{
                justifyContent: "center",
                padding: 10,
              }}
              title={I18n.t("appStore.button.google.title")}
              subtitle={I18n.t("appStore.button.google.subtitle")}
              urlRedirect={googleUrl}
            />
          </View>
          <View
            style={{
              justifyContent: "center",
              padding: 10,
              width: "100%",
            }}
          >
            <Image
              style={{
                justifyContent: "center",
                // ...sheet?.logo,
                padding: 10,
                width: "100%",
                height: "100%",
                shadowColor: currentTheme.appStore?.shadowColor,
                shadowOffset: {
                  width: 2,
                  height: 6,
                },
                shadowOpacity: 0.39,
                shadowRadius: 8.3,
              }}
              source={APPSTORE.appStoreImage}
              resizeMode="contain"
            />
          </View>
        </View>
      </View>
    </View>
  );
}
