import { BarCodeScanner } from "expo-barcode-scanner";
import React, { useEffect, useState } from "react";
import { Button, StyleSheet, Text, View } from "react-native";

import { SHOP_ID } from "../constants/Constants";
import { OrderTokenStore } from "../types";

function delay(time: number) {
  return new Promise(function (resolve, reject) {
    setTimeout(() => resolve(), time);
  });
}

export default function QrCodeScanner({
  handleQrCode,
  width,
  height,
}: {
  handleQrCode: any;
  width: number;
  height: number;
}) {
  const [hasPermission, setHasPermission] = useState<null | boolean>(null);
  const [scanned, setScanned] = useState(false);
  const [scannedData, setScannedData] = useState("");

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === "granted");
    })();
  }, []);

  // @ts-ignore
  const handleBarCodeScanned = async ({ data }) => {
    console.log("QRdata: ", data);
    setScanned(true);

    await delay(500);

    if (data.startsWith("https://app.prijslijst.info/shop/")) {
      const shopAndTable = data
        .replace("https://app.prijslijst.info/shop/", "")
        .split("/");
      if (shopAndTable.length !== 2) {
        alert("This doesn't look like a valid prijslijst.info QR code!");
      }
      console.log(
        `Handling shopId: ${shopAndTable[0]} with tableId: ${shopAndTable[1]}`
      );
      handleQrCode(shopAndTable[0], shopAndTable[1]);
    } else {
      alert("This doesn't look like a valid prijslijst.info QR code!");
      // @ts-ignore
      setTimeout(() => {
        // @ts-ignore
        setScanned(false);
      }, 3000);
    }
  };

  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  // @ts-ignore
  return (
    <View
      style={{
        width: width,
        height: height,
        borderRadius: 10,
      }}
    >
      {!scanned && (
        <BarCodeScanner
          onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
          style={{
            ...StyleSheet.absoluteFillObject,
            borderRadius: 10,
          }}
        />
      )}
    </View>
  );
}
