import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  ContactSupportOutlined,
  CropLandscapeOutlined,
} from "@material-ui/icons";
import { StackScreenProps } from "@react-navigation/stack";
import I18n from "i18n-js";
import { debounce, throttle } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FlatList, Platform, SectionList, Text, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { useQuery } from "react-query";

import {
  FilterList,
  FilterMainCategory,
  MainCategory,
  PlaceholderCardWrapper,
  SearchInputComponent,
  SearchResult,
  SectionListSet,
  SubCategory,
} from "../../components";
import Card from "../../components/Card";
import SectionHeader from "../../components/SectionHeader";
import {
  CATEGORY_ICONS,
  FILTEROPTIONS,
  LOGOOS,
  PREFEREDSORT,
} from "../../constants/Constants";
import useColorScheme from "../../hooks/useColorScheme";
import {
  AppLanguage,
  FavoriteKindItem,
  FavoriteProductItem,
  Price,
  RootStackParamList,
  Sort,
} from "../../types";
import { Subject } from "../../utils/actions/observers/observer";
import {
  BaseContext,
  FilterContext,
  ShopContext,
  ThemeContext,
} from "../../utils/contexts";
import { LanguageContext } from "../../utils/Language";
import {
  createFavorite,
  formatMoney,
  getFavoriteId,
  getFavorites,
  getProductInfo,
  getTheme,
  handleFavorite,
  isFavorite,
  switchOnLanguage,
  uuid,
} from "../../utils/Utils";

interface ISectionListHeader {
  title: MainCategory;
}

interface ISectionListData {
  data: SubCategory;
}

interface IPlace {
  from: number;
  to: number;
}

let count = 0;

const CategorySelectScreen = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList>) => {
  const colorTheme = useColorScheme();
  const languageContext = useContext(LanguageContext);
  const shopContext = useContext(ShopContext);
  const themeContext = useContext(ThemeContext);
  const baseContext = useContext(BaseContext);
  const filterContext = useContext(FilterContext);

  const { shopId, theme } = shopContext.values;
  const { displaySize, platform, mobileSize } = themeContext.values;
  const {
    results,
    filters,
    activeFilter,
    categories,
    favorites,
  } = filterContext.values;

  const { getStyle } = themeContext.helpers;
  const {
    setFilters,
    setResults,
    setCategories,
    setFavoritesGUI,
  } = filterContext.helpers;

  const currentTheme = getTheme(theme)[colorTheme];

  const logo = LOGOOS[theme];
  const subject = new Subject();
  // for now we only use the new category screen for the mississippi shop to
  // allow the content creators some time for filling the categories with photo's
  const styles = getStyle(route.name);
  const sheet = styles?.sheet;

  const listRef = useRef<any>();
  const [searchVisible, setSearchVisible] = useState(false);
  const { api } = baseContext.values;
  // api.v1.shops.get.clear();
  // console.log('id => ', api.v1.shops.get.id(shopId).getUrl(),  api.v1.shops.get.getKey())
  // api.v1.shops.get.getKey()
  //

  const useData = () => {
    return useQuery(
      [api.v1.shops.get.setKey(`shopData-${shopId}`).getKey()],
      // STATE BINDING IS REQUIRIED !!!
      () =>
        fetch(api.v1.shops.get.clear().id(shopId).getUrl()).then((res: any) =>
          res.json()
        )
    );
  };
  // `shopData-${shopId}`
  //

  const { isLoading, data, error } = useData();

  if (error) {
    // @ts-ignore
    return <Text>An error has occurred: {error.message}</Text>;
  }

  let length = 0;

  const [filter, setFilter] = useState<FilterMainCategory[]>([]);
  const [filterOpened, setFilterOpened] = useState<boolean>(false);

  let subCategoriesIDs = Array.from(
    new Set(data?.prices?.map((s: Price) => s.category_id))
  );

  const switchOnImage = (item: Price) => {
    if (item?.kind_id) {
      return item.kind_image;
    } else {
      return item?.product_image;
    }
  };

  const calculateScrollIndexPerDevice = (numberOfObjects: number) => {
    return (
      numberOfObjects *
      calculateAspectHeightRatioRaw(mobileSize.height / 2.8, 5, 2.2)
    );
  };

  const switchOnTitle = (item: Price) => {
    if (item?.kind_name) {
      return item.kind_name;
    } else {
      return item.product_name;
    }
  };

  const switchOnKind = (item: Price, lang: AppLanguage) => {
    if (item.kind_i) {
      return "indica";
    } else if (item?.kind_c) {
      return "CBD";
    } else if (item?.kind_s) {
      return "sativa";
    } else if (item?.kind_h) {
      return "hybrid";
    } else {
      return "";
    }
  };

  const switchOnPrice = (item: any, lang: AppLanguage) => {
    if (item.half) {
      return formatMoney(item.half);
    }
    if (item.one) {
      return formatMoney(item.one);
    }
    if (item.two_five) {
      return formatMoney(item.two_five);
    }
    if (item.five) {
      return formatMoney(item.five);
    }
    if (item.joint) {
      return formatMoney(item.joint);
    }
    if (item.piece) {
      return formatMoney(item.piece);
    }
  };
  // main on alfabet
  //

  const sortByMainCategory = (items: SubCategory[]) => {
    const results: SubCategory[] = [];

    const sortedArrById = items.sort((a, b) => {
      return a.main_category_order_number - b.main_category_order_number;
    });

    // apply external filter if shop has
    const externalSort = PREFEREDSORT(shopId);
    if (externalSort.length > 0) {
      externalSort.forEach((el: Sort) => {
        var found = false;
        sortedArrById.forEach((item) => {
          if (!found && item.name == el.value) {
            // item.sortTitle = el.title;
            results.push(item);
            found = true;
            return false;
          } else return true;
        });
      });
      return results;
    } else return sortedArrById;
  };

  useEffect(() => {
    getFavorites(shopId)
      .then((favorites) => {
        setFavoritesGUI(favorites);
        return true;
      })
      .then(() => {
        let fromIndex = 0;
        let toIndex = 0;
        const tempArr: SectionListSet[] = [];
        const tempFilter = [];
        if (!data && !data?.prices) if (data?.prices?.length <= 0) return;
        const sub = subCategoriesIDs.map(
          (i): SubCategory => {
            const category = data.prices.find(
              (p: Price) => p.category_id === i
            );
            length += 1;
            return {
              id: i,
              name: category.category_name,
              name_en: category.category_name_en,
              order_number: category.category_order_number,
              icon: CATEGORY_ICONS.find(
                (icon) => icon.iconName === category.category_icon
              ),
              main_category_id: category.main_category_id,
              category_id: category.category_id,
              category_color: category.category_color,
              category_image_1: category.category_image_1,
              category_image_2: category.category_image_2,
              kind_name: category.kind_name,
              kind_product: category.kind_product,
              kind_id: category?.kind_id,
              image: switchOnImage(category),
              product_id: category?.product_id,
              product_name: category.product_name,
              product_short_description_en:
                category.product_short_description_en,
              product_short_description_nl:
                category.product_short_description_nl,
              active: category.active,
              internal_product_id: category.internal_product_id,
              kind_short_description_nl: category.kind_short_description_nl,
              kind_short_description_en: category.kind_short_description_en,
              title: switchOnTitle(category),
              gramPrice: switchOnPrice(category, "nl"),
              jointPrice: switchOnPrice(category, "nl"),
              kind: switchOnKind(category, "nl"),
              subTitle: category,
            };
          }
        );
        let index = 1;
        const sortedSubCategories = sortByMainCategory(sub);
        for (const category of sortedSubCategories) {
          const prices = data.prices
            .filter((i: Price) => {
              return i.category_id == category.category_id && category.active;
            })
            .map((price: Price) => {
              return {
                id: price.id,
                name: price.category_name,
                name_en: price.category_name_en,
                order_number: price.category_order_number,
                icon: CATEGORY_ICONS.find(
                  (icon) => icon.iconName === price.category_icon
                ),
                main_category_id: price.main_category_id,
                category_id: price.category_id,
                category_color: price.category_color,
                kind_name: price.kind_name,
                kind_product: price.kind_product,
                kind_id: price?.kind_id,
                image: switchOnImage(price),
                product_id: price?.product_id,
                product_name: price.product_name,
                product_short_description_en:
                  price.product_short_description_en,
                product_short_description_nl:
                  price.product_short_description_nl,
                active: price.active,
                kind_short_description_nl: price.kind_short_description_nl,
                kind_short_description_en: price.kind_short_description_en,
                internal_product_id: price?.internal_product_id,
                title: switchOnTitle(price),
                gramPrice: switchOnPrice(price, languageContext.appLanguage),
                jointPrice: switchOnPrice(price, languageContext.appLanguage),
                kind: switchOnKind(price, languageContext.appLanguage),
                subTitle: price,
              };
            });

          // set categories
          //
          tempArr.push({
            title: category,
            data: prices,
            index: index,
          });

          toIndex += calculateScrollIndexPerDevice(prices.length);
          tempFilter.push({
            id: category.id,
            name: category.name_en
              ? languageContext.appLanguage == "nl"
                ? category.name
                : category.name_en
              : category.name,
            index: index,
            from: fromIndex,
            to: toIndex,
          });
          index += 1;
          fromIndex = toIndex;
          setFilter(tempFilter);
          setCategories(tempArr);
        }
      });
  }, [data, languageContext, shopContext]);

  useEffect(() => {
    console.log("THEME CHANGE");
  }, [themeContext]);

  const formatSearch = useCallback(
    (result: SearchResult) => {
      const filter = categories.filter((set) =>
        result.mains.find((main) => set.title.main_category_id == main)
      );

      const matched: Price[] = [];
      for (const set of filter) {
        matched.push(
          ...set.data.filter((cat) => result.ids.find((sub) => sub === cat.id))
        );
      }
      setResults(matched);
    },
    [categories]
  );

  const calculateAspectHeightRatio = (
    val: number,
    ratioWidth: number,
    ratioHeight: number
  ) => {
    return Math.round((val / ratioWidth) * ratioHeight);
  };

  const calculateAspectHeightRatioRaw = (
    val: number,
    ratioWidth: number,
    ratioHeight: number
  ) => {
    return (val / ratioWidth) * ratioHeight;
  };

  const [cardDimensions] = React.useState({
    width: platform.isWeb
      ? displaySize.width / 2
      : Math.round(mobileSize.width),
    height: platform.isWeb
      ? calculateAspectHeightRatio(displaySize.width / 1.9, 5, 2.2)
      : calculateAspectHeightRatio(mobileSize.width / 2, 5, 2.2),
  });

  const onScrollThrottle = throttle((e) => {
    subject.onScrollCalculated(Math.round(e?.nativeEvent?.contentOffset?.y));
  }, 200);

  const onScrollRawEvent = (e: any) => {
    if (Platform.OS !== "web") {
      e.persist();
    }
    return onScrollThrottle(e);
  };

  const keyExtractor = useCallback((item: any) => {
    return item.id ? item.id.toString() : uuid();
  }, []);

  const header = (items: any) => (
    <SectionHeader
      title={items.section.title}
      currentTheme={currentTheme}
      textInput={sheet?.textInput}
      image={sheet?.image}
      cardDimensions={cardDimensions}
    />
  );

  const section = useCallback(
    (data: any) => {
      return (
        <Card
          // key={`${data.item.id}_sub_card_${uuid()}`}
          navigation={navigation}
          image={{
            name: data.item.image,
            width: Number(sheet?.cardImage?.width),
            height: Number(sheet?.cardImage?.height),
            borderRadius: Number(sheet?.cardImage?.borderRadius),
            borderWidth: Number(sheet?.cardImage?.borderWidth),
          }}
          onFavoriteClick={onFavoriteCallback}
          searchLength={results.length}
          shopId={shopId}
          title={data.item.title}
          subTitle={data.item.subTitle}
          gramPrice={data.item.gramPrice}
          grams={data.item.kind_id ? "1 gram" : I18n.t("productsScreen.piece")}
          // isFavorite={test()}
          joint={data.item.kind_id ? "1 joint" : ""}
          jointPrice={data.item.kind_id ? data.item.jointPrice : ""}
          isFavorite={isFavorite(data.item, favorites)}
          kind_i={data.item.kind}
          kind_id={data.item.kind_id}
          product_id={data.item.product_id}
          internal_product_id={data.item.internal_product_id}
        />
      );
    },
    [data, results, filters, languageContext]
  );

  const scrollToLocation = useCallback((result: FilterMainCategory) => {
    if (!listRef?.current?.scrollToLocation) {
      return;
    }
    listRef.current.scrollToLocation({
      sectionIndex: result.index - 1,
      itemIndex: 0,
    });
  }, []);

  const onSearchCallback = useCallback(
    (result: SearchResult) => {
      if (result.categories.length > 0) {
        formatSearch(result);
      } else {
        setResults([]);
      }
    },
    [categories]
  );

  const onFavoriteCallback = async (id: string | undefined) => {
    data?.prices.forEach((x: any) => {
      const creatable = createFavorite(x);
      const priceId = getFavoriteId(creatable);
      if (priceId === id) {
        return handleFavorite(creatable, shopId)
          .then((newFavorites) => {
            setFavoritesGUI(newFavorites);
            return;
          })
          .catch((newFavorites) => {
            // here is ehree we should display something to customers if error
            setFavoritesGUI(newFavorites);
            return;
          });
      }
    });
  };

  const onSearchClose = () => {
    setFilters([], FILTEROPTIONS(shopId)[0]);
  };

  const found = ["kind_name", "product_name"];

  if (categories?.length <= 0 || data?.length <= 0) {
    return <ActivityIndicator color={currentTheme.product.title.color} />;
  }
  return (
    <View style={sheet?.container}>
      <View style={sheet?.bodyFiller}></View>
      <View
        style={{
          ...sheet?.body,
          backgroundColor: currentTheme.category.body.backgroundColor,
        }}
      >
        {categories?.length > 0 ? (
          <View style={{ ...sheet?.pageNameStack }}>
            <SearchInputComponent
              style={sheet?.cupertinoSearchBarWithMic}
              filterMainCategories={filter}
              onScrollEvent={onScrollRawEvent}
              onFilterSelect={scrollToLocation}
              dataset={categories}
              searchOpened={searchVisible}
              subject={subject}
              setSearchOpened={setSearchVisible}
              onClose={onSearchClose}
              subField={"subCategories"}
              onSearchResultFound={onSearchCallback}
              searchFields={found}
              results={filters.length > 0 ? filters : []}
              searchLength={
                filters.length > 0 ? filters.length : results.length
              }
              filterOpened={filterOpened}
              setFilterOpened={setFilterOpened}
            />
          </View>
        ) : null}
        {filterOpened ? (
          <FilterList
            setSearchFilters={setFilters}
            setFilterOpened={setFilterOpened}
            selectedOption={activeFilter}
            // searchIsOppened={}
            data={categories}
            favorites={favorites}
          />
        ) : null}
        {categories.length == 0 ? (
          <PlaceholderCardWrapper />
        ) : filters.length > 0 || results.length > 0 ? (
          <FlatList
            style={{
              ...sheet?.filterList,
            }}
            data={results.length > 0 ? results : filters}
            removeClippedSubviews={true}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => section({ item: item })}
          />
        ) : (
          <SectionList
            style={{
              zIndex: 10,
              marginTop: searchVisible ? 0 : -44,
            }}
            ref={listRef}
            onScroll={onScrollRawEvent}
            showsVerticalScrollIndicator={false}
            sections={categories}
            renderItem={section}
            renderSectionHeader={header}
            keyExtractor={keyExtractor}
            initialNumToRender={data?.length}
          />
        )}
      </View>
    </View>
  );
};

export default CategorySelectScreen;
