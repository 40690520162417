import {
  IconDefinition,
  faFilter,
  faSquareFull,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { isArray } from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMemo } from "react";
import {
  ScrollView,
  Text,
  TextInput,
  TouchableHighlight,
  View,
} from "react-native";
import MaterialCommunityIconsIcon from "react-native-vector-icons/MaterialCommunityIcons";

import useColorScheme from "../hooks/useColorScheme";
import { Price } from "../types";
import { Observer, Subject } from "../utils/actions/observers/observer";
import { FilterContext, ShopContext, ThemeContext } from "../utils/contexts";
import { LanguageContext } from "../utils/Language";
import { getProductInfo, getTheme } from "../utils/Utils";
import { SectionListSet } from "./CategoryIconList";
import { FilterMainCategory, SearchElement, SearchResult, Searchable } from ".";

export const SearchInputComponent = ({
  dataset,
  style,
  searchFields,
  filterMainCategories,
  onFilterSelect,
  onScrollEvent,
  subject,
  subField,
  results,
  searchOpened,
  setSearchOpened,
  onSearchResultFound,
  filterOpened,
  onClose,
  setFilterOpened,
  searchLength,
}: {
  dataset: SectionListSet[];
  style: any;
  // activeIndex: number;
  onScrollEvent: any;
  searchFields: string[];
  searchOpened: boolean;
  subject: Subject;
  setSearchOpened: Dispatch<React.SetStateAction<boolean>>;
  filterMainCategories?: FilterMainCategory[];
  results: Price[];
  onFilterSelect?: (result: FilterMainCategory) => void;
  subField?: string;
  onClose: () => void;
  onSearchResultFound: (result: SearchResult) => void;
  filterOpened: boolean;
  searchLength: number;
  setFilterOpened: Dispatch<SetStateAction<boolean>>;
}) => {
  const colorTheme = useColorScheme();
  const shopContext = useContext(ShopContext);
  const themeContext = useContext(ThemeContext);
  const filterContext = useContext(FilterContext);
  const languageContext = useContext(LanguageContext);

  const { theme } = shopContext.values;

  const { getStyle } = themeContext.helpers;
  const { displaySize } = themeContext.values;
  const currentTheme = getTheme(theme)[colorTheme];

  const [searchable, setSearchable] = useState<SearchElement[]>([]);
  const scrollRef = useRef<any>();
  const [scrollIndex, setScrollIndex] = useState<number>(0);

  const styles = getStyle("SearchInputComponent");
  const sheet = styles?.sheet;

  const scrollObserver: Observer = new Observer((input: any) => {
    setScrollIndex(input.state);
    scrollRef.current.scrollTo({
      x: input.state / (displaySize.height / 30),
    });
  });

  useEffect(() => {
    subject.attach(scrollObserver);
    if (results.length > 0) convertData(results);
    else convertData(dataset);
  }, [results, filterMainCategories]);

  const convertData = (dataset: SectionListSet[] | Price[]) => {
    if (dataset[0].hasOwnProperty("data"))
      convertSearchableFromSectionListSet(dataset as SectionListSet[]);
    else convertSearchableFromPrice(dataset as Price[]);
  };

  const convertSearchableFromSectionListSet = (set: SectionListSet[]) => {
    const searchArr: SearchElement[] = [];
    for (const el in dataset) {
      for (let index = 0; index < dataset[el].data.length; index++) {
        const price = dataset[el].data[index] as any;
        const entity: SearchElement = {
          main_id: price.main_category_id,
          category_id: price.category_id,
          id: price.id,
          index: Number(el),
          searchable: [],
        };
        for (const arg of searchFields) {
          if (price[arg] !== undefined && price[arg] !== null) {
            entity.searchable.push(price[arg]);
          }
        }
        const result = getProductInfo(
          price.subTitle,
          languageContext.appLanguage
        );
        if (result) {
          if (isArray(result)) {
            entity.searchable = entity.searchable.concat(result);
          } else {
            entity.searchable.push(result);
          }
        }
        searchArr.push(entity);
      }
    }
    setSearchable(searchArr);
  };

  const convertSearchableFromPrice = (prices: Price[]) => {
    const searchArr: SearchElement[] = [];
    for (let index = 0; index < prices.length; index++) {
      const price = prices[index] as any;
      const entity: SearchElement = {
        main_id: price.main_category_id,
        category_id: price.category_id,
        id: price.id,
        index: Number(index),
        searchable: [],
      };
      for (const arg of searchFields) {
        if (price[arg] !== undefined && price[arg] !== null) {
          entity.searchable.push(price[arg]);
        }
      }
      const result = getProductInfo(
        price.subTitle,
        languageContext.appLanguage
      );
      if (result) {
        if (isArray(result)) {
          entity.searchable = entity.searchable.concat(result);
        } else {
          entity.searchable.push(result);
        }
      }
      searchArr.push(entity);
    }
    setSearchable(searchArr);
  };

  const isEmpty = (str: string) => {
    return !str || str.length === 0;
  };

  const search = (searchParam: string): Promise<SearchResult> =>
    new Promise((reslove, reject) => {
      const found: SearchResult = {
        mains: [],
        categories: [],
        ids: [],
      };
      searchable.forEach((element: SearchElement) => {
        element.searchable.forEach((match: String) => {
          if (match?.toLowerCase().includes(searchParam.toLowerCase())) {
            found.mains.push(element.main_id);
            found.categories.push(element.category_id);
            found.ids.push(element.id);
          }
        });
      });
      reslove(found);
    });

  const onSearch = (inputValue: string) => {
    if (isEmpty(inputValue)) {
      onSearchResultFound({
        mains: [],
        categories: [],
        ids: [],
      });
    } else {
      search(inputValue).then((result: SearchResult) => {
        onSearchResultFound(result);
      });
    }
  };

  const setSearchVisible = (value: boolean) => {
    if (!value) {
      onSearch("");
    }
    // setSearchVisible(value);
  };

  const scrollView = useCallback(() => {
    return (
      <ScrollView horizontal={true} key={4} ref={scrollRef}>
        {filterMainCategories?.map(
          (filter: FilterMainCategory, index: number) => (
            <Text
              key={`filtername-${index}`}
              onPress={(e) => {
                if (onFilterSelect && searchLength <= 0) {
                  onFilterSelect(filter);
                }
              }}
              style={
                filter.from <= scrollIndex && scrollIndex < filter.to
                  ? {
                      color: currentTheme.search.textColor,
                      opacity: 1,
                      fontSize: 17,
                      marginTop: -2,
                      marginRight: 2,
                    }
                  : {
                      color: currentTheme.search.textColor,
                      opacity: 0.6,
                      fontSize: 15,
                      marginRight: 2,
                    }
              }
            >
              {" "}
              {filter.name}{" "}
            </Text>
          )
        )}
      </ScrollView>
    );
  }, [scrollIndex, filterMainCategories]);

  const onSearchPress = () => {
    if (searchOpened) {
      setSearchOpened(false);
      setSearchVisible(false);
      setFilterOpened(false);
      onClose();
    } else {
      setSearchOpened(true);
    }
  };

  return (
    <View
      key={1}
      style={[
        sheet?.wrapper,
        style,
        {
          zIndex: 2000,
          backgroundColor: currentTheme.search.backgroundColor,
        },
      ]}
    >
      <View
        key={2}
        style={[
          sheet?.container,
          {
            backgroundColor: currentTheme.search.backgroundColor,
            height: 40,
          },
        ]}
      >
        <TouchableHighlight onPress={onSearchPress}>
          <MaterialCommunityIconsIcon
            key={3}
            name="magnify"
            style={{
              ...sheet?.inputLeftIcon,
              color: currentTheme.search.searchIconColor,
            }}
          />
        </TouchableHighlight>
        {scrollView()}
      </View>
      {searchOpened ? (
        <View
          key={5}
          style={[
            sheet?.container,
            {
              backgroundColor: currentTheme.search.backgroundColor,
            },
          ]}
        >
          <View
            style={{
              ...sheet?.inputBox,
              backgroundColor: currentTheme.search.searchInputBackgroundColor,
            }}
          >
            <MaterialCommunityIconsIcon
              name="magnify"
              style={sheet?.inputLeftIcon}
            />
            <TextInput
              placeholder="Search"
              placeholderTextColor={currentTheme.search.placeholderColor}
              style={{ ...sheet?.inputStyle }}
              onChangeText={onSearch}
            />
          </View>
          <View style={{ ...sheet?.image }}>
            <TouchableHighlight onPress={() => setFilterOpened(!filterOpened)}>
              <View
                style={{
                  padding: 5,
                  backgroundColor:
                    filterContext.values.activeFilter.value === "reset"
                      ? currentTheme.search.backgroundColor
                      : currentTheme.filter.options.backgroundColorSelected,
                }}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  size={20}
                  color={currentTheme.search.textColor}
                />
              </View>
            </TouchableHighlight>
          </View>
          <View style={sheet?.imageClose}>
            <MaterialCommunityIconsIcon
              name="close"
              onPress={() => {
                onSearchPress();
                setSearchVisible(false);
                setFilterOpened(false);
                onClose();
              }}
              size={35}
              //colorTheme
              color={currentTheme.search.textColor}
            />
          </View>
        </View>
      ) : null}
    </View>
  );
};
