import { FontAwesome } from "@expo/vector-icons";
import { useIsFocused, useRoute } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import I18n from "i18n-js";
import * as React from "react";
import { useContext } from "react";
import {
  ActivityIndicator,
  StatusBar,
  StyleSheet,
  useColorScheme,
} from "react-native";
import { SwipeListView } from "react-native-swipe-list-view";

import { Text, View } from "../components/Themed";
import { API_URL } from "../constants/Constants";
import { OrderStatusItem, RootStackParamList } from "../types";
import { ShopContext, ThemeContext } from "../utils/contexts";
import {
  formatMoney,
  getFavorites,
  getOrders,
  getTheme,
  resetFavorites,
  resetShoppingCart,
  setFavorites,
} from "../utils/Utils";

export default function OrdersScreen({
  navigation,
}: StackScreenProps<RootStackParamList, "NotFound">) {
  const route = useRoute();

  const isFocused = useIsFocused();
  const colorTheme = useColorScheme();

  const shopContext = useContext(ShopContext);
  const { theme, shopId } = shopContext.values;

  const themeContext = useContext(ThemeContext);
  const { getStyle } = themeContext.helpers;

  const styles = getStyle(route.name);
  const sheet = styles?.sheet;
  // @ts-ignore
  const currentTheme = getTheme(theme)[colorTheme];

  const [orders, setOrdersGUI] = React.useState<OrderStatusItem[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  async function loadOrders(shopId: string) {
    console.log("Loading orders for shopId:", shopId);
    const orders = await getOrders(shopId);
    const ordersString = orders.map((i: OrderStatusItem) => i.id).join(",");
    fetch(`${API_URL}/v1/orders/check/${ordersString}`, {
      // fetch("httdps://api.prijslijst.info/v1/orders/check/85718655-e5b2-47f5-8be1-d4fb4dcf53d5,026e3a70-9ad8-4da5-8b2a-1e1f5d9372b8", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOrdersGUI(data);
        setIsLoading(false);
      });
  }

  React.useEffect(() => {
    loadOrders(shopId);
  }, [isFocused]);

  return (
    <View style={sheet?.container}>
      <StatusBar barStyle={currentTheme.statusBar} />
      <Text style={sheet?.title}>{I18n.t("ordersScreen.title")}️</Text>
      {!isLoading && (
        <SwipeListView
          useFlatList
          style={sheet?.list}
          data={orders}
          renderItem={({ item }) => (
            <View style={sheet?.renderItemRow}>
              <View
                style={sheet?.separator}
                lightColor="#eee"
                darkColor="rgba(255,255,255,0.1)"
              />
              <View style={sheet?.contentRow}>
                <View>
                  <Text style={sheet?.orderId}>
                    Nr: {item.customer_order_id}
                  </Text>
                </View>
                <Text style={sheet?.tableName}>{item.table_name}</Text>
                <Text style={sheet?.price}>
                  &euro; {formatMoney(item.total)}
                </Text>
                {item.status === "cancelled" && (
                  <Text style={{ color: "red" }}>
                    {I18n.t(`ordersScreen.${item.status}`)}
                  </Text>
                )}
                {item.status === "complete" && (
                  <Text style={{ color: currentTheme.actionButton }}>
                    {I18n.t(`ordersScreen.${item.status}`)}
                  </Text>
                )}
                {item.status === "pending" && (
                  <Text style={{ color: currentTheme.orderButtonBackground }}>
                    {I18n.t(`ordersScreen.${item.status}`)}
                  </Text>
                )}
              </View>
            </View>
          )}
          renderHiddenItem={(data, rowMap) => (
            <View style={sheet?.rowBack}>
              <Text></Text>
            </View>
          )}
          leftOpenValue={0}
          rightOpenValue={-75}
        />
      )}
      {!isLoading && (
        <FontAwesome.Button
          style={sheet?.reloadButton}
          name="refresh"
          borderRadius={50}
          backgroundColor={currentTheme.actionButton}
          onPress={() => loadOrders(shopId)}
        >
          {I18n.t("checkoutPage.orderStatus")}
        </FontAwesome.Button>
      )}
      {isLoading && <ActivityIndicator />}
    </View>
  );
}
