import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faHeart,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { useIsFocused, useRoute, useTheme } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import I18n from "i18n-js";
import React, { useContext, useEffect, useState } from "react";
import { Text } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Markdown from "react-native-markdown-display";
import { ActivityIndicator, ProgressBar } from "react-native-paper";
import { useQuery } from "react-query";

import { FavoriteActionButton } from "../../components";
import ActionButton from "../../components/ActionButton";
import Icon from "../../components/Icon";
import ImageCarousel from "../../components/ImageCarousel";
import { View } from "../../components/Themed";
import { API_URL } from "../../constants/Constants";
import useColorScheme from "../../hooks/useColorScheme";
import { CannabisProduct, DetailParamList, Price } from "../../types";
import {
  BaseContext,
  FilterContext,
  ShopContext,
  ThemeContext,
} from "../../utils/contexts";
import { RouteContext } from "../../utils/contexts/routes";
import { LanguageContext } from "../../utils/Language";
import {
  createFavorite,
  formatImage,
  getFavoriteId,
  getFavorites,
  getProductInfo,
  getTheme,
  handleFavorite,
  handleInternalClickAddCart,
  isFavorite,
  isFavoritePromise,
  setFavorites,
} from "../../utils/Utils";

export const ProductScreen = ({
  navigation,
  route,
}: StackScreenProps<DetailParamList, "DetailScreen">) => {
  const { kindId, productId, item } = route.params;
  const colorTheme = useColorScheme();

  const shopContext = useContext(ShopContext);
  const themeContext = useContext(ThemeContext);
  const baseContext = useContext(BaseContext);
  const routeContext = useContext(RouteContext);
  const filterContext = useContext(FilterContext);

  const { getStyle } = themeContext.helpers;
  const { platform } = themeContext.values;

  const { favorites } = filterContext.values;
  const { setFavoritesGUI } = filterContext.helpers;

  const styles = getStyle("ProductDetailNew");
  const sheet = styles?.sheet;

  const { values, helpers } = routeContext;

  const { shopId, theme, tableId, shoppingCartItems } = shopContext.values;
  const { setShopContext } = shopContext.helpers;

  // const [isFav, setIsFav] = useState<boolean | null>(null);

  const currentTheme = platform.isWeb
    ? getTheme(theme)["light"]
    : getTheme(theme)[colorTheme];

  const { colors, dark } = useTheme();
  const languageContext = useContext(LanguageContext);

  const api = baseContext.values.api;
  if (kindId) {
    // Todo Martin: add shop? or cache could clash after shopswitch
    api.v1.kinds.get.setKey(`kind-${kindId}`);
  } else {
    // Todo Martin: add shop? or cache could clash after shopswitch (and serve wrong prices!)
    api.v1.products.get.setKey(`product-${productId}`);
  }

  // Todo Martin: fix cache: second load of detail page still serves old data!
  // This hook shouldn't be needed
  const api_url = `${API_URL}/v1/${kindId ? "kinds" : "products"}/${
    kindId ? kindId : productId
  }?shop=${shopId}`;
  const { isLoading, error, data } = useQuery(
    kindId ? api.v1.kinds.get.getKey() : api.v1.products.get.getKey(),
    () => fetch(api_url).then((res) => res.json())
    // Todo Martin: fix cache: second load of detail page still serves old data!
    // return fetch(
    //   api.v1.kinds.get.id(kindId).search("shop", shopId).getUrl()
    // ).then((res) => res.json());
    // } else {
    //   return fetch(
    //     api.v1.products.get.id(productId).search("shop", shopId).getUrl()
    //   ).then((res) => res.json());
    // }
    // }
  );

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      helpers?.setCurrentRoute(route.name);
    }
  }, [isFocused]);

  if (error) return <Text>An error has occurred: {error.message}</Text>;

  // Todo Martin: -> Type of Product item has changed? So for now we rely on fetched data always...
  let price: Price;
  if (data?.prices) {
    price = data.prices[0];
  }

  const onFavoriteCallback = () => {
    const creatable = createFavorite(price);
    return handleFavorite(creatable, shopId)
      .then((newFavorites) => {
        setFavoritesGUI(newFavorites);
        return;
      })
      .catch((newFavorites) => {
        // here is ehree we should display something to customers if error
        setFavoritesGUI(newFavorites);
        return;
      });
  };

  const effects = (
    <>
      <Text
        style={{
          ...sheet?.subTitle,
          color: currentTheme.product.subtitle.color,
        }}
      >
        {data?.tags_amount === 0 ? "" : I18n.t("productDetailScreen.effects")}
      </Text>
      {data?.tags &&
        data?.tags.map(
          (tag: { amount: number | string | undefined }, index: number) => (
            <View style={sheet?.progressWraper} key={index}>
              <Text
                style={{
                  ...sheet?.progressBarText,
                  color: currentTheme.product.subtitle.color,
                }}
              >
                {tag.name}
              </Text>
              <ProgressBar
                progress={tag.amount ? tag.amount / 100 : 0}
                color={currentTheme.product.favorite.color}
                style={sheet?.progressBar}
              />
            </View>
          )
        )}
      <View
        style={{
          flex: 1,
        }}
      />
    </>
  );

  const flavors = (
    <>
      <Text
        style={{
          ...sheet?.subTitle,
          color: currentTheme.product.subtitle.color,
        }}
      >
        {data?.flavors_amount === 0
          ? ""
          : I18n.t("productDetailScreen.flavors")}
      </Text>
      <View style={sheet?.flavorsRow}>
        {data?.flavors &&
          data?.flavors.map(
            (
              flavor: { amount: number | string | undefined },
              index: number
            ) => (
              <View
                style={{
                  ...sheet?.flavor,
                }}
                key={index}
              >
                <Icon
                  style={sheet?.icon}
                  name={flavor.name}
                  color={currentTheme.product.subtitle.color}
                />
                <Text
                  style={{
                    ...sheet?.flavorText,
                    color: currentTheme.product.subtitle.color,
                  }}
                >
                  {flavor.name}
                </Text>
              </View>
            )
          )}
      </View>
    </>
  );

  return (
    <View
      style={{
        height: "100%",
      }}
    >
      {isLoading ? (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <ActivityIndicator
            size="large"
            color={currentTheme.product.title.color}
          />
        </View>
      ) : (
        // scrollIndicatorInsets fixes the vertical scrollbar issue
        // https://github.com/facebook/react-native/issues/28085
        <ScrollView
          style={{ width: "100%" }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <View style={sheet?.container}>
            {!isLoading && (
              <View style={sheet?.container}>
                <View style={sheet?.carouselImage}>
                  <ImageCarousel
                    navigation={navigation}
                    images={formatImage(data)}
                  />
                </View>
                <View
                  style={{
                    paddingTop: -100,
                  }}
                >
                  <View style={sheet?.row}>
                    <View style={sheet?.titleWrapper}>
                      <Text
                        style={{
                          ...sheet?.title,
                          color: currentTheme.product.title.color,
                        }}
                      >
                        {data?.name}
                      </Text>
                      <Text
                        style={{
                          ...sheet?.descriptionShort,
                          color: currentTheme.product.subtitle.color,
                        }}
                      >
                        {!isLoading &&
                          getProductInfo(data, languageContext.appLanguage)}
                      </Text>
                    </View>
                    <View style={sheet?.favoriteWrapper}>
                      <View style={sheet?.favoriteButton}>
                        <FavoriteActionButton
                          loadingIcon={faCheck}
                          feedbackTime={1000}
                          size={40}
                          item={price ? price : null}
                          id={item.kind_id ? item.kind_id : item.product_id}
                          waitForLoading={false}
                          handleClick={onFavoriteCallback}
                        />
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      ...sheet?.rowEnd,
                    }}
                  >
                    <Text
                      style={{
                        color: currentTheme.product.subtitle.color,
                      }}
                    >
                      {data?.i
                        ? "indica"
                        : data?.c
                        ? "CBD"
                        : data?.s
                        ? "sativa"
                        : data?.h
                        ? "hybrid"
                        : ""}
                    </Text>
                  </View>
                  <Text
                    style={{
                      ...sheet?.subTitle,
                      color: currentTheme.product.subtitle.color,
                    }}
                  >
                    {data.description_nl
                      ? I18n.t("productDetailScreen.price")
                      : ""}
                  </Text>
                  <View style={sheet?.list}>
                    {price.half && (
                      <ActionButton
                        iconName={faShoppingCart}
                        loadingIcon={faCheck}
                        feedbackTime={1000}
                        size={40}
                        waitForLoading={false}
                        item={price}
                        price="half"
                        quantity="0,5"
                        description="gram"
                        handleClick={() => {
                          setShopContext({
                            theme: theme,
                            shopId: shopId,
                            tableId: tableId,
                            shoppingCartItems: shoppingCartItems + 1,
                          });
                          handleInternalClickAddCart(
                            data,
                            "0,5 gram",
                            "half",
                            shopId
                          );
                        }}
                      />
                    )}
                    {price.one && (
                      <ActionButton
                        iconName={faShoppingCart}
                        loadingIcon={faCheck}
                        feedbackTime={1000}
                        size={20}
                        waitForLoading={false}
                        item={price}
                        price="one"
                        quantity="1"
                        description="gram"
                        handleClick={() => {
                          setShopContext({
                            theme: theme,
                            shopId: shopId,
                            tableId: tableId,
                            shoppingCartItems: shoppingCartItems + 1,
                          });
                          handleInternalClickAddCart(
                            data,
                            "1 gram",
                            "one",
                            shopId
                          );
                        }}
                      />
                    )}
                    {price.two_five && (
                      <ActionButton
                        iconName={faShoppingCart}
                        loadingIcon={faCheck}
                        feedbackTime={1000}
                        size={20}
                        waitForLoading={false}
                        item={price}
                        price="two_five"
                        quantity="2,5"
                        description="gram"
                        handleClick={() => {
                          setShopContext({
                            theme: theme,
                            shopId: shopId,
                            tableId: tableId,
                            shoppingCartItems: shoppingCartItems + 1,
                          });
                          handleInternalClickAddCart(
                            data,
                            "2,5 gram",
                            "two_five",
                            shopId
                          );
                        }}
                      />
                    )}
                    {price.five && (
                      <ActionButton
                        iconName={faShoppingCart}
                        loadingIcon={faCheck}
                        feedbackTime={1000}
                        size={20}
                        waitForLoading={false}
                        item={price}
                        price="five"
                        quantity="5"
                        description="gram"
                        handleClick={() => {
                          setShopContext({
                            theme: theme,
                            shopId: shopId,
                            tableId: tableId,
                            shoppingCartItems: shoppingCartItems + 1,
                          });
                          handleInternalClickAddCart(
                            data,
                            "5 gram",
                            "five",
                            shopId
                          );
                        }}
                      />
                    )}
                    {price.joint && (
                      <ActionButton
                        iconName={faShoppingCart}
                        loadingIcon={faCheck}
                        feedbackTime={1000}
                        size={20}
                        waitForLoading={false}
                        item={price}
                        price="joint"
                        quantity="1"
                        description="joint"
                        handleClick={() => {
                          setShopContext({
                            theme: theme,
                            shopId: shopId,
                            tableId: tableId,
                            shoppingCartItems: shoppingCartItems + 1,
                          });
                          handleInternalClickAddCart(
                            data,
                            "joint",
                            "joint",
                            shopId
                          );
                        }}
                      />
                    )}

                    {price.piece && (
                      <ActionButton
                        iconName={faShoppingCart}
                        loadingIcon={faCheck}
                        feedbackTime={1000}
                        size={20}
                        waitForLoading={false}
                        item={price}
                        price="piece"
                        quantity="1"
                        description=" "
                        handleClick={() => {
                          setShopContext({
                            theme: theme,
                            shopId: shopId,
                            tableId: tableId,
                            shoppingCartItems: shoppingCartItems + 1,
                          });
                          handleInternalClickAddCart(
                            data,
                            "piece",
                            "piece",
                            shopId
                          );
                        }}
                      />
                    )}
                  </View>
                  <Text
                    style={{
                      ...sheet?.subTitle,
                      color: currentTheme.product.subtitle.color,
                    }}
                  >
                    {data.description_nl
                      ? I18n.t("productDetailScreen.description")
                      : ""}
                  </Text>
                  {languageContext.appLanguage === "nl" && data.description_nl && (
                    <Markdown
                      style={{
                        body: {
                          color: currentTheme.text,
                          width: "90%",
                          marginTop: "3%",
                          marginLeft: "5%",
                          fontFamily: "Roboto_400Regular",
                          fontSize: 15,
                        },
                      }}
                    >
                      {data?.description_nl}
                    </Markdown>
                  )}
                  {languageContext.appLanguage === "en" && data.description_en && (
                    <Markdown
                      style={{
                        body: {
                          color: currentTheme.product.subtitle.color,
                          width: "90%",
                          marginTop: "3%",
                          marginLeft: "5%",
                          fontFamily: "Roboto_400Regular",
                          fontSize: 15,
                        },
                      }}
                    >
                      {data?.description_en}
                    </Markdown>
                  )}
                </View>
                {kindId && effects}
                {kindId && flavors}
                <View style={{ height: 20 }} />
              </View>
            )}
            {isLoading && <ActivityIndicator />}
          </View>
        </ScrollView>
      )}
    </View>
  );
};
