import React, { useContext } from "react";
import { useColorScheme } from "react-native";
import { ThemeProvider } from "react-native-elements";

import DownloadAppScreen from "../../screens/new/DownloadAppScreen";
import QRCodeScannerScreen from "../../screens/new/QrCodeScannerScreen";
import QRCodeScannerWebScreen from "../../screens/QrCodeScannerWebScreen";
import { BaseContext } from "../../utils/contexts";
import NewNavigation from ".";

export const Routes = () => {
  const colorScheme = useColorScheme();
  const baseContext = useContext(BaseContext);

  const { setRenderOptions } = baseContext.helpers;
  const { renderOptions } = baseContext.values;

  if (renderOptions.renderQrScreen) {
    return (
      <ThemeProvider>
        <QRCodeScannerScreen
          renderCallback={setRenderOptions}
        ></QRCodeScannerScreen>
      </ThemeProvider>
    );
  } else if (renderOptions.renderDownloadAppScreen) {
    return (
      <ThemeProvider>
        <DownloadAppScreen renderCallback={setRenderOptions} />
      </ThemeProvider>
    );
  }
  // Add this to the new components if its approved
  // REMOVED THE WEB VIEWS
  // else if (renderOptions.renderWebQrScreen) {
  //   return (
  //     <QRCodeScannerWebScreen
  //       renderCallback={setRenderOptions}
  //     ></QRCodeScannerWebScreen>
  //   );
  // }
  else {
    return <NewNavigation colorScheme={colorScheme} />;
  }
};
