// Todo: Remove and don't use:
import { Theme } from "../types";

const tintColorLight = "#0f0";
const tintColorDark = "#fff";

export default {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabBar: "white",
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    searchBarBackground: "000",
    orderButtonBackground: "999",
    orderButtonText: "#f00",
    actionButton: "#739e6f",
    imageBorder: "#739e6f",
    topBarNavigationElements: "#739e6f",
    statusBar: "dark-content",
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabBar: "black",
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
    searchBarBackground: "000",
    orderButtonBackground: "999",
    orderButtonText: "#f00",
    actionButton: "#739e6f",
    imageBorder: "#739e6f",
    topBarNavigationElements: "#739e6f",
    statusBar: "light-content",
  },
};

export const defaultTheme: Theme = {
  light: {
    text: "#000",
    background: "#fff",
    tint: "#0f0",
    tabBar: "white",
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    searchBarBackground: "000",
    orderButtonBackground: "#006838",
    orderButtonText: "#006838",
    actionButton: "#006838",
    imageBorder: "#006838",
    appStore: {
      backgroundColor: "#F2F4FC",
      subtitleColor: "#593E5C",
      textColor: "#000000",
      shadowColor: "#000000",
      button: {
        backgroundColor: "#ffffff",
        color: "#000000",
      },
    },
    qr: {
      page: {
        backgroundColor: "#0D2E4E",
        textColor: "#FFFBFB",
        backArrowColor: "#FFFBFB",
      },
      scan: {
        borderColor: "#FFFBFB",
        textColor: "#FBADAD",
        backgroundColor: "#262121",
      },
      design: {
        backgroundColor: "#090109",
        bottomBackgroundColor: "#140314",
      },
    },
    filter: {
      list: {
        backgroundColor: "#DAD2BF",
        borderColor: "#7D7C7A",
      },
      options: {
        backgroundColorNotSelected: "#362B12",
        backgroundColorSelected: "#333333",
        textColor: "#A9A08B",
      },
    },
    navigation: {
      // 362B12
      topBar: {
        backgroundColor: "#362B12",
        burgerIcon: "#DAD2BF",
        cardIcon: "#DAD2BF",
      },
      backgroundColor: "#DAD2BF",
      closeButtonColor: "#362B12",
      navigationItemBackgroundColor: "#362B12",
      navigationItemIconColor: "#DAD2BF",
      navigationItemTextColor: "#DAD2BF",
      navigationTitle: {
        color: "#4A4027",
      },
      navigationOrderList: {
        backgroundColor: "#F4EEEA",
        textColor: "#5B491F",
      },
    },
    search: {
      backgroundColor: "#362B12",
      placeholderColor: "#372C13",
      searchInputBackgroundColor: "#FFFFFF",
      searchIconColor: "#C8C0AC",
      textColor: "#C8C0AC",
    },
    category: {
      head: {
        textColor: "#F4EEEA",
        backgroundColor: "#362B12",
      },
      body: {
        backgroundColor: "#F9F4F4",
      },
    },
    product: {
      image: {
        color: "#7C6D4B",
        icon: "#856362",
      },
      favorite: {
        color: "#9C9178",
        border: "#443920",
      },
      title: {
        color: "#675D4B",
      },
      subtitle: {
        color: "#6A614E",
      },
      price: {
        color: "#463A20",
        background: "#F3EDE9",
      },
    },
    card: {
      backgroundColor: "#F0EBE7",
      textColor: "#766E5C",
      image: {
        borderColor: "#654F34",
      },
      favorite: {
        backgroundColor: "#F0EBE7",
      },
    },
    topBarNavigationElements: "#006838",
    statusBar: "dark-content",
    bottomBatNavigation: "#006838",
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: "#0ff",
    tabBar: "black",
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
    searchBarBackground: "000",
    orderButtonBackground: "#006838",
    orderButtonText: "#006838",
    actionButton: "#006838",
    imageBorder: "#006838",
    appStore: {
      backgroundColor: "#593E5C",
      subtitleColor: "#f2f4fc",
      textColor: "#ffffff",
      shadowColor: "#ffffff",
      button: {
        backgroundColor: "#000",
        color: "#fff",
      },
    },
    qr: {
      page: {
        backgroundColor: "#0D2E4E",
        textColor: "#FFFBFB",
        backArrowColor: "#FFFBFB",
      },
      scan: {
        borderColor: "#FFFBFB",
        textColor: "#FBADAD",
        backgroundColor: "#262121",
      },
      design: {
        backgroundColor: "#090109",
        bottomBackgroundColor: "#140314",
      },
    },
    filter: {
      list: {
        backgroundColor: "#DAD2BF",
        borderColor: "#7D7C7A",
      },
      options: {
        backgroundColorNotSelected: "#362B12",
        backgroundColorSelected: "#333333",
        textColor: "#A9A08B",
      },
    },
    navigation: {
      topBar: {
        backgroundColor: "#362B12",
        burgerIcon: "#DAD2BF",
        cardIcon: "#DAD2BF",
      },
      backgroundColor: "#DAD2BF",
      closeButtonColor: "#362B12",
      navigationItemBackgroundColor: "#362B12",
      navigationItemIconColor: "#DAD2BF",
      navigationItemTextColor: "#DAD2BF",
      navigationTitle: {
        color: "#DAD2BF",
      },
      navigationOrderList: {
        backgroundColor: "#F4EEEA",
        textColor: "#5B491F",
      },
    },
    search: {
      backgroundColor: "#362B12",
      placeholderColor: "#372C13",
      searchInputBackgroundColor: "#FFFFFF",
      searchIconColor: "#C8C0AC",
      textColor: "#C8C0AC",
    },
    category: {
      head: {
        textColor: "#F4EEEA",
        backgroundColor: "#000000",
      },
      body: {
        backgroundColor: "#F9F4F4",
      },
    },
    product: {
      image: {
        color: "#7C6D4B",
        icon: "#856362",
      },
      favorite: {
        color: "#9C9178",
        border: "#443920",
      },
      title: {
        color: "#DAD2BF",
      },
      subtitle: {
        color: "#C6BEAD",
      },
      price: {
        color: "#C6BEAD",
        background: "#000000",
      },
    },
    card: {
      backgroundColor: "#161511",
      textColor: "#766E5C",
      image: {
        borderColor: "#654F34",
      },
      favorite: {
        backgroundColor: "#161511",
      },
    },
    topBarNavigationElements: "#006838",
    statusBar: "light-content",
    bottomBatNavigation: "#006838",
  },
};

export const mississippiTheme: Theme = {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabBar: "white",
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    searchBarBackground: "000",
    orderButtonBackground: "#006838",
    orderButtonText: "#006838",
    actionButton: "#006838",
    imageBorder: "#006838",
    topBarNavigationElements: "#006838",
    qr: {
      page: {
        backgroundColor: "#0D2E4E",
        textColor: "#FFFBFB",
        backArrowColor: "#FFFBFB",
      },
      scan: {
        borderColor: "#FFFBFB",
        textColor: "#FBADAD",
        backgroundColor: "#262121",
      },
      design: {
        backgroundColor: "#000000",
        bottomBackgroundColor: "#140314",
      },
    },
    filter: {
      list: {
        backgroundColor: "#DAD2BF",
        borderColor: "#7D7C7A",
      },
      options: {
        backgroundColorNotSelected: "#362B12",
        backgroundColorSelected: "#333333",
        textColor: "#A9A08B",
      },
    },
    navigation: {
      topBar: {
        backgroundColor: "#362B12",
        burgerIcon: "#DAD2BF",
        cardIcon: "#DAD2BF",
      },
      backgroundColor: "#DAD2BF",
      closeButtonColor: "#362B12",
      navigationItemBackgroundColor: "#362B12",
      navigationItemIconColor: "#DAD2BF",
      navigationItemTextColor: "#DAD2BF",
      navigationTitle: {
        color: "#4A4027",
      },
      navigationOrderList: {
        backgroundColor: "#F4EEEA",
        textColor: "#5B491F",
      },
    },
    search: {
      backgroundColor: "#372C13",
      placeholderColor: "#372C13",
      searchInputBackgroundColor: "#FFFFFF",
      searchIconColor: "#C8C0AC",
      textColor: "#C8C0AC",
    },
    category: {
      head: {
        textColor: "#F4EEEA",
        backgroundColor: "#372C13",
      },
      body: {
        backgroundColor: "#F9F4F4",
      },
    },
    product: {
      image: {
        color: "#7C6D4B",
        icon: "#856362",
      },
      favorite: {
        color: "#9C9178",
        border: "#443920",
      },
      title: {
        color: "#675D4B",
      },
      subtitle: {
        color: "#6A614E",
      },
      price: {
        color: "#463A20",
        background: "#F3EDE9",
      },
    },
    card: {
      backgroundColor: "#F0EBE7",
      textColor: "#766E5C",
      image: {
        borderColor: "#654F34",
      },
      favorite: {
        backgroundColor: "#F0EBE7",
      },
    },
    statusBar: "dark-content",
    bottomBatNavigation: "#006838",
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabBar: "#121212",
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
    searchBarBackground: "000",
    orderButtonBackground: "#06f",
    orderButtonText: "#06f",
    actionButton: "#006838",
    imageBorder: "#006838",
    qr: {
      page: {
        backgroundColor: "#0D2E4E",
        textColor: "#FFFBFB",
        backArrowColor: "#FFFBFB",
      },
      scan: {
        borderColor: "#FFFBFB",
        textColor: "#FBADAD",
        backgroundColor: "#262121",
      },
      design: {
        backgroundColor: "#090109",
        bottomBackgroundColor: "#140314",
      },
    },
    filter: {
      list: {
        backgroundColor: "#DAD2BF",
        borderColor: "#7D7C7A",
      },
      options: {
        backgroundColorNotSelected: "#362B12",
        backgroundColorSelected: "#333333",
        textColor: "#A9A08B",
      },
    },
    navigation: {
      topBar: {
        backgroundColor: "#362B12",
        burgerIcon: "#DAD2BF",
        cardIcon: "#DAD2BF",
      },
      backgroundColor: "#000000",
      closeButtonColor: "#362B12",
      navigationItemBackgroundColor: "#362B12",
      navigationItemIconColor: "#DAD2BF",
      navigationItemTextColor: "#DAD2BF",
      navigationTitle: {
        color: "#DAD2BF",
      },
      navigationOrderList: {
        backgroundColor: "#DAD2BF",
        textColor: "#5B491F",
      },
    },
    search: {
      backgroundColor: "#362B12",
      placeholderColor: "#372C13",
      searchInputBackgroundColor: "#FFFFFF",
      searchIconColor: "#C8C0AC",
      textColor: "#C8C0AC",
    },
    category: {
      head: {
        textColor: "#F4EEEA",
        backgroundColor: "#372C13",
      },
      body: {
        backgroundColor: "#000000",
      },
    },
    product: {
      image: {
        color: "#7C6D4B",
        icon: "#856362",
      },
      favorite: {
        color: "#9C9178",
        border: "#443920",
      },
      title: {
        color: "#DAD2BF",
      },
      subtitle: {
        color: "#C6BEAD",
      },
      price: {
        color: "#C6BEAD",
        background: "#000000",
      },
    },
    card: {
      backgroundColor: "#161511",
      textColor: "#DAD2BF",
      image: {
        borderColor: "#493C1B",
      },
      favorite: {
        backgroundColor: "#161511",
      },
    },
    topBarNavigationElements: "#006838",
    statusBar: "light-content",
    bottomBatNavigation: "#006838",
  },
};

export const easyGoingTheme: Theme = {
  light: {
    text: "#421E1C",
    background: "#fff",
    tint: "#F58820",
    tabBar: "#E5D0BC",
    tabIconDefault: "#452517",
    tabIconSelected: "#F58820",
    searchBarBackground: "000",
    orderButtonBackground: "#452517",
    orderButtonText: "#421E1C",
    actionButton: "#F58820",
    imageBorder: "#F58820",
    qr: {
      page: {
        backgroundColor: "#0D2E4E",
        textColor: "#FFFBFB",
        backArrowColor: "#FFFBFB",
      },
      scan: {
        borderColor: "#FFFBFB",
        textColor: "#FBADAD",
        backgroundColor: "#262121",
      },
      design: {
        backgroundColor: "#090109",
        bottomBackgroundColor: "#140314",
      },
    },
    filter: {
      list: {
        backgroundColor: "#DAD2BF",
        borderColor: "#7D7C7A",
      },
      options: {
        backgroundColorNotSelected: "#362B12",
        backgroundColorSelected: "#333333",
        textColor: "#A9A08B",
      },
    },
    navigation: {
      topBar: {
        backgroundColor: "#362B12",
        burgerIcon: "#DAD2BF",
        cardIcon: "#DAD2BF",
      },
      backgroundColor: "#DAD2BF",
      closeButtonColor: "#362B12",
      navigationItemBackgroundColor: "#362B12",
      navigationItemIconColor: "#DAD2BF",
      navigationItemTextColor: "#DAD2BF",
      navigationTitle: {
        color: "#4A4027",
      },
      navigationOrderList: {
        backgroundColor: "#F4EEEA",
        textColor: "#5B491F",
      },
    },
    search: {
      backgroundColor: "#362B12",
      placeholderColor: "#372C13",
      searchInputBackgroundColor: "#FFFFFF",
      searchIconColor: "#C8C0AC",
      textColor: "#C8C0AC",
    },
    category: {
      head: {
        textColor: "#F4EEEA",
        backgroundColor: "#362B12",
      },
      body: {
        backgroundColor: "#F9F4F4",
      },
    },
    product: {
      image: {
        color: "#7C6D4B",
        icon: "#856362",
      },
      favorite: {
        color: "#9C9178",
        border: "#443920",
      },
      title: {
        color: "#675D4B",
      },
      subtitle: {
        color: "#6A614E",
      },
      price: {
        color: "#463A20",
        background: "#F3EDE9",
      },
    },
    card: {
      backgroundColor: "#F0EBE7",
      textColor: "#766E5C",
      image: {
        borderColor: "#654F34",
      },
      favorite: {
        backgroundColor: "#F0EBE7",
      },
    },
    topBarNavigationElements: "#F58820",
    statusBar: "dark-content",
    bottomBatNavigation: "#F58820",
  },
  dark: {
    text: "#dddddd",
    background: "#000",
    tint: "#F58820",
    tabBar: "#121212",
    tabIconDefault: "#ccc",
    tabIconSelected: "#F58820",
    searchBarBackground: "000",
    orderButtonBackground: "#F58820",
    orderButtonText: "#F58820",
    actionButton: "#F58820",
    imageBorder: "#F58820",
    topBarNavigationElements: "#F58820",
    qr: {
      page: {
        backgroundColor: "#0D2E4E",
        textColor: "#FFFBFB",
        backArrowColor: "#FFFBFB",
      },
      scan: {
        borderColor: "#FFFBFB",
        textColor: "#FBADAD",
        backgroundColor: "#262121",
      },
      design: {
        backgroundColor: "#090109",
        bottomBackgroundColor: "#140314",
      },
    },
    filter: {
      list: {
        backgroundColor: "#DAD2BF",
        borderColor: "#7D7C7A",
      },
      options: {
        backgroundColorNotSelected: "#362B12",
        backgroundColorSelected: "#333333",
        textColor: "#A9A08B",
      },
    },
    navigation: {
      topBar: {
        backgroundColor: "#362B12",
        burgerIcon: "#DAD2BF",
        cardIcon: "#DAD2BF",
      },
      backgroundColor: "#DAD2BF",
      closeButtonColor: "#362B12",
      navigationItemBackgroundColor: "#362B12",
      navigationItemIconColor: "#DAD2BF",
      navigationItemTextColor: "#DAD2BF",
      navigationTitle: {
        color: "#DAD2BF",
      },
      navigationOrderList: {
        backgroundColor: "#DAD2BF",
        textColor: "#5B491F",
      },
    },
    search: {
      backgroundColor: "#362B12",
      placeholderColor: "#372C13",
      searchInputBackgroundColor: "#FFFFFF",
      searchIconColor: "#C8C0AC",
      textColor: "#C8C0AC",
    },
    category: {
      head: {
        textColor: "#F4EEEA",
        backgroundColor: "#362B12",
      },
      body: {
        backgroundColor: "#F9F4F4",
      },
    },
    product: {
      image: {
        color: "#7C6D4B",
        icon: "#856362",
      },
      favorite: {
        color: "#9C9178",
        border: "#443920",
      },
      title: {
        color: "#DAD2BF",
      },
      subtitle: {
        color: "#C6BEAD",
      },
      price: {
        color: "#C6BEAD",
        background: "#000000",
      },
    },
    card: {
      backgroundColor: "#F0EBE7",
      textColor: "#766E5C",
      image: {
        borderColor: "#654F34",
      },
      favorite: {
        backgroundColor: "#161511",
      },
    },
    statusBar: "light-content",
    bottomBatNavigation: "#F58820",
  },
};

export const formaticsTheme: Theme = {
  light: {
    text: "#222222",
    background: "#eeeeee",
    tint: "#D80018",
    tabBar: "#cccccc",
    tabIconDefault: "#666666",
    tabIconSelected: "#D80018",
    searchBarBackground: "#0f0",
    orderButtonBackground: "#D80018",
    orderButtonText: "#111",
    actionButton: "#D80018",
    imageBorder: "#D80018",
    qr: {
      page: {
        backgroundColor: "#0D2E4E",
        textColor: "#FFFBFB",
        backArrowColor: "#FFFBFB",
      },
      scan: {
        borderColor: "#FFFBFB",
        textColor: "#FBADAD",
        backgroundColor: "#262121",
      },
      design: {
        backgroundColor: "#090109",
        bottomBackgroundColor: "#140314",
      },
    },
    filter: {
      list: {
        backgroundColor: "#DAD2BF",
        borderColor: "#7D7C7A",
      },
      options: {
        backgroundColorNotSelected: "#362B12",
        backgroundColorSelected: "#333333",
        textColor: "#A9A08B",
      },
    },
    navigation: {
      topBar: {
        backgroundColor: "#362B12",
        burgerIcon: "#DAD2BF",
        cardIcon: "#DAD2BF",
      },
      backgroundColor: "#DAD2BF",
      closeButtonColor: "#362B12",
      navigationItemBackgroundColor: "#362B12",
      navigationItemIconColor: "#DAD2BF",
      navigationItemTextColor: "#DAD2BF",
      navigationTitle: {
        color: "#4A4027",
      },
      navigationOrderList: {
        backgroundColor: "#F4EEEA",
        textColor: "#5B491F",
      },
    },
    search: {
      backgroundColor: "#362B12",
      placeholderColor: "#372C13",
      searchInputBackgroundColor: "#FFFFFF",
      searchIconColor: "#C8C0AC",
      textColor: "#C8C0AC",
    },
    category: {
      head: {
        textColor: "#F4EEEA",
        backgroundColor: "#362B12",
      },
      body: {
        backgroundColor: "#F9F4F4",
      },
    },
    product: {
      image: {
        color: "#7C6D4B",
        icon: "#856362",
      },
      favorite: {
        color: "#9C9178",
        border: "#443920",
      },
      title: {
        color: "#675D4B",
      },
      subtitle: {
        color: "#6A614E",
      },
      price: {
        color: "#463A20",
        background: "#F3EDE9",
      },
    },
    card: {
      backgroundColor: "#F0EBE7",
      textColor: "#766E5C",
      image: {
        borderColor: "#654F34",
      },
      favorite: {
        backgroundColor: "#F0EBE7",
      },
    },
    topBarNavigationElements: "#D80018",
    statusBar: "dark-content",
    bottomBatNavigation: "#D80018",
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: "#00f",
    tabBar: "#121212",
    tabIconDefault: "#ccc",
    tabIconSelected: "#D80018",
    searchBarBackground: "000",
    orderButtonBackground: "#D80018",
    orderButtonText: "#D80018",
    actionButton: "#D80018",
    imageBorder: "#D80018",
    qr: {
      page: {
        backgroundColor: "#0D2E4E",
        textColor: "#FFFBFB",
        backArrowColor: "#FFFBFB",
      },
      scan: {
        borderColor: "#FFFBFB",
        textColor: "#FBADAD",
        backgroundColor: "#262121",
      },
      design: {
        backgroundColor: "#090109",
        bottomBackgroundColor: "#140314",
      },
    },
    filter: {
      list: {
        backgroundColor: "#DAD2BF",
        borderColor: "#7D7C7A",
      },
      options: {
        backgroundColorNotSelected: "#362B12",
        backgroundColorSelected: "#333333",
        textColor: "#A9A08B",
      },
    },
    navigation: {
      topBar: {
        backgroundColor: "#362B12",
        burgerIcon: "#DAD2BF",
        cardIcon: "#DAD2BF",
      },
      backgroundColor: "#DAD2BF",
      closeButtonColor: "#362B12",
      navigationItemBackgroundColor: "#362B12",
      navigationItemIconColor: "#DAD2BF",
      navigationItemTextColor: "#DAD2BF",
      navigationTitle: {
        color: "#DAD2BF",
      },
      navigationOrderList: {
        backgroundColor: "#DAD2BF",
        textColor: "#5B491F",
      },
    },
    search: {
      backgroundColor: "#362B12",
      placeholderColor: "#372C13",
      searchInputBackgroundColor: "#FFFFFF",
      searchIconColor: "#C8C0AC",
      textColor: "#C8C0AC",
    },
    category: {
      head: {
        textColor: "#F4EEEA",
        backgroundColor: "#362B12",
      },
      body: {
        backgroundColor: "#000000",
      },
    },
    product: {
      image: {
        color: "#7C6D4B",
        icon: "#856362",
      },
      favorite: {
        color: "#9C9178",
        border: "#443920",
      },
      title: {
        color: "#DAD2BF",
      },
      subtitle: {
        color: "#C6BEAD",
      },
      price: {
        color: "#C6BEAD",
        background: "#000000",
      },
    },
    card: {
      backgroundColor: "#161511",
      textColor: "#766E5C",
      image: {
        borderColor: "#654F34",
      },
      favorite: {
        backgroundColor: "#161511",
      },
    },
    topBarNavigationElements: "#D80018",
    statusBar: "light-content",
    bottomBatNavigation: "#D80018",
  },
};

export const testShopTheme: Theme = {
  light: {
    text: "#222222",
    background: "#fff",
    tint: "#2b044b",
    tabBar: "#d8c3f4",
    tabIconDefault: "#391060",
    tabIconSelected: "#2b044b",
    searchBarBackground: "000",
    orderButtonBackground: "#fc8710",
    orderButtonText: "#d8c3f4",
    actionButton: "#fc8710",
    imageBorder: "#2b044b",
    qr: {
      page: {
        backgroundColor: "#0D2E4E",
        textColor: "#FFFBFB",
        backArrowColor: "#FFFBFB",
      },
      scan: {
        borderColor: "#FFFBFB",
        textColor: "#FBADAD",
        backgroundColor: "#262121",
      },
      design: {
        backgroundColor: "#090109",
        bottomBackgroundColor: "#140314",
      },
    },
    filter: {
      list: {
        backgroundColor: "#DAD2BF",
        borderColor: "#7D7C7A",
      },
      options: {
        backgroundColorNotSelected: "#362B12",
        backgroundColorSelected: "#333333",
        textColor: "#A9A08B",
      },
    },
    navigation: {
      topBar: {
        backgroundColor: "#d8c3f4",
        burgerIcon: "#2b044b",
        cardIcon: "#2b044b",
      },
      backgroundColor: "#d8c3f4",
      closeButtonColor: "#2b044b",
      navigationItemBackgroundColor: "#d8c3f4",
      navigationItemIconColor: "#2b044b",
      navigationItemTextColor: "#2b044b",
      navigationTitle: {
        color: "#391060",
      },
      navigationOrderList: {
        backgroundColor: "#d8c3f4",
        textColor: "#391060",
      },
    },
    search: {
      backgroundColor: "#ab7ee7",
      placeholderColor: "#ab7ee7",
      searchInputBackgroundColor: "#FFFFFF",
      searchIconColor: "#2b044b",
      textColor: "#2b044b",
    },
    category: {
      head: {
        textColor: "#1b0432",
        backgroundColor: "#d8c3f4",
      },
      body: {
        backgroundColor: "#d8c3f4",
      },
    },
    product: {
      image: {
        color: "#391060",
        icon: "#2b044b",
      },
      favorite: {
        color: "#391060",
        border: "#d8c3f4",
      },
      title: {
        color: "#391060",
      },
      subtitle: {
        color: "#391060",
      },
      price: {
        color: "#2b044b",
        background: "#d8c3f4",
      },
    },
    card: {
      backgroundColor: "#F0EBE7",
      textColor: "#391060",
      image: {
        borderColor: "#d8c3f4",
      },
      favorite: {
        backgroundColor: "#F0EBE7",
      },
    },
    topBarNavigationElements: "#b85f46",
    statusBar: "light-content",
    bottomBatNavigation: "#b85f46",
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: "#b85f46",
    tabBar: "#121212",
    tabIconDefault: "#ccc",
    tabIconSelected: "#b85f46",
    searchBarBackground: "000",
    orderButtonBackground: "#fc8710",
    orderButtonText: "#d8c3f4",
    actionButton: "#fc8710",
    imageBorder: "#fc8710",
    qr: {
      page: {
        backgroundColor: "#0D2E4E",
        textColor: "#FFFBFB",
        backArrowColor: "#FFFBFB",
      },
      scan: {
        borderColor: "#FFFBFB",
        textColor: "#FBADAD",
        backgroundColor: "#262121",
      },
      design: {
        backgroundColor: "#090109",
        bottomBackgroundColor: "#140314",
      },
    },
    filter: {
      list: {
        backgroundColor: "#DAD2BF",
        borderColor: "#7D7C7A",
      },
      options: {
        backgroundColorNotSelected: "#362B12",
        backgroundColorSelected: "#333333",
        textColor: "#A9A08B",
      },
    },
    navigation: {
      topBar: {
        backgroundColor: "#2b044b",
        burgerIcon: "#DAD2BF",
        cardIcon: "#DAD2BF",
      },
      backgroundColor: "#DAD2BF",
      closeButtonColor: "#2b044b",
      navigationItemBackgroundColor: "#100220",
      navigationItemIconColor: "#d8c3f4",
      navigationItemTextColor: "#d8c3f4",
      navigationTitle: {
        color: "#ab7ee7",
      },
      navigationOrderList: {
        backgroundColor: "#d8c3f4",
        textColor: "#2b044b",
      },
    },
    search: {
      backgroundColor: "#391060",
      placeholderColor: "#391060",
      searchInputBackgroundColor: "#FFFFFF",
      searchIconColor: "#C8C0AC",
      textColor: "#C8C0AC",
    },
    category: {
      head: {
        textColor: "#F4EEEA",
        backgroundColor: "#2b044b",
      },
      body: {
        backgroundColor: "#100220",
      },
    },
    product: {
      image: {
        color: "#2b044b",
        icon: "#d8c3f4",
      },
      favorite: {
        color: "#d8c3f4",
        border: "#2b044b",
      },
      title: {
        color: "#d8c3f4",
      },
      subtitle: {
        color: "#d8c3f4",
      },
      price: {
        color: "#d8c3f4",
        background: "#2b044b",
      },
    },
    card: {
      backgroundColor: "#1b0432",
      textColor: "#d8c3f4",
      image: {
        borderColor: "#ab7ee7",
      },
      favorite: {
        backgroundColor: "#1b0432",
      },
    },
    topBarNavigationElements: "#b85f46",
    statusBar: "light-content",
    bottomBatNavigation: "#b85f46",
  },
};
