import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import React, { useContext } from "react";
import { ColorSchemeName } from "react-native";

import useColorScheme from "../../hooks/useColorScheme";
import { RenderOptions } from "../../types";
import {
  FilterProvider,
  ShopContext,
  ThemeProvider,
} from "../../utils/contexts";
import { getTheme } from "../../utils/Utils";
import { MainStackNavigator } from "./DrawerNavigation";
import RouteConfiguration from "./RouteConfiguration";

export default function NewNavigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
  theme?: string;
}) {
  const colorTheme = useColorScheme();
  const shopContext = useContext(ShopContext);
  const { theme } = shopContext.values;
  const { getShopContext } = shopContext.helpers;
  const currentTheme = getTheme(theme)[colorTheme];

  const MyDarkTheme = {
    colors: {
      ...DefaultTheme,
      card: currentTheme.tabBar, // top tab bar color
      text: currentTheme.topBarNavigationElements, // screen title
      primary: currentTheme.topBarNavigationElements, // Back button (arrow + text)
    },
  };

  const MyLightTheme = {
    colors: {
      ...DefaultTheme,
      card: currentTheme.tabBar, // top tab bar color
      text: currentTheme.topBarNavigationElements, // screen title
      primary: currentTheme.topBarNavigationElements, // Back button (arrow + text)
    },
  };

  React.useEffect(() => {
    getShopContext();
  }, []);
  const navigationRef = React.useRef(null);

  return (
    <NavigationContainer
      linking={RouteConfiguration}
      theme={colorScheme === "dark" ? MyDarkTheme : MyLightTheme}
      ref={navigationRef}
    >
      <ThemeProvider>
        <FilterProvider>
          <MainStackNavigator navigation={navigationRef.current} />
          <StatusBar style={"light"} />
        </FilterProvider>
      </ThemeProvider>
    </NavigationContainer>
  );
}
