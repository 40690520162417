import { CssSheet } from "../../actions/types";

export const HorecaProductDetailStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "HorecaProductDetailScreen",
  sheet: {
    container: {
      flex: 1,
    },
    productImage: {
      marginTop: 11,
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 20,
      fontWeight: "bold",
      width: "100%",
      marginTop: "3%",
    },
    subTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginTop: "10%",
    },
    link: {
      marginTop: 15,
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      color: "#2e78b7",
    },
    button: {
      alignItems: "center",
      padding: 10,
      borderRadius: 25,
      marginTop: "5%",
    },
    buttonText: {
      color: "white",
      fontWeight: "bold",
    },
    description: {
      width: "80%",
      textAlign: "center",
      marginTop: "3%",
      marginLeft: "10%",
    },
    tag: {
      alignItems: "center",
      backgroundColor: "lightgray",
      borderRadius: 25,
      marginTop: "2%",
      padding: 10,
    },
    flexRow: {
      flex: 1,
      flexDirection: "row",
    },
    marginButtons: {
      marginTop: 20,
      marginLeft: 20,
    },
    addButtons: {
      width: "90%",
      marginLeft: "5%",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    row: {
      flexDirection: "row",
      width: "90%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "3%",
      marginLeft: "5%",
    },
  },
};

export const HorecaProductDetailMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "HorecaProductDetailScreen",
  sheet: {
    container: {
      flex: 1,
    },
    productImage: {
      marginTop: 11,
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 20,
      fontWeight: "bold",
      width: "100%",
      marginHorizontal: "25%",
      marginTop: "3%",
    },
    subTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginTop: "10%",
    },
    link: {
      marginTop: 15,
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      color: "#2e78b7",
    },
    button: {
      alignItems: "center",
      padding: 10,
      borderRadius: 25,
      marginTop: "5%",
    },
    buttonText: {
      color: "white",
      fontWeight: "bold",
    },
    description: {
      width: "80%",
      textAlign: "center",
      marginTop: "3%",
      marginLeft: "10%",
    },
    tag: {
      alignItems: "center",
      backgroundColor: "lightgray",
      borderRadius: 25,
      marginTop: "2%",
      padding: 10,
    },
    flexRow: {
      flex: 1,
      flexDirection: "row",
    },
    marginButtons: {
      marginTop: 20,
      marginLeft: 20,
    },
    addButtons: {
      width: "90%",
      marginLeft: "5%",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    row: {
      flexDirection: "row",
      width: "90%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "3%",
      marginLeft: "5%",
    },
  },
};
