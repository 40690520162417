import {
  IconDefinition,
  faArrowLeft,
  faBars,
  faCartArrowDown,
  faCartPlus,
  faShoppingBag,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import {
  DrawerActions,
  StackActions,
  useNavigation,
} from "@react-navigation/native";
import React, { useContext, useState } from "react";
import {
  Image,
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
} from "react-native";
import { Badge } from "react-native-elements";
import { TouchableOpacity } from "react-native-gesture-handler";

import { LOGOOS } from "../constants/Constants";
import useColorScheme from "../hooks/useColorScheme";
import { mediaTypes } from "../utils/actions/types";
import { ShopContext, ThemeContext } from "../utils/contexts";
import { mediaQueries } from "../utils/Utils";
import { getTheme } from "../utils/Utils";
import { FontAwesomeIcon } from "./Themed";

interface IHideOption {
  logo: boolean;
  cart: boolean;
  back: IconDefinition;
}

// import { Button, FontAwesomeIcon, Text, View } from "../../components/Themed";

export default function HeaderMenuBar(props: any) {
  const shopContext = useContext(ShopContext);
  const themeContext = useContext(ThemeContext);
  const colorTheme = useColorScheme();

  const { theme, shoppingCartItems } = shopContext.values;
  const { displaySize, platform, mobileSize } = themeContext.values;
  const { getStyle } = themeContext.helpers;

  const styles = getStyle("Header");
  const sheet = styles?.sheet;
  const currentTheme = getTheme(theme)[colorTheme];
  const navigation = useNavigation();
  const [hideOptions] = useState<IHideOption>(props.hideOptions);
  const logo = LOGOOS[theme];

  const calculateAspectHeightRatio = (
    val: number,
    ratioWidth: number,
    ratioHeight: number
  ) => {
    return Math.round((val / ratioWidth) * ratioHeight);
  };

  const [dynamicStyle, setDynamicStyles] = React.useState<
    StyleSheet.NamedStyles<any>
  >({
    logo: {
      width: displaySize.width * 0.8,
      height: displaySize.height * 0.05,
      maxHeight: 50,
    },
  });

  React.useEffect(() => {
    if (platform.isWeb) {
      if (mediaQueries() === mediaTypes.smallPhone) {
        setDynamicStyles({
          logo: {
            width: displaySize.width * 0.5,
            height: displaySize.height * 0.01,
            maxHeight: 60,
          },
        });
      } else if (mediaQueries() === mediaTypes.mediumPhone) {
        setDynamicStyles({
          logo: {
            width: displaySize.width * 0.75,
            height: displaySize.height * 0.045,
            maxHeight: 50,
          },
        });
      } else if (mediaQueries() === mediaTypes.largePhone) {
        setDynamicStyles({
          logo: {
            width: displaySize.width * 0.8,
            height: displaySize.height * 0.05,
            maxHeight: 50,
          },
        });
      } else {
        setDynamicStyles({
          logo: {
            width: displaySize.width * 0.8,
            height: displaySize.height * 0.05,
            maxHeight: 50,
          },
        });
      }
    } else {
      setDynamicStyles({
        logo: {
          width: displaySize.width * 0.8,
          height: displaySize.height * 0.05,
          maxHeight: 50,
        },
      });
    }
  }, [themeContext]);

  const openCheckout = () => {
    navigation.navigate("CheckoutScreen");
  };

  const burgerIcon = (
    <TouchableHighlight
      onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
    >
      <FontAwesomeIcon
        icon={faBars}
        size={30}
        style={sheet?.leftIcon}
        color={currentTheme.navigation.topBar.burgerIcon}
      />
    </TouchableHighlight>
  );
  const customIcon = (icon?: IconDefinition) => (
    <TouchableHighlight
      onPress={() => {
        navigation.dispatch(StackActions.pop());
      }}
    >
      {icon || (
        <FontAwesomeIcon
          icon={faArrowLeft}
          size={30}
          style={sheet?.leftIcon}
          color={currentTheme.navigation.topBar.burgerIcon}
        />
      )}
    </TouchableHighlight>
  );

  const cartIcon = (
    <TouchableOpacity onPress={openCheckout}>
      {/* navigation.dispatch(DrawerActions.toggleDrawer()) */}
      {/* <TouchableHighlight
      style={{
        ...props.styles,
        ...sheet?.shoppingCart,
        zIndex: 1000,
      }}
      onPress={openCheckout}
    > */}
      <View
        style={{
          ...sheet?.wrapper,
        }}
      >
        {shoppingCartItems > 0 && (
          <View onTouchStart={openCheckout}>
            <FontAwesomeIcon
              icon={faShoppingCart}
              size={30}
              zIndex={0}
              backgroundColor={currentTheme.navigation.topBar.backgroundColor}
              style={sheet?.rightIcon}
              // navigation.dispatch(DrawerActions.toggleDrawer())
              onPress={openCheckout}
              color={currentTheme.navigation.topBar.cardIcon}
            ></FontAwesomeIcon>
            <Text
              style={{
                ...sheet?.badge,
                fontSize: 20,
                backgroundColor: "red",
              }}
              onPress={openCheckout}
            >
              {shoppingCartItems}
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );

  return (
    <View
      style={{
        ...sheet?.headerContainer,
        backgroundColor: currentTheme.navigation.topBar.backgroundColor,
      }}
    >
      {props.showBackButton ? (
        <View style={{ ...sheet?.burgerMenu }}>{burgerIcon}</View>
      ) : (
        <View style={{ ...sheet?.burgerMenu }}>{customIcon()}</View>
      )}
      <View
        style={{
          ...sheet?.logoContainer,
          backgroundColor: currentTheme.navigation.topBar.backgroundColor,
        }}
      >
        {hideOptions.logo == true ? (
          <Image
            style={{
              ...sheet?.logo,
              width: dynamicStyle.logo.width,
              height: dynamicStyle.logo.height,
              maxHeight: dynamicStyle.logo.maxHeight,
            }}
            source={logo}
            resizeMode="contain"
          />
        ) : null}
      </View>
      {hideOptions.cart == true ? cartIcon : null}
    </View>
  );
}
