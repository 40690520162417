import React, { useReducer } from "react";

import { SectionListSet } from "../../components";
import {
  FavoriteKindItem,
  FavoriteProductItem,
  Filter,
  Price,
} from "../../types";
import {
  set_categories_dispatch,
  set_favorites_dispatch,
  set_filter_dispatch,
  set_filter_option_dispatch,
  set_results_dispatch,
} from "../actions/dispatches/FilterActions";
import { FilterState } from "../actions/types/FilterTypes";
import { FilterReducer, initialFilterState } from "../reducers";

export interface FilterContextInterface {
  values: FilterState;
  helpers: {
    setResults: (args: Price[]) => void;
    setFilters: (args: Price[], activeFilter: Filter) => void;
    setCategories: (args: SectionListSet[]) => void;
    setFavoritesGUI: (args: (FavoriteKindItem | FavoriteProductItem)[]) => void;
    setFilterOption: (args: Filter) => void;
  };
}

export const FilterContext = React.createContext<FilterContextInterface>({
  values: initialFilterState,
  helpers: {
    setResults: () => {},
    setFilters: () => {},
    setCategories: () => {},
    setFavoritesGUI: () => {},
    setFilterOption: () => {},
  },
});

// Here is where we can seperate the shop fucntions from App.tsx
const FilterProvider = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [state, dispatch] = useReducer(FilterReducer, initialFilterState);

  const setResults = async (args: Price[]) => {
    dispatch(set_results_dispatch(args));
  };

  const setFilters = async (args: Price[], activeFilter: Filter) => {
    dispatch(set_filter_dispatch(args, activeFilter));
  };

  const setCategories = async (args: SectionListSet[]) => {
    dispatch(set_categories_dispatch(args));
  };

  const setFavoritesGUI = async (args: any[]) => {
    dispatch(set_favorites_dispatch(args));
  };

  const setFilterOption = async (args: Filter) => {
    dispatch(set_filter_option_dispatch(args));
  };

  return (
    <FilterContext.Provider
      value={{
        // values are all state properties
        values: {
          ...state,
        },
        // helpers are dispatch actions and context functions
        helpers: {
          setResults,
          setFilters,
          setCategories,
          setFavoritesGUI,
          setFilterOption,
        },
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
