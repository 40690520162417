import { CssSheet } from "../../actions/types";

export const QrCodeScannerStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "QrCodeScannerScreen",
  sheet: {
    container: {
      flex: 1,
    },
    content: {
      alignItems: "center",
      justifyContent: "center",
    },
    header: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    headerContent: {
      flexDirection: "row",
      justifyContent: "center",
      shadowRadius: 5,
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
      shadowColor: "#ffffff",
      width: "100%",
    },
    footer: {
      flexDirection: "column",
      marginTop: "6%",
      width: "100%",
      height: "25%",
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
    },
    footerContent: {
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
    },
    footerImage: {
      padding: 30,
    },
    backIconContainer: {
      width: "5%",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      textAlignVertical: "center",
      marginVertical: "5%",
    },
    backIcon: {
      marginLeft: 40,
      justifyContent: "center",
    },
    qrCodeIcon: {
      width: "95%",
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: "5%",
      paddingRight: "5%",
      alignSelf: "center",
      textAlignVertical: "center",
    },
    button: {
      marginTop: 20,
    },
    info: {
      marginTop: 5,
      padding: 5,
      fontSize: 18,
      fontWeight: "bold",
      textAlign: "center",
    },
    logo: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      height: 80,
      width: 80,
    },
    barcodeScanner: {
      marginTop: "6%",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "stretch",
      borderRadius: 10,
      borderWidth: 2,
    },
    containerSubCategory: {
      backgroundColor: "transparent",
      marginLeft: 10,
      marginTop: 15,
      width: 75,
    },
    list: {
      width: "90%",
      marginLeft: 15,
      marginTop: 25,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      color: "white",
    },
  },
};

export const QrCodeScannerMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "QrCodeScannerScreen",
  sheet: {
    container: {
      flex: 1,
    },
    content: {
      alignItems: "center",
      justifyContent: "center",
    },
    header: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    headerContent: {
      flexDirection: "row",
      justifyContent: "center",
      shadowRadius: 5,
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
      shadowColor: "#ffffff",
      width: "100%",
    },
    footer: {
      flexDirection: "column",
      marginTop: "6%",
      width: "100%",
      height: "25%",
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
    },
    footerContent: {
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
    },
    footerImage: {
      padding: 30,
    },
    backIconContainer: {
      width: "5%",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      textAlignVertical: "center",
      marginVertical: "5%",
    },
    backIcon: {
      marginLeft: 40,
      justifyContent: "center",
    },
    qrCodeIcon: {
      width: "95%",
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: "10%",
      paddingRight: "5%",
      alignSelf: "center",
      textAlignVertical: "center",
    },
    button: {
      marginTop: 20,
    },
    info: {
      marginTop: 5,
      padding: 5,
      fontSize: 18,
      fontWeight: "bold",
      textAlign: "center",
    },
    logo: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      height: 80,
      width: 80,
    },
    barcodeScanner: {
      marginTop: "6%",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "stretch",
      borderRadius: 10,
      borderWidth: 2,
    },
    containerSubCategory: {
      backgroundColor: "transparent",
      marginLeft: 10,
      marginTop: 15,
      width: 75,
    },
    list: {
      width: "90%",
      marginLeft: 15,
      marginTop: 25,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      color: "white",
    },
  },
};
