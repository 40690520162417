// reducer will manage the state based on actions
import { Dimensions, Platform } from "react-native";

import { defaultTheme } from "../../constants/Colors";
import { SET_DIMENTION, SET_THEME } from "../actions/types";
import {
  CssSheet,
  MobileWindowSize,
  SET_ORIENTATION,
  SET_SHEET,
  ThemeAction,
  ThemeColor,
  ThemeState,
  WindowSize,
} from "../actions/types/ThemeTypes";
import {
  ActionButtonMobileStyles,
  ActionButtonStyles,
  AddToCartModalMobileStyles,
  AddToCartModalStyles,
  CannabisProductDetailMobileStyles,
  CannabisProductDetailStyles,
  CannabisProductNewDetailMobileStyles,
  CannabisProductNewDetailStyles,
  CardMobileStyles,
  CardStyles,
  CategoryMobileIosStyles,
  CategoryMobileStyles,
  CategoryNewMobileIosStyles,
  CategoryNewMobileStyles,
  CategoryNewStyles,
  CategoryScreenIosMobileStyles,
  CategoryScreenMobileStyles,
  CategoryScreenStyles,
  CategoryStyles,
  CheckOutMobileStyles,
  CheckOutStyles,
  CheckoutCardMobileStyles,
  CheckoutCardStyles,
  DrawerMobileStyles,
  DrawerStyles,
  FavoritesScreenMobileStyles,
  FavoritesScreenStyles,
  FilterMobileStyles,
  FilterStyles,
  HeaderMobileStyles,
  HeaderStyles,
  HorecaProductDetailMobileStyles,
  HorecaProductDetailStyles,
  ImageCarouselMobileStyles,
  ImageCarouselNewMobileStyles,
  ImageCarouselNewStyles,
  ImageCarouselStyles,
  ImageDetailScreenMobileStyles,
  ImageDetailScreenStyles,
  NavigationFilterMobileStyles,
  NavigationFilterStyles,
  NavigationMobileStyles,
  NavigationStyles,
  NotFoundMobileStyles,
  NotFoundStyles,
  OrdersScreenMobileStyles,
  OrdersScreenStyles,
  PlaceholderCardMobileStyles,
  PlaceholderCardStyles,
  ProductsMobileStyles,
  ProductsStyles,
  QrCodeScannerMobileStyles,
  QrCodeScannerStyles,
  SearchInputMobileStyles,
  SearchInputStyles,
  SettingsScreenMobileStyles,
  SettingsScreenStyles,
  ShoppingCartNavMobileStyles,
  ShoppingCartNavStyles,
  ShoppingCartTotalMobileStyles,
  ShoppingCartTotalStyles,
} from "../helpers/styles";
import {
  AppStoreButtonMobileStyles,
  AppStoreButtonStyles,
} from "../helpers/styles/AppStoreButtonCss";
import {
  DownloadAppScreenMobileStyles,
  DownloadAppScreenStyles,
} from "../helpers/styles/DownloadAppScreenCss";
import {
  OrderNavMobileStyles,
  OrderNavStyles,
} from "../helpers/styles/OrderNavCss";
import {
  OrderTotalMobileStyles,
  OrderTotalStyles,
} from "../helpers/styles/OrderTotalCss";
import { mediaQueries } from "../Utils";

export const initialThemeState: ThemeState = {
  platform: {
    os: [Platform.OS],
    viewPort: mediaQueries(),
    isWeb: Platform.OS === "web",
    isAndroid: Platform.OS === "android",
    isIos: Platform.OS === "ios",
    isWindows: Platform.OS === "windows",
  },
  theme: {
    pallet: defaultTheme,
    isDark: true,
  },
  displaySize: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  },
  mobileSize: {
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    isPortrait:
      Dimensions.get("window").width < Dimensions.get("window").height,
  },
  styles: [
    // Category
    CategoryStyles,
    CategoryMobileStyles,
    CategoryMobileIosStyles,
    CategoryNewStyles,
    CategoryNewMobileStyles,
    CategoryNewMobileIosStyles,
    // CheckOut
    CheckOutStyles,
    CheckOutMobileStyles,
    // Orders
    OrdersScreenStyles,
    OrdersScreenMobileStyles,
    // Add To Cart
    AddToCartModalStyles,
    AddToCartModalMobileStyles,
    // Cannabis
    CannabisProductDetailStyles,
    CannabisProductDetailMobileStyles,
    CannabisProductNewDetailStyles,
    CannabisProductNewDetailMobileStyles,
    // Favorites
    FavoritesScreenStyles,
    FavoritesScreenMobileStyles,
    // Horeca
    HorecaProductDetailStyles,
    HorecaProductDetailMobileStyles,
    // Image
    ImageDetailScreenStyles,
    ImageDetailScreenMobileStyles,
    // not found
    NotFoundStyles,
    NotFoundMobileStyles,
    // Product
    ProductsStyles,
    ProductsMobileStyles,
    // // QR
    QrCodeScannerStyles,
    QrCodeScannerMobileStyles,
    // QrCodeScannerWebStyles,
    // QrCodeScannerWebMobileStyles,
    // settings
    SettingsScreenStyles,
    SettingsScreenMobileStyles,
    // new category screen
    CategoryScreenIosMobileStyles,
    CategoryScreenMobileStyles,
    CategoryScreenStyles,
    // ImageCarousel
    ImageCarouselStyles,
    ImageCarouselMobileStyles,
    ImageCarouselNewStyles,
    ImageCarouselNewMobileStyles,
    // ActionButton
    ActionButtonStyles,
    ActionButtonMobileStyles,
    // Drawer
    DrawerStyles,
    DrawerMobileStyles,
    // Components
    SearchInputStyles,
    SearchInputMobileStyles,

    CardStyles,
    CardMobileStyles,
    PlaceholderCardStyles,
    PlaceholderCardMobileStyles,
    FilterStyles,
    FilterMobileStyles,
    ShoppingCartTotalStyles,
    ShoppingCartTotalMobileStyles,

    ShoppingCartNavMobileStyles,
    ShoppingCartNavStyles,

    NavigationFilterStyles,
    NavigationFilterMobileStyles,

    OrderTotalMobileStyles,
    OrderTotalStyles,

    OrderNavMobileStyles,
    OrderNavStyles,
    CheckoutCardStyles,
    CheckoutCardMobileStyles,
    NavigationStyles,
    NavigationMobileStyles,
    HeaderStyles,
    HeaderMobileStyles,
    DownloadAppScreenStyles,
    DownloadAppScreenMobileStyles,
    AppStoreButtonStyles,
    AppStoreButtonMobileStyles,
  ],
};

const ThemeReducer = (
  state: ThemeState = initialThemeState,
  action: ThemeAction
): ThemeState => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.data as ThemeColor,
      };
    case SET_DIMENTION:
      return {
        ...state,
        displaySize: action.data as WindowSize,
        mobileSize: action.data as MobileWindowSize,
      };
    case SET_ORIENTATION:
      return {
        ...state,
        mobileSize: {
          ...state.mobileSize,
          isPortrait: action.data as boolean,
        },
      };
    case SET_SHEET:
      state.styles = action.data as CssSheet[];
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ThemeReducer;
