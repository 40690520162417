import React, { memo, useContext, useState } from "react";
import { Text, View } from "react-native";
import {
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
  ShineOverlay,
} from "rn-placeholder";

import { ThemeContext } from "../utils/contexts";

export const PlaceholderCardWrapper = ({ children }: IPlaceholderProps) => {
  const themeContext = useContext(ThemeContext);
  const { getStyle } = themeContext.helpers;

  const styles = getStyle("PlaceholderCard");
  const sheet = styles?.sheet;

  return <View style={[sheet?.card]}>{children}</View>;
};

const PlaceholderCard = (props: any) => {
  return (
    <View>
      <PlaceholderCardWrapper>
        <Placeholder
          Left={(props) => (
            <PlaceholderMedia
              style={[
                {
                  width: "100%",
                  height: 75,
                  marginTop: 0,
                  padding: 0,
                  top: "-10%",
                },
                props.style,
              ]}
            />
          )}
          Animation={ShineOverlay}
        ></Placeholder>
      </PlaceholderCardWrapper>
      {[1, 2, 3, 4].map((i) => (
        <PlaceholderCardWrapper key={i}>
          <Placeholder
            Left={(props) => (
              <PlaceholderMedia
                style={[
                  {
                    width: 100,
                    height: 100,
                  },
                  props.style,
                ]}
              />
            )}
            Right={(props) => (
              <View>
                <PlaceholderLine width={100} />
                <PlaceholderMedia
                  style={[
                    {
                      marginTop: "75%",
                    },
                  ]}
                />
              </View>
            )}
            Animation={ShineOverlay}
          >
            <PlaceholderLine width={40} />
            <PlaceholderLine
              width={80}
              style={[
                {
                  marginTop: "15%",
                },
              ]}
            />
            <View
              style={[
                {
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <PlaceholderLine
                width={25}
                style={[
                  {
                    marginRight: 10,
                  },
                ]}
              />
              <PlaceholderLine
                width={25}
                style={[
                  {
                    marginRight: 10,
                  },
                ]}
              />
              <PlaceholderLine width={25} />
            </View>
          </Placeholder>
        </PlaceholderCardWrapper>
      ))}
    </View>
  );
};

export default memo(PlaceholderCard, (props, nextProps) => {
  if (props === nextProps) {
    return true;
  }
  return false;
});
