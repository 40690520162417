/**
 * The Observer interface declares the update method, used by subjects.
 */
export interface IObserver {
  // Receive update from subject.
  update(subject: Subject): void;
}

/**
 * The Subject interface declares a set of methods for managing subscribers.
 */
export interface ISubject {
  // Attach an observer to the subject.
  attach(observer: IObserver): void;

  // Detach an observer from the subject.
  detach(observer: IObserver): void;

  // Notify all observers about an event.
  notify(): void;
}

export class Subject {
  /**
   * @type {number} For the sake of simplicity, the Subject's state, essential
   * to all subscribers, is stored in this variable.
   */
  public state: number | undefined;

  /**
   * @type {Observer[]} List of subscribers. In real life, the list of
   * subscribers can be stored more comprehensively (categorized by event
   * type, etc.).
   */
  private observers: IObserver[] = [];

  /**
   * The subscription management methods.
   */
  public attach(observer: IObserver): void {
    const isExist = this.observers.includes(observer);
    if (isExist) {
      return console.log("Subject: Observer has been attached already.");
    }

    this.observers.push(observer);
  }

  public detach(observer: IObserver): void {
    const observerIndex = this.observers.indexOf(observer);
    if (observerIndex === -1) {
      return console.log("Subject: Nonexistent observer.");
    }

    this.observers.splice(observerIndex, 1);
  }

  /**
   * Trigger an update in each subscriber.
   */
  public notify(): void {
    for (const observer of this.observers) {
      observer.update(this);
    }
  }

  public onScrollCalculated(rawScrollValue: number): void {
    this.state = Math.round(rawScrollValue);
    this.notify();
  }
}

export class Observer {
  public updateFunc: (input: any) => void;

  constructor(updateFunc: (input: any) => void) {
    this.updateFunc = updateFunc;
  }

  public update(subject: Subject): void {
    if (subject instanceof Subject) {
      this.updateFunc(subject);
    }
  }
}
