// reducer will manage the state based on actions
import { Platform } from "react-native";

import { apiEndpoints } from "../../constants/ApiEndpoints";
import {
  API_URL,
  SHOPS_CONFIG,
  STORE_VERSION,
} from "../../constants/Constants";
import {
  DISABLE_BACK,
  SET_DIMENTION,
  SET_RENDER_OPTIONS,
  SET_THEME,
} from "../actions/types";
import {
  BaseAction,
  BaseState,
  SET_CONFIG,
  SET_VERSION,
} from "../actions/types/BaseTypes";
import { Endpoints, ShopQuery } from "../actions/types/RouteTypes";

const setEndpoints = (backendMode: string) => {
  let endpoints: any = {};
  Object.keys(apiEndpoints).forEach((key) => {
    endpoints[key] = `${backendMode}`;
  });
  endpoints = recursiveEndpointBuilder(endpoints, apiEndpoints, undefined);
  return endpoints;
};

const recursiveEndpointBuilder = (
  endpoints: any,
  object: any,
  pref: string | undefined
) => {
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === "object") {
      let prefix;
      if (endpoints[key]) prefix = `${endpoints[key]}`;
      else prefix = `${pref}`;
      endpoints[key] = {};
      recursiveEndpointBuilder(endpoints[key], object[key], prefix);
      return endpoints;
    }
    if (pref) {
      endpoints[key] = new ShopQuery(pref).route(object[key]);
      endpoints[key]["full_url"] = `${pref}/${object[key]}`;
    } else {
      const val = endpoints[key];
      endpoints[key] = new ShopQuery(val).route(object[key]);
      endpoints[key]["full_url"] = `${val}/${object[key]}`;
    }
  });
  return endpoints;
};

// This state is now hadcoded later it will be
export const initialBaseState: BaseState = {
  version: STORE_VERSION,
  config: SHOPS_CONFIG,
  api: setEndpoints(API_URL),
  hideBackButton: true,
  baseRoutes: ["CategoryScreen"],
  renderOptions: {
    renderQrScreen: Platform.OS !== "web",
    renderWebQrScreen: Platform.OS === "web",
    renderDownloadAppScreen: Platform.OS === "web",
  },
};

// Here is where we will switch views based on version check and store configuration
const BaseReducer = (
  state: BaseState = initialBaseState,
  action: BaseAction
): BaseState => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        config: action.data,
      };
    case SET_VERSION:
      return {
        ...state,
        version: action.data,
      };
    case DISABLE_BACK: {
      return {
        ...state,
        hideBackButton: action.data,
      };
    }
    case SET_RENDER_OPTIONS: {
      return {
        ...state,
        renderOptions: action.data,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default BaseReducer;
