import "./locale/en";
import "./locale/nl";

import I18n from "i18n-js";
import React, { useEffect } from "react";
import { Platform, Text, TextInput } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { QueryCache, QueryClient, QueryClientConfig, QueryClientProvider } from "react-query";

import useCachedResources from "./hooks/useCachedResources";
import { AppLanguage, RenderOptions } from "./types";
import { STORE_VERSION, DISABLE_WARNINGS } from "./constants/Constants";
import { LanguageContext } from "./utils/Language";
import { BaseProvider, ShopProvider } from "./utils/contexts";
import { useFonts, Roboto_400Regular } from '@expo-google-fonts/roboto';
import { LogBox } from 'react-native';
import { Routes } from "./navigation/new/Routes";

if (DISABLE_WARNINGS) {
  LogBox.ignoreAllLogs();//Ignore all log notifications
  // LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message
}

// Disable dynamic font resizing:
// https://stackoverflow.com/questions/41807843/how-to-disable-font-scaling-in-react-native-for-ios-app
Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;
TextInput.defaultProps = TextInput.defaultProps || {};
TextInput.defaultProps.allowFontScaling = false;

const queryClientConfig: QueryClientConfig = {
  queryCache: new QueryCache({
    onError: error => {
      // console.log('ON ERROR CACHE ',error)
    },
    onSuccess: data => {
      // console.log('ON SUCCESS CACHE ', data)
    }
  }),
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60, //60 seconds
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchIntervalInBackground: false,
      suspense: false,
    },
  },
}

const queryClient = new QueryClient(queryClientConfig);

export default function App() {
  let [fontsLoaded] = useFonts({
    Roboto_400Regular,
  });
  // if (!fontsLoaded) {
  //     return <AppLoading />;
  // }

  const isLoadingComplete = useCachedResources();
  

  const [language, setLanguage] = React.useState("en");

  const getAppLanguage = async () => {
    if (Platform.OS === "web") {
      const appLanguage = window.localStorage.getItem(`${STORE_VERSION}-appLanguage`)
      if (appLanguage !== null) {
        console.log("APP is setting stored language", appLanguage);
        I18n.locale = appLanguage;
        setLanguage(appLanguage);
      }
    } else {
      try {
        const appLanguage = await AsyncStorage.getItem(
          `${STORE_VERSION}-appLanguage`
        );

        if (appLanguage !== null) {
          console.log("APP is setting stored language", appLanguage);
          I18n.locale = appLanguage;
          setLanguage(appLanguage);
        }
      } catch (e) {
        alert("Failed to fetch the data from device storage");
      }
    }
  };

  const setAppLanguage = async (appLanguage: AppLanguage) => {
    if (Platform.OS === "web") {
      I18n.locale = appLanguage;
      setLanguage(appLanguage);
      window.localStorage.setItem(`${STORE_VERSION}-appLanguage`, appLanguage)
    } else {
      try {
        await AsyncStorage.setItem(`${STORE_VERSION}-appLanguage`, appLanguage);
      } catch (e) {
        // save error
      }
      I18n.locale = appLanguage;
      setLanguage(appLanguage);
    }
  };

  React.useEffect(() => {
    getAppLanguage();
  }, []);



  // QR
  // QrCodeScannerStyles,
  // QrCodeScannerMobileStyles,
  // QrCodeScannerWebStyles,
  // QrCodeScannerWebMobileStyles,

  useEffect(() => {

  }, []);

  if (!isLoadingComplete || !fontsLoaded) {
    // in future loading animation
    return null;
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <BaseProvider>
          <SafeAreaProvider>
            <ShopProvider>
              <LanguageContext.Provider
                value={{ appLanguage: language, setAppLanguage: setAppLanguage }}
              >
                <Routes />
                {/* {renderInitView(render)} */}
              </LanguageContext.Provider>
            </ShopProvider>
          </SafeAreaProvider>
        </BaseProvider>
      </QueryClientProvider>
    );
  }
}
