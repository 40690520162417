import { CssSheet } from "../../actions/types";

export const DrawerStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "Drawer",
  sheet: {
    drawerContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      textAlign: "center",
    },
  },
};

export const DrawerMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "Drawer",
  sheet: {
    drawerContainer: {
      flex: 1,
      flexDirection: "row",
      textAlign: "center",
    },
  },
};
