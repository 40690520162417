import { CssSheet } from "../../actions/types";

export const FavoritesScreenStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "FavoritesScreen",
  sheet: {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
    },
    containerContent: {
      alignItems: "center",
      justifyContent: "center",
    },
    list: {
      width: "100%",
    },
    title: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 22,
      marginBottom: 10,
    },
    separator: {
      marginVertical: 30,
      marginTop: 1,
      height: 1,
      width: "100%",
    },
    rowBack: {
      alignItems: "center",
      backgroundColor: "#ff453a",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: 10,
    },
    deleteButton: {
      marginRight: 10,
    },
    productImage: {
      marginRight: 20,
      marginLeft: 10,
    },
    flexRow: {
      flex: 1,
      flexDirection: "row",
    },
    productName: {
      fontWeight: "bold",
    },
    description: {
      marginTop: "5%",
      marginBottom: "5%",
      width: 150,
    },
    usageTip: {
      fontStyle: "italic",
    },
  },
};

export const FavoritesScreenMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "FavoritesScreen",
  sheet: {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
    },
    containerContent: {
      alignItems: "center",
      justifyContent: "center",
    },
    list: {
      width: "100%",
    },
    title: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 22,
      marginBottom: 10,
    },
    separator: {
      marginVertical: 30,
      marginTop: 1,
      height: 1,
      width: "100%",
    },
    rowBack: {
      alignItems: "center",
      backgroundColor: "#ff453a",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: 10,
    },
    deleteButton: {
      marginRight: 10,
    },
    productImage: {
      marginRight: 20,
      marginLeft: 10,
    },
    flexRow: {
      flex: 1,
      flexDirection: "row",
    },
    productName: {
      fontWeight: "bold",
    },
    description: {
      marginTop: "5%",
      marginBottom: "5%",
      width: 150,
    },
    usageTip: {
      fontStyle: "italic",
    },
  },
};
