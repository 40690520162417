import React, { useState } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

export default function LoadingView({ color }: { color: string }) {
  return (
    <View style={styles.container}>
      <ActivityIndicator size="small" color={color} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.7,
    justifyContent: "center",
    alignSelf: "center",
  },
});
