// Interpolation works as follows:
//
// Make a key with the translation and enclose the variable with {{}}
// ie "Hello {{name}}" Do not add any spaces around the variable name.
// Provide the values as: I18n.t("key", {name: "John Doe"})
import I18n from "i18n-js";

I18n.translations.nl = {
  code: "NL",
  name: "Nederlands",
  select_locale: "Selecteer Nederlands",
  loading: "Laden...",
  EntityId: "",
  header: {
    shoppingCartPay: "Betaal",
  },
  introScreen: {
    title: "Prijslijst - QR code info",
    text:
      "Om deze app te gebruiken moet je een prijslijst QR code scannen. Die vind je in de buurt van establisementen of op websites die meedoen met de prijslijst app.",
    scanButton: "Scan QR Code",
  },
  chooseProductKindPage: {
    title: "Kies een categorie",
  },
  SettingsScreen: {
    title: "Instellingen",
    currentLanguage: "Huidige taal",
    currentTheme: "Huidig Thema",
  },
  shopPage: {
    title: "Prijzen",
    category: "Categorie",
    filter: "Filter",
  },
  drawerNavigation: {
    lastOrders: "Vorige bestellingen",
  },
  Screens: {
    Products: "Producten",
    ProductInfo: "Producten info",
    Settings: "Instellingen",
    Favorites: "Favorieten",
    Checkout: "Bestellen",
    AddToCart: "Voeg toe aan winkelwagen",
    QrCodeScanner: "Scan QR code",
    ZoomImage: "Zoom foto",
  },
  productDetailScreen: {
    description: "Beschrijving",
    flavors: "Smaken",
    effects: "Effecten",
    addToCart: "Voeg toe aan",
    prices: "Prijzen",
    price: "Prijs",
    strains: "Soorten",
  },
  checkoutPage: {
    title: "Bestellen",
    tableHeader: "Producten",
    productColumnName: "Item",
    priceColumnName: "Prijs",
    orderButton: "Bestel",
    orderStatus: "Order status",
    processingHeader: "Bestelling bezig",
    customerOrderId: "Order-nummer",
    noTableFound:
      "Geen tafel gevonden. Scan a.u.b. de QR code van een table in de shop om verder te gaan.",
    orderProcessHelpText:
      "Even geduld, je bestelling wordt ingepakt, deze pagina ververst zichzelf wanneer de bestelling klaar is.",
    MAX_5_GRAMS_ALLOWED: "Maximaal 5 gram cannabis per klant!",
    OUT_OF_STOCK:
      "is helaas niet meer op voorraad nu. Verwijder en probeer het opnieuw.",
    ORDER_OK: "Bestelling gelukt!",
    table1: "Tafel 1",
    table2: "Tafel 2",
    table3: "Tafel 3",
    table4: "Tafel 4",
    table5: "Tafel 5",
    table6: "Tafel 6",
    table7: "Tafel 7",
    table8: "Tafel 8",
    table9: "Tafel 9",
    table10: "Tafel 10",
    resetCart: "mandje leegmaken",
    total: "Totaal:",
    reset: "Winkelwagen resetten",
    browse: "Blader door producten",
    gramTotal: "Grams",
    subTotal: "Subtotaal",
  },
  ordersScreen: {
    title: "Order status",
    total: "Totaal:",
    cancelled: "Geannuleerd",
    pending: "Bezig",
    complete: "Klaar",
  },
  shoppingCartModal: {
    selectQuantity: "Selecteer een hoeveelheid",
    add: "Toevoegen",
    close: "Sluiten",
    title: "Bestellen",
  },
  productsScreen: {
    piece: "per stuk",
  },
  favoritesScreen: {
    title: "Favorieten",
    resetFavorites: "Leeg favorieten",
    useSwipe: "gebruik swipe om te wissen",
  },
  imageCarouselDetailScreen: {
    useSwipe: "gebruik 2 vingers om in te zoomen",
  },
  qrCodeScreen: {
    info: "Scan een QR code om een prijslijst te laden en iets te bestellen",
  },
  imageCarouselScreen: {
    photo: "Foto",
  },
  filters: {
    reset: "Filters resetten",
    favorites: "Favorieten",
    cbd: "CBD",
    indica: "Indica",
    hybride: "Hybride",
    sativa: "Sativa",
    new: "Nieuw",
  },
  sorting: {
    califlower: "Califlowers",
    flower: "Flowers",
    hash: "Hasj",
    specialties: "Specialiteiten",
    smoke: "Rokers benodigheden",
    food: "Eten",
    fuzzyDrinks: "Fris drank",
    sweets: "Snoep",
    milkshakeNsmoothy: "Milkshakes & Smoothies",
    warmDrinks: "Warme drank",
  },
  order: {
    list: {
      tableNr: "Nr: ",
      noOrders: "Er zijn nog geen bestellingen gedaan",
    },
    table: {
      name: "Tafel",
    },
  },
  appStore: {
    title: "Download de app",
    subTitle: "Kies een restaurant, bestel en eet!",
    button: {
      apple: {
        title: "App Store",
        subtitle: "Downloaden op de",
      },
      google: {
        title: "Google Play",
        subtitle: "Downloaden op de",
      },
    },
  },
};

export default I18n.translations.nl;
