import { CssSheet } from "../../actions/types";

export const AppStoreButtonStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "AppStoreButton",
  sheet: {
    container: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      alignSelf: "center",
      padding: 10,
      borderRadius: 3,
      shadowOffset: {
        width: 2,
        height: 6,
      },
      shadowOpacity: 0.2,
      shadowRadius: 8.3,
    },
    icon: {
      paddingRight: 5,
      fontSize: 20,
      justifyContent: "center",
    },
    title: {
      fontSize: 14,
    },
    subtitle: {
      fontSize: 10,
    },
  },
};

export const AppStoreButtonMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "AppStoreButton",
  sheet: {},
};
