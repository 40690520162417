import { faSearchPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import Swiper from "react-native-web-swiper";

import useColorScheme from "../hooks/useColorScheme";
import { ShopContext, ThemeContext } from "../utils/contexts";
import { getTheme } from "../utils/Utils";
import ProductImage from "./ProductImage";
import { FontAwesomeIcon } from "./Themed";

// REVIEW Change this to dynamic stuff
export default function ImageCarousel({
  navigation,
  images,
}: {
  navigation: any;
  images: string[];
}) {
  const shopContext = useContext(ShopContext);

  const themeContext = useContext(ThemeContext);
  const colorTheme = useColorScheme();

  const { getStyle } = themeContext.helpers;
  const { platform, displaySize, mobileSize } = themeContext.values;
  const newTheme = themeContext.newTheme;

  const styles = getStyle(newTheme ? "ImageCarouselNew" : "ImageCarousel");
  const sheet = styles?.sheet;
  const [state] = useState<string[]>(images);

  const { theme } = shopContext.values;

  const currentTheme = getTheme(theme)[colorTheme];

  const views = state.map((image, index) => (
    <View style={sheet?.slideContainer} key={index}>
      {/* {!newTheme || platform.isWeb || platform.isIos ? null : ( */}
      {/* <> */}
      {/* <TouchableOpacity
            style={[sheet?.item]}
            onPress={() =>
              navigation.navigate("ImageDetailScreen", {
                image: image,
              })
            }
          >
            <FontAwesomeIcon icon={faStar} size={40} color={"#d1cb28"} />
          </TouchableOpacity> */}
      {/* </> */}
      {/* )} */}
      <ProductImage
        imageMap={{
          width: platform.isWeb ? displaySize.width : mobileSize.width / 1.2,
          height: platform.isWeb
            ? displaySize.height / 2
            : mobileSize.height / 2.2,
          borderColor: currentTheme.product.image.color,
          borderRadius: 12,
          borderWidth: 2,
        }}
        resolution={{
          height: platform.isWeb
            ? Math.round(displaySize.height)
            : Math.round(mobileSize.height),
          width: platform.isWeb
            ? Math.round(displaySize.width)
            : Math.round(mobileSize.width),
          fit: "contain",
        }}
        name={image}
      />
      <TouchableOpacity
        style={[
          sheet?.clickOnImage,
          { backgroundColor: currentTheme.product.image.icon },
        ]}
        onPress={() =>
          navigation.navigate("ImageDetailScreen", { image: image })
        }
      >
        <FontAwesomeIcon icon={faSearchPlus} color={"white"} size={25} />
      </TouchableOpacity>
    </View>
  ));

  return (
    <Swiper
      controlsProps={{
        prevPos: false,
        nextPos: false,
      }}
    >
      {views}
    </Swiper>
  );
}
