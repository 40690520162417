// reducer will manage the state based on actions
import { SET_SHOP_CONTEXT } from "../actions/types";
import { ShopAction, ShopState } from "../actions/types/ShopTypes";

export const initialShopState: ShopState = {
  theme: "default",
  shopId: "",
  tableId: "",
  shoppingCartItems: 0,
};

const ShopReducer = (
  state: ShopState = initialShopState,
  action: ShopAction
): ShopState => {
  switch (action.type) {
    case SET_SHOP_CONTEXT:
      return {
        theme: action.data.theme,
        shopId: action.data.shopId,
        tableId: action.data.tableId,
        shoppingCartItems: action.data.shoppingCartItems,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ShopReducer;
