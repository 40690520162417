import { CssSheet } from "../../actions/types";

export const PlaceholderCardStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "PlaceholderCard",
  sheet: {
    card: {
      backgroundColor: "white",
      borderRadius: 3,
      elevation: 3,
      marginBottom: 12,
      padding: 20,
    },
    dark: {
      backgroundColor: "#111111",
    },
  },
};

export const PlaceholderCardMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "PlaceholderCard",
  sheet: {
    card: {
      backgroundColor: "white",
      borderRadius: 3,
      elevation: 3,
      marginTop: 12,
      marginBottom: 12,
      padding: 20,
    },
    dark: {
      backgroundColor: "#111111",
    },
  },
};
