import { VerticalAlignCenter } from "@material-ui/icons";

import { CssSheet } from "../../actions/types";

export const CheckoutCardStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "CheckoutCard",
  sheet: {
    flexContainer: {
      flex: 1,
      flexDirection: "row",
    },
    card: {
      width: "100%",
      height: "100%",
      elevation: 18,
      overflow: "hidden",
      top: "1%",
    },
    image: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    rect: {
      // left: "25%",
      height: "100%",
      width: "100%",
      opacity: 1,
    },
    imageWrapper: {
      top: "5%",
      left: 20,
      width: "100%",
      height: "90%",
      position: "relative",
    },
    image1: {
      position: "relative",
      bottom: 10,
    },
    title: {
      justifyContent: "flex-start",
      fontSize: 16,
    },
    subTitle: {
      position: "relative",
      fontSize: 9,
      paddingTop: "5%",
    },
    loremIpsum: {
      left: 109,
      position: "relative",
      fontSize: 9,
    },
    footerContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "space-between",
    },
    headerContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    ind: {
      fontSize: 14,
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: "solid",
      padding: 3,
    },
    groupControllerButtons: {
      // width: 200
    },
    gram: {
      fontSize: 9,
    },
    gramPrice: {
      justifyContent: "center",
      alignItems: "center",
      margin: "2%",
      fontSize: 16,
    },
    joint: {
      fontSize: 9,
    },
    jointPrice: {
      position: "relative",
      fontSize: 14,
    },
    text2Stack: {
      position: "relative",
    },
    containerWrapper: {
      width: "25%",
      position: "relative",
      justifyContent: "flex-start",
    },
  },
};

export const CheckoutCardMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "CheckoutCard",
  sheet: {
    container: {
      height: 100,
      width: "100%",
      borderBottomWidth: 1,
      borderBottomColor: "white",
    },
    flexContainer: {
      flex: 1,
      flexDirection: "row",
      width: "100%",
    },
    card: {
      width: "100%",
      height: "100%",
      elevation: 18,
      overflow: "hidden",
    },
    image: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    rect: {
      left: "20%",
      height: "100%",
      width: "80%",
      opacity: 1,
    },
    imageWrapper: {
      padding: "5%",
      width: "100%",
      height: "90%",
      position: "relative",
    },
    image1: {
      position: "relative",
      bottom: 10,
    },
    title: {
      fontSize: 17,
      justifyContent: "flex-end",
    },
    subTitle: {
      marginTop: -8,
      fontSize: 14,
    },
    loremIpsum: {
      left: 109,
      position: "relative",
      fontSize: 9,
    },
    footerContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    headerContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    ind: {
      marginTop: -5,
      marginRight: -4,
      fontSize: 12,
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: "solid",
      padding: 3,
    },
    amountController: {
      flex: 1,
      justifyContent: "flex-end",
    },
    amountContent: {
      flexDirection: "row",
      alignItems: "flex-end",
      textAlignVertical: "center",
    },
    // gram: {
    //   fontSize: 11,
    // },
    gramPrice: {
      fontSize: 16,
      margin: "2%",
    },
    joint: {
      fontSize: 11,
    },
    jointPrice: {
      fontSize: 14,
      marginLeft: -10,
    },
    text2Stack: {
      position: "relative",
    },
    quantityWrapper: {
      width: 55,
      fontSize: 20,
      textAlignVertical: "center",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: 15,
      marginTop: -30,
    },
    containerWrapper: {
      width: "25%",
      position: "relative",
      justifyContent: "flex-start",
      zIndex: 2,
    },
  },
};
