import React from "react";

import { SupportedTheme } from "../types";

export interface ThemeContextInterface {
  appTheme: SupportedTheme;
  setAppTheme: (appTheme: SupportedTheme) => void;
}

// create the theme context with default selected theme
export const ThemeContext = React.createContext<ThemeContextInterface>({
  appTheme: "default",
  setAppTheme: (appTheme: SupportedTheme) => {},
});
