import { CssSheet, mediaTypes } from "../../actions/types";

export const CategoryScreenStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "CategoryScreen",
  queries: [
    {
      view: mediaTypes.smallPhone,
      sheet: {
        cardImage: {
          width: 80,
          height: 80,
          borderRadius: 20,
          borderWidth: 1,
        },
      },
    },
    {
      view: mediaTypes.mediumPhone,
      sheet: {
        cardImage: {
          width: 90,
          height: 90,
          borderRadius: 20,
          borderWidth: 1,
        },
      },
    },
    {
      view: mediaTypes.largePhone,
      sheet: {
        cardImage: {
          width: 100,
          height: 100,
          borderRadius: 20,
          borderWidth: 1,
        },
      },
    },
  ],
  sheet: {
    container: {
      flex: 1,
      flexDirection: "row",
    },
    bodyFiller: {
      flex: 1,
      flexDirection: "row",
    },
    row: {
      alignSelf: "center",
      // display: ""
    },
    body: {
      width: "100%",
    },
    pageName: {
      top: 34,
      left: 35,
      position: "relative",
      fontSize: 24,
      fontFamily: "Roboto_400Regular",
    },
    cardImage: {
      width: 100,
      height: 100,
      borderRadius: 20,
      borderWidth: 1,
    },
    cupertinoSearchBarWithMic: {
      width: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 2000,
    },
    pageNameStack: {
      width: "100%",
      height: "9%",
    },
    image: {
      width: "100%",
      height: 82,
      marginTop: 10,
    },
    textInput: {
      color: "rgba(255,255,255,1)",
      fontSize: 34,
      fontFamily: "Roboto_400Regular",
      width: "100%",
      height: 40,
      textAlign: "center",
      backgroundColor: "rgba(4,0,0,1)",
    },
    filterList: {
      paddingTop: "3%",
    },
  },
};

export const CategoryScreenMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android"],
  },
  route: "CategoryScreen",
  sheet: {
    container: {
      flex: 1,
      flexDirection: "row",
    },
    bodyFiller: {
      flex: 1,
      flexDirection: "row",
    },
    row: {
      alignSelf: "center",
      // display: ""
    },
    body: {
      width: "100%",
    },
    pageName: {
      top: 34,
      left: 35,
      position: "relative",
      fontSize: 24,
      fontFamily: "Roboto_400Regular",
    },
    cardImage: {
      width: 100,
      height: 100,
      borderRadius: 20,
      borderWidth: 1,
    },
    cupertinoSearchBarWithMic: {
      width: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 2000,
    },
    pageNameStack: {
      width: "100%",
      height: 75,
    },
    image: {
      width: "100%",
      height: 82,
      marginTop: 10,
    },
    textInput: {
      color: "rgba(255,255,255,1)",
      fontSize: 34,
      fontFamily: "Roboto_400Regular",
      width: "100%",
      height: 40,
      textAlign: "center",
      backgroundColor: "rgba(4,0,0,1)",
    },
    filterList: {
      paddingTop: "3%",
    },
  },
};

export const CategoryScreenIosMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["ios"],
  },
  route: "CategoryScreen",
  sheet: {
    container: {
      flex: 1,
      flexDirection: "row",
    },
    bodyFiller: {
      flex: 1,
      flexDirection: "row",
    },
    row: {
      alignSelf: "center",
      // display: ""
    },
    body: {
      width: "100%",
    },
    pageName: {
      top: 34,
      left: 35,
      position: "relative",
      fontSize: 24,
      fontFamily: "Roboto_400Regular",
    },
    cupertinoSearchBarWithMic: {
      width: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 2000,
    },
    pageNameStack: {
      width: "100%",
      height: 75,
      zIndex: 3000,
    },
    image: {
      width: "100%",
      height: 82,
      marginTop: 10,
    },
    textInput: {
      fontSize: 34,
      width: "100%",
      height: 40,
      textAlign: "center",
      fontFamily: "Roboto_400Regular",
    },
    filterList: {
      paddingTop: "3%",
    },
  },
};
