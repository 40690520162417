import { FontAwesome, Feather as Icon } from "@expo/vector-icons";
import I18n from "i18n-js";
import React, { useContext } from "react";
import { StyleSheet, Text, View } from "react-native";

import { LanguageContext } from "../utils/Language";

export default function Intro({
  backgroundColor,
  iconName,
  title,
  handleClick,
}: // navigation,
{
  backgroundColor: string;
  iconName: any;
  title: string;
  handleClick: any;
}) {
  const languageContext = useContext(LanguageContext);

  return (
    <View style={styles.container}>
      {/*<Image source={}*/}
      <Icon
        style={{ marginTop: -120 }}
        name={iconName}
        size={80}
        color="white"
      />
      <View style={{ marginTop: 40, marginLeft: 12, marginRight: 12 }}>
        <Text style={{ fontSize: 24, fontWeight: "bold", color: "white" }}>
          {I18n.t("introScreen.title")}
        </Text>
        <Text
          style={{
            marginTop: 10,
            fontSize: 16,
            fontWeight: "bold",
            lineHeight: 26,
            color: "white",
          }}
        >
          {I18n.t("introScreen.text")}
        </Text>
      </View>
      <View style={styles.scanButtonContainer}>
        <FontAwesome.Button
          style={styles.scanButton}
          name="camera"
          borderRadius={50}
          backgroundColor="#111111"
          onPress={() => handleClick()}
        >
          {I18n.t("introScreen.scanButton")}
        </FontAwesome.Button>
      </View>
      <View style={styles.flagsContainer}>
        <Text
          style={styles.flags}
          onPress={() => languageContext.setAppLanguage("nl")}
        >
          🇳🇱
        </Text>
        <Text
          style={styles.flags}
          onPress={() => languageContext.setAppLanguage("en")}
        >
          🇬🇧
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#593e5c",
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 22,
    marginBottom: 10,
  },
  scanButtonContainer: {
    marginTop: 40,
  },
  scanButton: {
    padding: 20,
  },
  flags: {
    fontSize: 60,
    marginTop: "2%",
  },
  flagsContainer: {
    marginTop: 40,
    flexDirection: "row",
  },
});
