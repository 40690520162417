import I18n from "i18n-js";
import React, { useContext, useState } from "react";
import { View, useColorScheme } from "react-native";
import { Text } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";

import { Currencies } from "../constants/Currencies";
import { OrderStatusItem } from "../types";
import { ShopContext, ThemeContext } from "../utils/contexts";
import { getTheme } from "../utils/Utils";

interface IProps {
  items: OrderStatusItem[];
}

interface IPropsTotal {
  item: OrderStatusItem;
}

export const OrderTotalItem = ({ item }: IPropsTotal) => {
  const shopContext = useContext(ShopContext);
  const themeContext = useContext(ThemeContext);

  const colorTheme = useColorScheme();
  const { shopId, theme } = shopContext.values;
  const currentTheme = getTheme(theme)[colorTheme];
  // styles
  const { getStyle } = themeContext.helpers;
  const styles = getStyle("OrderNavTotal");
  const sheet = styles?.sheet;

  const [total, setTotal] = useState(item.total);
  const [currency, setCurrency] = useState(Currencies.EU);

  return (
    <View
      style={{
        ...sheet?.orderListTotal,
      }}
    >
      <Text>
        {currency.unit} {total}
      </Text>
    </View>
  );
};

export const OrderNavList = ({ items }: IProps) => {
  const shopContext = useContext(ShopContext);
  const themeContext = useContext(ThemeContext);

  const colorTheme = useColorScheme();
  const { shopId, theme } = shopContext.values;
  const currentTheme = getTheme(theme)[colorTheme];
  // styles
  const { getStyle } = themeContext.helpers;
  const styles = getStyle("OrderNavList");
  const sheet = styles?.sheet;

  if (items.length === 0) {
    return (
      <ScrollView
        style={{
          ...sheet?.orderListContainer,
        }}
      >
        <View>
          <View
            style={{
              ...sheet?.orderListItem,
            }}
          >
            <Text
              style={{
                ...sheet?.orderNrItem,
              }}
            >
              {I18n.t(`order.list.noOrders`)}
            </Text>
          </View>
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView
      style={{
        ...sheet?.orderListContainer,
      }}
    >
      {items?.map((item: OrderStatusItem, index: number) => (
        <View key={`drawer_order_${index}`}>
          <View
            style={{
              ...sheet?.orderListItem,
            }}
          >
            <Text
              style={{
                ...sheet?.orderNrItem,
              }}
            >
              {I18n.t(`order.list.tableNr`)}
              {item?.customer_order_id}
            </Text>
            <Text
              style={{
                ...sheet?.orderListItem,
                color: currentTheme.navigation.navigationOrderList.textColor,
              }}
            >
              {I18n.t(`order.table.name`)}
              {item.table_name}
            </Text>
          </View>
          {items && items.length && items.length > 0 ? (
            index == items.length - 1 ? (
              <OrderTotalItem item={item}></OrderTotalItem>
            ) : null
          ) : null}
        </View>
      ))}
    </ScrollView>
  );
};
