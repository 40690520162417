/* This file contains a collection of all kind of settings for the app
 *
 * It will also let you set some parameters that will make it easier to test the apps order system or
 * to switch to testing against a local backend instead of the default production api of the pricelist
 *
 * */

import {
  faHeart as faHeartOutline,
  faStar,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCandyCane,
  faCannabis,
  faChevronRight,
  faCocktail,
  faCoffee,
  faCoins,
  faDice,
  faDownload,
  faEthernet,
  faExclamationTriangle,
  faGlassWhiskey,
  faHamburger,
  faHeart,
  faHollyBerry,
  faHotdog,
  faListAlt,
  faMugHot,
  faQrcode,
  faRetweet,
  faSmile,
  faSmoking,
  faUtensilSpoon,
  faUtensils,
  faWater,
  faWineBottle,
} from "@fortawesome/free-solid-svg-icons";
import I18n from "i18n-js";

import {
  Filter,
  INavigationFilter,
  INavigationItem,
  INavigationLanguage,
  INavigationLocalRenderSwitchItem,
  INavigationOrderList,
  INavigationTitle,
  MediaQueryConstants,
  Sort,
  SupportedImages,
  SupportedTheme,
} from "../types";

/* API SELECTION */
// should be false for all prod builds
export const USE_LOCAL_BACKEND = false;
export const USE_STAGING_BACKEND = false;

const LOCAL_API_URL = "http://192.168.30.155:5000";
const REMOTE_API_URL = "https://api.prijslijst.info";
const REMOTE_STAGIN_API_URI = "https://api.staging.prijslijst.info";
export const API_URL = USE_LOCAL_BACKEND
  ? LOCAL_API_URL
  : USE_STAGING_BACKEND
  ? REMOTE_STAGIN_API_URI
  : REMOTE_API_URL;

// Still hardcoded shop for now
export const SHOP_ID = "19149768-691c-40d8-a08e-fe900fd23bc0";

// Ability to disable console warnings in the Sim
export const DISABLE_WARNINGS = false;

/* The next setting is here to make it easier to test the shopping cart
In production each order has to be transitioned to "complete" or the client
will hang "waiting for complete" on the checkout page. When you set
`CHECKOUT_TEST = true`: the checkout page will allow you to submit an order
and handle it normally but it won't save the CHECKOUT_TOKEN, which it receives for
an order that is submitted ok, in local storage. So when you navigate to another tab
and then back to the checkout tab you will be able to re-submit the order.

PLEASE ENSURE IT'S FALSE BEFORE BUILDING AN PRODUCTION BUILD/APP.
*/
// This should be false in PROD_RELEASE
export const CHECKOUT_TEST = false;

// Change this when you introduce a backward incompatible change in the local storage
// It's used as a prefix for the local storage keys this app uses.
export const STORE_VERSION = "pricelist-v11";

export function getShoppingCartStore(shopId: string) {
  return `${STORE_VERSION}-shopping-cart-${shopId}`;
}

export function getFavoritesStore(shopId: string) {
  return `${STORE_VERSION}-favorites-${shopId}`;
}

export function getOrdersStore(shopId: string) {
  return `${STORE_VERSION}-orders-${shopId}`;
}

export function getShopAndTableStore() {
  return `${STORE_VERSION}-table`;
}

// Todo: hard coded for now
export const SHOPS_CONFIG = {
  "9c8213fe-4ad9-4136-8b8d-4aed57506703": {
    name: "Easy going",
    showShoppingCart: true,
  },
  "19149768-691c-40d8-a08e-fe900fd23bc0": {
    name: "Mississippi",
    showShoppingCart: true,
  },
  "a08a13e2-a31b-4b6d-b2b4-0491cb3d2227": {
    name: "Missouri",
    showShoppingCart: false,
  },
  "bbc0cfa7-e230-4338-817a-55190debdac0": {
    name: "Mary Jane",
    showShoppingCart: false,
  },
  "597c8bfe-8ea3-4967-b6d6-0b337e7d95a2": {
    name: "Reneekes Test Shop",
    showShoppingCart: true,
  },
};

export const LOGOOS: Record<SupportedTheme, any> = {
  default: require("../assets/images/logoMississippi.png"),
  easyGoing: require("../assets/images/logoEasygoing.png"),
  mississippi: require("../assets/images/logoMississippi.png"),
  formatics: require("../assets/images/logoFormatics-light.png"),
  testShop: require("../assets/images/logoTestShop.png"),
  testImage: require("../assets/images/random-cat.png"),
};

export const APPSTORE: Record<SupportedImages, any> = {
  appStoreImage: require("../assets/images/down.png"),
  playStoreImage: require("../assets/images/google_icon.png"),
  googlePlayStoreAppUrl:
    "https://play.google.com/store/apps/details?id=com.formatics.prijslijst",
  appAppStoreUrl:
    "https://apps.apple.com/us/app/prijslijst/id1554142299?platform=iphone",
};

export const CATEGORY_ICONS = [
  { iconName: "berry", icon: faHollyBerry },
  { iconName: "burgers", icon: faHamburger },
  { iconName: "candy", icon: faCandyCane },
  { iconName: "cannabis", icon: faCannabis },
  { iconName: "coffee", icon: faCoffee },
  { iconName: "chevron", icon: faChevronRight },
  { iconName: "coin", icon: faCoins },
  { iconName: "deal", icon: faExclamationTriangle },
  { iconName: "downloads", icon: faDownload },
  { iconName: "eat", icon: faUtensils },
  { iconName: "games", icon: faDice },
  { iconName: "hotdog", icon: faHotdog },
  { iconName: "love", icon: faHeart },
  { iconName: "milkshakes", icon: faCocktail },
  { iconName: "mug", icon: faMugHot },
  { iconName: "network", icon: faEthernet },
  { iconName: "smoke", icon: faSmoking },
  { iconName: "smile", icon: faSmile },
  { iconName: "soda", icon: faGlassWhiskey },
  { iconName: "spoon", icon: faUtensilSpoon },
  { iconName: "water", icon: faWater },
  { iconName: "wine", icon: faWineBottle },
];

export const FILTEROPTIONS = (shopId: string) => {
  switch (shopId) {
    case "19149768-691c-40d8-a08e-fe900fd23bc0":
      return [
        new Filter(I18n.t(`filters.reset`), "reset", "reset", faRetweet),
        new Filter(
          I18n.t(`filters.favorites`),
          "fav",
          "favorites",
          faHeartOutline
        ),
        new Filter(I18n.t(`filters.cbd`), "kind", "CBD", faHeartOutline),
        new Filter(I18n.t(`filters.indica`), "kind", "indica", faHeartOutline),
        new Filter(I18n.t(`filters.hybride`), "kind", "hybrid", faHeartOutline),
        new Filter(I18n.t(`filters.sativa`), "kind", "sativa", faHeartOutline),
        new Filter(I18n.t(`filters.new`), "new", true, faStar),
      ];
    default:
      return [
        new Filter(I18n.t(`filters.reset`), "reset", "reset", faRetweet),
        new Filter(
          I18n.t(`filters.favorites`),
          "fav",
          "favorites",
          faHeartOutline
        ),
        new Filter(I18n.t(`filters.new`), "new", true, faStar),
      ];
  }
};

// priority
// ["hasj", "rokers", "flower"]

export const PREFEREDSORT = (shopId: string) => {
  switch (shopId) {
    case "19149768-691c-40d8-a08e-fe900fd23bc0":
      return [
        new Sort(I18n.t("sorting.califlower"), "Califlowers"),
        new Sort(I18n.t("sorting.flower"), "Flowers"),
        new Sort(I18n.t("sorting.hash"), "Hasj"),
        new Sort(I18n.t("sorting.specialties"), "Specialiteiten"),
        new Sort(I18n.t("sorting.smoke"), "Rokers benodigheden"),
        new Sort(I18n.t("sorting.food"), "Eten"),
        new Sort(I18n.t("sorting.fuzzyDrinks"), "Fris drank"),
        new Sort(I18n.t("sorting.sweets"), "Snoep"),
        new Sort(I18n.t("sorting.milkshakeNsmoothy"), "Milkshakes & Smoothies"),
        new Sort(I18n.t("sorting.warmDrinks"), "Warme drank"),
      ];
    default:
      return [];
  }
};

export const navigationItems: (
  | INavigationFilter
  | INavigationItem
  | INavigationOrderList
  | INavigationTitle
  | INavigationLanguage
  | INavigationLocalRenderSwitchItem
)[] = [
  {
    id: 1,
    icon: {
      name: faQrcode,
      size: 30,
      color: "#DAD2BF",
    },
    text: {
      color: "#DAD2BF",
      fontSize: 18,
      input: "Scan QR Code",
    },
    wrapper: {
      backgroundColor: "#362B12",
    },
    renderOption: {
      renderDownloadAppScreen: false,
      renderQrScreen: true,
      renderWebQrScreen: false,
    },
  },
  {
    id: 1,
    icon: {
      name: faQrcode,
      size: 30,
      color: "#DAD2BF",
    },
    lang: {
      text: "🇳🇱",
      key: "nl",
    },
    text: {
      color: "#DAD2BF",
      fontSize: 18,
      input: "Nederlands",
    },
    wrapper: {
      backgroundColor: "#362B12",
    },
  },
  {
    id: 2,
    icon: {
      name: faQrcode,
      size: 30,
      color: "#DAD2BF",
    },
    lang: {
      text: "🇬🇧",
      key: "en",
    },
    text: {
      color: "#DAD2BF",
      fontSize: 18,
      input: "English",
    },
    wrapper: {
      backgroundColor: "#362B12",
    },
  },
  // {
  //   id: 4,
  //   icon: {
  //     name: faListAlt,
  //     size: 30,
  //     color: "#DAD2BF",
  //   },
  //   text: {
  //     color: "#DAD2BF",
  //     fontSize: 18,
  //     input: "Categories",
  //   },
  //   wrapper: {
  //     backgroundColor: "#362B12",
  //   },
  //   navigation: {
  //     route: "CategoryScreen",
  //     params: undefined,
  //   },
  // },
  // {
  //   id: 5,
  //   title: {
  //     text: "Filters",
  //     color: "#FFFFFF",
  //     backgroundColor: "#000000",
  //   },
  // },
  // ...FILTEROPTIONS.map((filter, index) => {
  //   return {
  //     id: index,
  //     icon: {
  //       name: filter.icon,
  //       size: 30,
  //       color: "#DAD2BF",
  //     },
  //     text: {
  //       color: "#DAD2BF",
  //       fontSize: 18,
  //       input: filter.title,
  //     },
  //     filterKey: filter.key,
  //     filterValue: filter.value,
  //     wrapper: {
  //       backgroundColor: "#362B12",
  //     },
  //   };
  // }),
  {
    id: 10,
    title: {
      text: "I18N_LAST_ORDERS",
      color: "#FFFFFF",
      backgroundColor: "#000000",
    },
  },
  {
    id: 11,
    orders: [],
  },
];

export const mediaQueryTypes: MediaQueryConstants = {
  desktop: {
    width: 1200,
  },
  smallDesktop: {
    width: 1024,
  },
  largeTablet: {
    width: 900,
  },
  tablet: {
    width: 768,
  },
  largePhone: {
    width: 600,
  },
  mediumPhone: {
    width: 480,
  },
  smallPhone: {
    width: 320,
  },
};
