import { CssSheet } from "../../actions/types";

export const CheckOutStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "CheckoutScreen",
  sheet: {
    container: {
      flex: 1,
      paddingLeft: 10,
      paddingRight: 2,
      paddingTop: 2,
      paddingBottom: 2,
    },
    list: {
      width: "100%",
      marginLeft: 20,
    },
    title: {
      textAlign: "center",
      fontWeight: "bold",
      fontFamily: "Roboto_400Regular",
      fontSize: 22,
      marginBottom: 10,
    },
    productTitle: {
      fontWeight: "bold",
      fontSize: 16,
      fontFamily: "Roboto_400Regular",
      width: 150,
    },
    link: {
      marginTop: 15,
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      fontFamily: "Roboto_400Regular",
      color: "#2e78b7",
    },
    separator: {
      marginVertical: 30,
      marginTop: 1,
      height: 1,
      width: "100%",
    },
    quantity: {
      marginLeft: "auto",
      marginTop: 4,
      width: 25,
      fontSize: 17,
      fontFamily: "Roboto_400Regular",
    },
    price: {
      marginLeft: 10,
      marginTop: 4,
      marginRight: 0,
      fontSize: 17,
      fontFamily: "Roboto_400Regular",
      width: 75,
    },
    rowBack: {
      alignItems: "center",
      backgroundColor: "#ff453a",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: 10,
    },
    line: {
      borderBottomColor: "#888888",
      borderBottomWidth: 1,
      marginTop: 5,
      marginBottom: 5,
    },
    totalsRow: {
      marginLeft: "auto",
      marginRight: -6,
      marginBottom: 30,
    },
    totalsText: {
      fontSize: 17,
      fontFamily: "Roboto_400Regular",
      width: 150,
    },
    gramsText: {
      justifyContent: "flex-start",
      fontSize: 20,
    },
    orderMessageWarning: {
      marginBottom: 20,
      color: "red",
      textAlign: "center",
      fontSize: 14,
      fontFamily: "Roboto_400Regular",
    },
    orderMessageInfo: {
      marginBottom: 20,
      color: "green",
      textAlign: "center",
      fontSize: 14,
      fontFamily: "Roboto_400Regular",
    },
    orderButtonContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 15,
    },
    orderGrams: {
      justifyContent: "flex-start",
    },
    orderButton: {
      paddingLeft: 20,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
    },
    previousOrderButton: {
      paddingLeft: 20,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
    },
    renderItemRow: {
      height: 100,
    },
    contentRow: {
      flex: 1,
      flexDirection: "row",
    },
    decreaseButton: {
      marginLeft: 10,
    },
    increaseButton: {
      marginLeft: 30,
      marginRight: 0,
    },
    deleteButton: {
      marginRight: 10,
    },
    waitForOrderMessage: {
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    orderMessageTitle: {
      fontSize: 22,
      fontFamily: "Roboto_400Regular",
    },
    orderMessageSubTitle: {
      marginTop: 10,
      marginBottom: 20,
      textAlign: "center",
      width: "80%",
    },
  },
};

export const CheckOutMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "CheckoutScreen",
  sheet: {
    container: {
      flex: 1,
      paddingLeft: 2,
      paddingRight: 2,
      paddingTop: 2,
      paddingBottom: 2,
    },
    list: {
      width: "100%",
      height: "75%",
    },
    title: {
      textAlign: "center",
      fontWeight: "bold",
      fontFamily: "Roboto_400Regular",
      fontSize: 22,
      marginBottom: 10,
    },
    productTitle: {
      fontWeight: "bold",
      fontSize: 16,
      fontFamily: "Roboto_400Regular",
      width: 150,
    },
    link: {
      marginTop: 15,
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      fontFamily: "Roboto_400Regular",
      color: "#2e78b7",
    },
    separator: {
      marginVertical: 30,
      marginTop: 1,
      height: 1,
      width: "100%",
    },
    overview: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-end",
      maxHeight: 140,
    },
    quantity: {
      marginLeft: "auto",
      marginTop: 4,
      width: 25,
      fontSize: 17,
      fontFamily: "Roboto_400Regular",
    },
    price: {
      marginLeft: 10,
      marginTop: 4,
      marginRight: 0,
      fontSize: 17,
      fontFamily: "Roboto_400Regular",
      width: 75,
    },
    rowBack: {
      alignItems: "center",
      backgroundColor: "#ff453a",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: 10,
    },
    line: {
      borderBottomColor: "#888888",
      borderBottomWidth: 1,
      marginTop: 5,
      marginRight: 8,
    },
    totalsRow: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 15,
    },
    totalsText: {
      fontSize: 20,
      justifyContent: "flex-end",
      fontWeight: "bold",
      fontFamily: "Roboto_400Regular",
      marginRight: 15,
    },
    gramsText: {
      marginLeft: 10,
      justifyContent: "flex-start",
      fontSize: 20,
    },
    orderMessageWarning: {
      marginBottom: 20,
      color: "red",
      textAlign: "center",
      fontSize: 14,
      fontFamily: "Roboto_400Regular",
    },
    orderMessageInfo: {
      marginBottom: 20,
      color: "green",
      textAlign: "center",
      fontSize: 14,
      fontFamily: "Roboto_400Regular",
    },
    orderButtonContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      marginBottom: 20,
      marginRight: 4,
    },
    orderButton: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
      paddingBottom: 10,
    },
    previousOrderButton: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
      paddingBottom: 10,
    },
    resetOrderButton: {
      paddingLeft: 25,
      paddingRight: 15,
      paddingTop: 10,
      paddingBottom: 10,
    },
    renderItemRow: {
      height: 100,
    },
    contentRow: {
      flex: 1,
      flexDirection: "row",
    },
    decreaseButton: {
      marginLeft: 10,
    },
    increaseButton: {
      marginLeft: 30,
      marginRight: 0,
    },
    deleteButton: {
      marginRight: 10,
    },
    waitForOrderMessage: {
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    orderMessageTitle: {
      fontSize: 22,
      fontFamily: "Roboto_400Regular",
    },
    orderMessageSubTitle: {
      marginTop: 10,
      marginBottom: 20,
      textAlign: "center",
      width: "80%",
    },
  },
};
