import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import React, { memo, useCallback, useContext, useState } from "react";
import { useEffect } from "react";
import { StyleSheet, Text } from "react-native";

import useColorScheme from "../hooks/useColorScheme";
import { Price } from "../types";
import { FilterContext, ShopContext, ThemeContext } from "../utils/contexts";
import { LanguageContext } from "../utils/Language";
import {
  getFavorites,
  getOriginAbbreviation,
  getProductInfo,
  getTheme,
  isFavorite,
  isFavoritePromise,
  setFavorites,
} from "../utils/Utils";
import ProductImage from "./ProductImage";
import { FontAwesomeIcon, TouchableOpacity, View } from "./Themed";

interface IProps {
  title?: string;
  subTitle?: Price;
  kind_i?: string;
  kind_id?: string | undefined;
  product_id?: string | undefined;
  grams?: string;
  gramPrice?: string;
  joint?: string;
  jointPrice?: string;
  navigation?: any;
  shopId?: string;
  isFavorite: boolean;
  internal_product_id: number;
  searchLength: number;
  onFavoriteClick: (favorite_id: string | undefined) => Promise<void>;
  // favorites: Price[];
  image?: {
    name: any;
    width: number;
    height: number;
    borderWidth: number;
    borderRadius: number;
  };
}

interface IPlaceholderProps {
  children:
    | boolean
    | JSX.Element
    | JSX.Element[]
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}

const FavButton = (props: {
  isFavorite: boolean | (() => boolean);
  item: any;
  onFavoriteClick: (arg0: any) => void;
}) => {
  const [favorite, setFavorite] = useState<boolean>(props.isFavorite);
  const colorTheme = useColorScheme();

  const filterContext = useContext(FilterContext);
  const { favorites } = filterContext.values;
  const shopContext = useContext(ShopContext);
  const { shopId, theme } = shopContext.values;
  const currentTheme = getTheme(theme)[colorTheme];
  const onFavoriteLocalState = () => {
    setFavorite(!favorite);
  };

  useEffect(() => {
    isFavoritePromise(props.item, shopId).then((state) => {
      setFavorite(state);
    });
  }, [favorites]);

  return (
    <TouchableOpacity
      onPress={() => {
        if (props.item.kind_id != undefined) {
          props.onFavoriteClick(props.item.kind_id);
        } else {
          props.onFavoriteClick(props.item.product_id);
        }
        onFavoriteLocalState();
      }}
      style={{
        padding: "2%",
        backgroundColor: currentTheme.card.favorite.backgroundColor,
        marginRight: -9,
      }}
    >
      <FontAwesomeIcon
        style={{
          borderColor: currentTheme.card.textColor,
        }}
        icon={favorite ? faHeart : faHeartOutline}
        color={currentTheme.card.textColor}
        size={25}
      />
    </TouchableOpacity>
  );
};

function Card(props: IProps) {
  const colorTheme = useColorScheme();
  const themeContext = useContext(ThemeContext);
  const languageContext = useContext(LanguageContext);

  const shopContext = useContext(ShopContext);
  const { shopId, theme } = shopContext.values;
  const { getStyle } = themeContext.helpers;

  const styles = getStyle("Card");
  const sheet = styles?.sheet;
  const currentTheme = getTheme(theme)[colorTheme];

  const [item, setItem] = useState({ ...props });

  const { displaySize, platform, mobileSize } = themeContext.values;

  const onCategoryClick = useCallback(() => {
    if (item.kind_id) {
      item.navigation.navigate("DetailScreen", {
        kindId: item.kind_id,
        item: item,
      });
    } else {
      item.navigation.navigate("DetailScreen", {
        productId: item.product_id,
        item: {},
      });
    }
  }, []);

  return (
    <View
      style={{
        ...sheet?.cardWrapper,
        backgroundColor: currentTheme.category.body.backgroundColor,
      }}
    >
      <TouchableOpacity
        style={{
          ...sheet?.card,
          backgroundColor: currentTheme.card.backgroundColor,
        }}
        onPress={onCategoryClick}
      >
        <View
          style={{
            ...sheet?.flexContainer,
            backgroundColor: currentTheme.card.backgroundColor,
          }}
        >
          <View
            style={{
              ...sheet?.containerWrapper,
              backgroundColor: currentTheme.card.backgroundColor,
            }}
          >
            <View
              style={{
                ...sheet?.imageWrapper,
                backgroundColor: currentTheme.card.backgroundColor,
              }}
            >
              <ProductImage
                name={item.image?.name}
                imageMap={{
                  width: item.image?.width || 100,
                  height: item.image?.height || 100,
                  borderWidth: item.image?.borderWidth || 0,
                  borderRadius: item.image?.borderRadius || 0,
                  borderColor: currentTheme.card.image.borderColor,
                }}
                resolution={{
                  width: 100,
                  height: 100,
                  fit: "contain",
                }}
              />
            </View>
          </View>
          <View
            style={{
              ...sheet?.rect,
              backgroundColor: currentTheme.card.backgroundColor,
            }}
          >
            <View
              style={{
                ...sheet?.headerContainer,
                backgroundColor: currentTheme.card.backgroundColor,
              }}
            >
              <Text
                style={{
                  ...sheet?.title,
                  color: currentTheme.card.textColor,
                }}
              >
                {item.title}
              </Text>
              {item.kind_i !== "" && (
                <Text
                  style={{
                    ...sheet?.ind,
                    color: currentTheme.card.textColor,
                    borderColor: currentTheme.card.textColor,
                  }}
                >
                  {getOriginAbbreviation(item.kind_i)}
                </Text>
              )}
            </View>
            <Text
              style={{ ...sheet?.subTitle, color: currentTheme.card.textColor }}
            >
              {getProductInfo(item.subTitle, languageContext.appLanguage)}
            </Text>

            <View
              style={{
                ...sheet?.footerContainer,
                backgroundColor: currentTheme.card.backgroundColor,
              }}
            >
              <Text
                style={{ ...sheet?.gram, color: currentTheme.card.textColor }}
              >
                {item.grams}
              </Text>
              <Text
                style={{
                  ...sheet?.gramPrice,
                  color: currentTheme.card.textColor,
                }}
              >
                € {item.gramPrice}
              </Text>
              <Text
                style={{ ...sheet?.joint, color: currentTheme.card.textColor }}
              >
                {item.joint}
              </Text>
              {item.jointPrice !== "" && (
                <Text
                  style={{
                    ...sheet?.jointPrice,
                    color: currentTheme.card.textColor,
                  }}
                >
                  € {item.jointPrice}
                </Text>
              )}
              <FavButton
                isFavorite={props.isFavorite}
                item={{ ...item }}
                key={"object_key"}
                onFavoriteClick={props.onFavoriteClick}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

export default memo(
  Card,
  (prevProps, nextProps) =>
    prevProps.isFavorite === nextProps.isFavorite &&
    prevProps.kind_id === nextProps.kind_id &&
    prevProps.product_id === nextProps.product_id &&
    prevProps.subTitle === nextProps.subTitle &&
    prevProps.searchLength === nextProps.searchLength
);
