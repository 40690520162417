import { CssSheet } from "../../actions/types";

export const ImageCarouselStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "ImageCarousel",
  sheet: {
    container: {
      flex: 1,
    },
    slideContainer: {
      // flex: 1,
      marginTop: 11,
      alignItems: "center",
    },
    clickOnImage: {
      width: 40,
      height: 40,
      // position: "absolute",
      borderRadius: 100,
      right: 30,
      top: 10,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.36,
      shadowRadius: 6.68,

      elevation: 11,
      alignItems: "center",
      justifyContent: "center",
    },
  },
};

export const ImageCarouselMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "ImageCarousel",
  sheet: {
    container: {
      flex: 1,
      width: "100%",
    },
    slideContainer: {
      marginTop: 11,
      flex: 1,
    },
    item: {
      width: 40,
      height: 40,
      position: "absolute",
      left: 10,
      top: 10,
      elevation: 11,
      alignItems: "center",
      justifyContent: "center",
    },
    productImage: {
      borderRadius: 0,
      borderWidth: 0,
    },
    clickOnImage: {
      width: 40,
      height: 40,
      position: "absolute",
      borderRadius: 100,
      right: 40,
      top: 10,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.36,
      shadowRadius: 6.68,
      elevation: 11,
      alignItems: "center",
      justifyContent: "center",
    },
  },
};

export const ImageCarouselIosMobileStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["ios"],
  },
  route: "ImageCarousel",
  sheet: {
    container: {
      flex: 1,
      width: "100%",
    },
    slideContainer: {
      marginTop: 11,
      flex: 1,
    },
    item: {
      width: 40,
      height: 40,
      position: "absolute",
      left: 10,
      top: 10,
      elevation: 11,
      alignItems: "center",
      justifyContent: "center",
    },
    productImage: {
      borderRadius: 0,
      borderWidth: 0,
    },
    clickOnImage: {
      width: 40,
      height: 40,
      position: "absolute",
      borderRadius: 100,
      right: 10,
      top: 10,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.36,
      shadowRadius: 6.68,
      elevation: 11,
      alignItems: "center",
      justifyContent: "center",
    },
  },
};
