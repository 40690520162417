import { Endpoints } from "../utils/actions/types/RouteTypes";

export const apiEndpoints = {
  v1: {
    order: {
      check: {
        get: "v1/orders/check/:id",
      },
      post: "v1/orders",
    },
    shops: {
      get: "v1/shops/:id",
      key: "shopData-:id",
    },
    kinds: {
      get: "v1/kinds/:id",
      key: "cannabis-product-:id",
    },
    products: {
      get: "v1/products/:id",
      key: "horeca-product-:id",
    },
  },
};
