import { SupportedTheme } from "../../../types";

export const GET_SHOP_CONTEXT = "GET_SHOP_CONTEXT";
export const SET_SHOP_CONTEXT = "SET_SHOP_CONTEXT";

export type ShopState = {
  theme: SupportedTheme;
  shopId: string;
  tableId: string;
  shoppingCartItems: number;
};

export type ShopAction =
  | {
      type: string;
      data: ShopState;
      // in future different state changes to shop loading
      // loading:
      // error:
    }
  | {
      type: string;
      data: ShopState;
    };
