import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Apple } from "@material-ui/icons";
import React, { useContext } from "react";
import { Image, Linking, StyleSheet, Text, ViewStyle } from "react-native";
import { TouchableHighlight } from "react-native-gesture-handler";

import useColorScheme from "../hooks/useColorScheme";
import { ShopContext, ThemeContext } from "../utils/contexts";
import { getTheme } from "../utils/Utils";
import { FontAwesomeIcon, View } from "./Themed";

export interface AppStoreButtonProps {
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  imageUrl?: any;
  title: string;
  subtitle: string;
  urlRedirect: string;
  styles?: ViewStyle;
  // style:
}

export const AppStoreButton = ({
  Icon,
  imageUrl,
  title,
  subtitle,
  urlRedirect,
  styles,
}: AppStoreButtonProps) => {
  const colorTheme = useColorScheme();

  const shopContext = useContext(ShopContext);
  const { theme } = shopContext.values;

  const themeContext = useContext(ThemeContext);
  const { getStyle } = themeContext.helpers;
  const currentTheme = getTheme(theme)[colorTheme];

  console.log("THHG => ", currentTheme);

  const style = getStyle("AppStoreButton");
  const sheet = style?.sheet;

  const onClick = () => {
    Linking.canOpenURL(urlRedirect).then(
      (supported) => {
        supported && Linking.openURL(urlRedirect);
      },
      (err) => console.log(err)
    );
  };

  return (
    <TouchableHighlight style={styles} onPress={onClick}>
      <View
        style={{
          ...sheet?.container,
          shadowColor: currentTheme.appStore?.shadowColor,
          backgroundColor: currentTheme.appStore?.button?.backgroundColor,
        }}
      >
        <View
          style={{
            ...sheet?.icon,
            backgroundColor: currentTheme.appStore?.button?.backgroundColor,
          }}
        >
          {imageUrl ? (
            <Image
              style={{
                width: 20,
                height: 20,
              }}
              source={imageUrl}
              resizeMode="contain"
            />
          ) : (
            Icon && (
              <Icon
                style={{
                  color: currentTheme.appStore?.button?.color,
                }}
              />
            )
          )}
        </View>
        <View
          style={{
            justifyContent: "center",
            backgroundColor: currentTheme.appStore?.button?.backgroundColor,
          }}
        >
          <Text
            style={{
              ...sheet?.subtitle,
              color: currentTheme.appStore?.button?.color,
            }}
          >
            {subtitle}
          </Text>
          <Text
            style={{
              ...sheet?.title,
              color: currentTheme.appStore?.button?.color,
            }}
          >
            {title}
          </Text>
        </View>
      </View>
    </TouchableHighlight>
  );
};
