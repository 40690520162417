import { SectionListSet } from "../components";
import { Price } from "../types";

export const filterOnKey = (
  key: string,
  value: string | boolean,
  set: SectionListSet[]
): Promise<Price[]> =>
  new Promise((reslove, reject) => {
    let newSet: Price[] = [];
    set.forEach((el: SectionListSet) => {
      newSet.push(
        ...el.data.filter((price: Price) => price[key as keyof Price] == value)
      );
    });
    if (newSet.length > 0) reslove(newSet);
    else reject(false);
  });

export const filterOnFavorite = (
  set: SectionListSet[],
  favorites: any[]
): Promise<Price[]> =>
  new Promise((reslove, reject) => {
    let newSet: Price[] = [];
    set.forEach((el: SectionListSet) => {
      newSet.push(
        ...el.data.filter((price: Price) =>
          favorites.find((fav: Price) => {
            if (price.kind_id) return price.kind_id === fav.kind_id;
            else return price.product_id === fav.product_id;
          })
        )
      );
    });
    if (newSet.length > 0) reslove(newSet);
    else reject(false);
  });
