import { CssSheet } from "../../actions/types";

export const FilterStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "Filter",
  sheet: {
    filter: {
      borderRadius: 20,
      position: "absolute",
      top: "12%",
      right: 5,
      height: "40%",
      width: "40%",
      zIndex: 200,
    },
    filterList: {
      position: "relative",
      borderRadius: 20,
      marginTop: "10%",
      marginBottom: "10%",
      height: "100%",
      width: "100%",
      flex: 1,
      flexDirection: "column",
    },
    filterField: {
      flex: 1,
      flexDirection: "column",
      flexWrap: "nowrap",
      justifyContent: "center",
      alignItems: "center",
    },
    selected: {
      opacity: 0.8,
    },
  },
};

export const FilterMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "Filter",
  sheet: {
    filter: {
      borderRadius: 20,
      position: "absolute",
      top: "12%",
      right: 5,
      height: "40%",
      borderColor: "#7D7C7A",
      width: "40%",
      zIndex: 200,
    },
    filterList: {
      position: "relative",
      borderRadius: 20,
      marginTop: "10%",
      marginBottom: "10%",
      height: "100%",
      width: "100%",
      flex: 1,
      flexDirection: "column",
    },
    filterField: {
      flex: 1,
      flexDirection: "column",
      flexWrap: "nowrap",
      justifyContent: "center",
      alignItems: "center",
    },
    selected: {
      opacity: 0.8,
    },
  },
};
