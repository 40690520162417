import { StackScreenProps } from "@react-navigation/stack";
import I18n from "i18n-js";
import React, { useContext, useState } from "react";
import { Dimensions, Text, View, useColorScheme } from "react-native";
import ImageZoom from "react-native-image-pan-zoom";

import ProductImage from "../components/ProductImage";
import { CategorySelectParamList } from "../types";
import { ShopContext, ThemeContext } from "../utils/contexts";
import { getTheme } from "../utils/Utils";

export default function ImageDetailScreen({
  navigation,
  route,
}: StackScreenProps<CategorySelectParamList, "NotFound">) {
  const { image } = route.params;

  const colorTheme = useColorScheme();
  const themeContext = useContext(ThemeContext);
  const shopContext = useContext(ShopContext);

  const { theme } = shopContext.values;

  const { getStyle } = themeContext.helpers;
  const styles = getStyle(route.name);
  const sheet = styles?.sheet;

  const currentTheme = getTheme(theme)[colorTheme];

  const { mobileSize, platform, displaySize } = themeContext.values;

  const [height] = useState(Math.round(displaySize.height));
  const [width] = useState(Math.round(displaySize.width));

  return (
    <View style={sheet?.container}>
      <View style={sheet?.slideContainer}>
        <View style={sheet?.scroll}>
          <ImageZoom
            cropWidth={width}
            cropHeight={height}
            imageWidth={width}
            imageHeight={height}
          >
            <ProductImage
              name={image}
              marginTop={"40%"}
              imageMap={{
                height: platform.isWeb ? width : width,
                width: platform.isWeb ? width : width + 1,
                borderRadius: 0,
                borderWidth: 0,
                borderColor: 0,
              }}
              resolution={{
                height: platform.isWeb ? height : height * 2,
                width: platform.isWeb ? width : width * 2,
                fit: "contain",
              }}
            />
          </ImageZoom>
        </View>
      </View>
      <View style={sheet?.usageContainer}>
        <Text style={sheet?.usageTip}>
          {I18n.t("imageCarouselDetailScreen.useSwipe")}
        </Text>
      </View>
    </View>
  );
}
