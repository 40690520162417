import { CssSheet } from "../../actions/types";

export const ProductsStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "ProductsScreen",
  sheet: {
    container: {
      flex: 1,
      alignItems: "center",
      height: "100%",
      alignContent: "center",
    },
    list: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      height: "100%",
    },
    hiddenItem: {
      opacity: 0,
      marginRight: 30,
    },
    row: {
      justifyContent: "flex-start",
      width: "100%",
      height: "100%",
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      marginTop: "5%",
    },
    separator: {
      marginVertical: 30,
      height: 1,
      width: "80%",
    },
    separator2: {
      marginVertical: 30,
      height: 1,
      width: "100%",
    },
    marginPrices: {
      width: 100,
      backgroundColor: "transparent",
    },
    addButton: {
      position: "absolute",
      right: 20,
      top: 5,
    },
    newItem: {
      marginLeft: "auto",
      marginRight: 30,
    },
    flexRow: {
      flex: 1,
      flexDirection: "row",
    },
    productImage: {
      marginLeft: 30,
      marginRight: 20,
    },
    productName: {
      fontWeight: "bold",
    },
    productFlavor: {
      marginBottom: "5%",
    },
    description: {
      marginBottom: "5%",
      width: 200,
    },
    search: {
      height: 30,
      width: "90%",
      marginLeft: 40,
      marginTop: 16,
      fontSize: 18,
    },
    searchIcon: {
      marginTop: 20,
      marginLeft: 20,
    },
    removeSearchIcon: {
      marginTop: 12,
      marginLeft: 2,
    },
    keyboardMargin: {
      marginBottom: 15,
      flexDirection: "row",
    },
    removeSearchIconContainer: {
      width: "10%",
      marginLeft: 10,
      marginTop: 8,
    },
    pricesContainer: {
      marginLeft: 40,
      marginTop: 20,
    },
  },
};

export const ProductsMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "ProductsScreen",
  sheet: {
    container: {
      flex: 1,
      alignItems: "center",
    },
    list: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      marginBottom: 10,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      marginTop: "5%",
    },
    separator: {
      marginVertical: 30,
      height: 1,
      width: "80%",
    },
    separator2: {
      marginVertical: 10,
      height: 1,
      width: "100%",
    },
    marginPrices: {
      width: 100,
      backgroundColor: "transparent",
    },
    addButton: {
      position: "absolute",
      right: 20,
      top: 5,
    },
    newItem: {
      marginLeft: "auto",
      marginRight: 30,
    },
    flexRow: {
      flex: 1,
      flexDirection: "row",
    },
    productImage: {
      marginLeft: 30,
      marginRight: 20,
    },
    productName: {
      fontWeight: "bold",
    },
    productFlavor: {
      marginBottom: "5%",
    },
    description: {
      marginBottom: "5%",
      width: 200,
    },
    search: {
      height: 30,
      width: "78%",
      marginLeft: 10,
      marginTop: 6,
      fontSize: 18,
    },
    searchIcon: {
      marginTop: 12,
      marginLeft: 20,
    },
    removeSearchIcon: {
      marginTop: 12,
      marginLeft: 2,
    },
    keyboardMargin: {
      marginBottom: 15,
      flexDirection: "row",
    },
    removeSearchIconContainer: {
      width: "10%",
    },
    pricesContainer: {
      marginLeft: 40,
      marginTop: 20,
    },
  },
};
