import { FontAwesomeIcon as DefaultFontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import * as React from "react";
import { useContext } from "react";
import { GestureResponderEvent } from "react-native";
import {
  Button as DefaultButton,
  Text as DefaultText,
  TextInput as DefaultTextInput,
  TouchableOpacity as DefaultTouchableOpacity,
  View as DefaultView,
} from "react-native";
import { TouchableHighlight } from "react-native-gesture-handler";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import { ShopContext } from "../utils/contexts";
import { getTheme, isFunction } from "../utils/Utils";

export function useThemeColor(
  props: {
    light?: string;
    dark?: string;
  },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  // const themeContext = useContext(ThemeContext);

  const shopContext = useContext(ShopContext);

  const { theme } = shopContext.values;

  // light or dark
  const colorTheme = useColorScheme();
  const colorFromProps = props[colorTheme];

  if (colorFromProps) {
    // for overriding colors
    // return colorFromProps;
  } else {
    // console.log("Returning theme", shopContext.theme);
    // console.log(getTheme(themeContext.appTheme)
    //   console.log(colorName, props.light, props.dark)
    const myTheme = getTheme(theme)[colorTheme][colorName];
    // console.log("myTheme: ", myTheme)
    return myTheme;
    // return Colors[theme][colorName];
  }
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
};

export type TextProps = ThemeProps & DefaultText["props"];
export type ViewProps = ThemeProps & DefaultView["props"];
export type TouchableOpacityProps = ThemeProps & DefaultView["props"];
export type TextInputProps = ThemeProps & DefaultTextInput["props"];
export type ButtonProps = ThemeProps & DefaultButton["props"];
// @ts-ignore
export type FontAwesomeIconProps = ThemeProps &
  DefaultFontAwesomeIcon["props"] & { children: React.ReactNode };

export function Text(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor(
    {
      light: lightColor,
      dark: darkColor,
    },
    "text"
  );

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    {
      light: lightColor,
      dark: darkColor,
    },
    "background"
  );

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}

export function TouchableOpacity(props: TouchableOpacityProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    "background"
  );

  return (
    <DefaultTouchableOpacity
      style={[{ backgroundColor }, style]}
      {...otherProps}
    />
  );
}

export function TextInput(props: TextInputProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor(
    {
      light: lightColor,
      dark: darkColor,
    },
    "text"
  );

  return <DefaultTextInput style={[{ color }, style]} {...otherProps} />;
}

export function FontAwesomeIcon(props: FontAwesomeIconProps) {
  const {
    style,
    lightColor,
    darkColor,
    onPress,
    backgroundColor,
    ...otherProps
  } = props;
  const color = useThemeColor(
    {
      light: lightColor,
      dark: darkColor,
    },
    "text"
  );

  if (isFunction(onPress)) {
    return (
      <TouchableHighlight onPress={onPress} underlayColor={backgroundColor}>
        <DefaultFontAwesomeIcon style={[{ color }, style]} {...otherProps} />
      </TouchableHighlight>
    );
  }

  return <DefaultFontAwesomeIcon style={[{ color }, style]} {...otherProps} />;
}

export function Button(props: ButtonProps) {
  const { lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor(
    {
      light: lightColor,
      dark: darkColor,
    },
    "orderButtonText"
  );

  return <DefaultButton color={color} {...otherProps} />;
}
