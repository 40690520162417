import { encode } from "base-64";
import React, { useContext, useState } from "react";
import { ActivityIndicator, Image, Text } from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";

import useColorScheme from "../hooks/useColorScheme";
import { ThemeContext } from "../utils/Theme";
import { getTheme } from "../utils/Utils";
import LoadingView from "./LoadingView";
import { View } from "./Themed";

interface IProps {
  name?: string;
  width: number;
  height: number;
  borderTopRightRadius?: number;
  borderTopLeftRadius?: number;
  borderBottomRightRadius?: number;
  borderBottomLeftRadius?: number;
}

export function CategoryImage({
  name = "fallback.png",
  width = 100,
  height = 100,
  borderTopRightRadius = 4,
  borderTopLeftRadius = 4,
  borderBottomRightRadius = 0,
  borderBottomLeftRadius = 0,
}: IProps) {
  if (!name) {
    name = "fallback.png";
  }
  const [isLoading, setIsLoading] = useState(true);

  const theme = useColorScheme();
  const themeContext = useContext(ThemeContext);
  const currentTheme = getTheme(themeContext.appTheme)[theme];

  const options = {
    bucket: "images-prijslijst-info",
    key: name,
    edits: {
      resize: {
        width: width - 4,
        height: height - 4,
        fit: "cover",
      },
    },
  };
  const strRequest = JSON.stringify(options);
  const encRequest = encode(strRequest);

  return (
    <>
      {isLoading && <LoadingView color={currentTheme.imageBorder} />}
      <Image
        source={{
          uri: `https://d3sticxdmgvhkp.cloudfront.net/${encRequest}`,
        }}
        style={{
          marginTop: -3,
          marginBottom: -3,
          width: width - 6,
          height: height - 6,
          borderTopRightRadius: borderTopRightRadius,
          borderTopLeftRadius: borderTopLeftRadius,
          borderBottomLeftRadius: borderBottomLeftRadius,
          borderBottomRightRadius: borderBottomRightRadius,
          borderWidth: 0,
          borderColor: currentTheme.imageBorder,
        }}
        onLoadEnd={() => setIsLoading(false)}
      />
    </>
  );
}
