import { CssSheet } from "../../actions/types";

export const ShoppingCartTotalStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "ShoppingCartNavTotal",
  sheet: {
    cartListItem: {
      padding: "2%",
    },
  },
};

export const ShoppingCartTotalMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "ShoppingCartNavTotal",
  sheet: {
    cartListItem: {
      padding: "2%",
    },
  },
};
