import { encode } from "base-64";
import React, { useContext, useState } from "react";
import { Image } from "react-native";

import useColorScheme from "../hooks/useColorScheme";
import { ThemeContext } from "../utils/Theme";
import { ImageSizeMap, ResolutionMap } from "../utils/types";
import { getTheme } from "../utils/Utils";
import LoadingView from "./LoadingView";

interface IProps {
  name?: string;
  imageMap?: ImageSizeMap;
  marginTop?: number | string;
  resolution?: ResolutionMap;
}

function ProductImage({
  name = "fallback.png",
  imageMap,
  marginTop,
  resolution,
}: IProps) {
  if (!name) {
    name = "fallback.png";
  }
  // const [isLoading, setIsLoading] = useState(true);

  const theme = useColorScheme();
  const themeContext = useContext(ThemeContext);

  const currentTheme = getTheme(themeContext.appTheme)[theme];

  const options = {
    bucket: "images-prijslijst-info",
    key: name,
    edits: {
      resize: resolution,
    },
  };

  const strRequest = JSON.stringify(options);
  const encRequest = encode(strRequest);

  return (
    <>
      {/* {isLoading && <LoadingView color={currentTheme.imageBorder} />} */}
      <Image
        source={{
          uri: `https://d3sticxdmgvhkp.cloudfront.net/${encRequest}`,
        }}
        style={{
          alignSelf: "center",
          zIndex: 0,
          marginTop: marginTop ? marginTop : 0,
          width: Math.round(imageMap?.width || 0),
          // Todo: Martin : fix product image for real -> uncommenting this leads to artefact on iOS
          // height: Math.round(imageMap?.height || 0),
          height: Math.round(imageMap?.width || 0),
          borderRadius: imageMap?.borderRadius,
          borderWidth: imageMap?.borderWidth,
          borderColor: imageMap?.borderColor,
        }}
        onLoadEnd={() => {
          // setIsLoading(false)
        }}
      />
    </>
  );
}

export default ProductImage;
