import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { StyleSheet } from "react-native";

/* begin navigation/stack/route */
export type RootStackParamList = {
  Category: undefined
  // QrCodeScannerScreen: undefined;
  // QrCodeScannerWebScreen: undefined;
  CategoryScreenMain: undefined;
  CategoryScreen: undefined;
  CheckoutScreen: undefined;
  DetailScreen: undefined;
  ImageDetailScreen: undefined;
};

export type RenderOptions = { 
  renderQrScreen: boolean;
  renderWebQrScreen: boolean;
  renderDownloadAppScreen: boolean,
}

export type BottomTabStackParamList = {
  CategoryIconSelectScreen: { categoryId: string, categoryName: string };
  Favorites: undefined;
  Settings: undefined;
  Checkout: undefined;
}

export type DetailParamList = {
  DetailScreen: { kindId: string, productId: string, item: any }
}

export type CategoryParams = {
  CategorySelectScreen: { shopID: string }
}

export type CategorySelectParamList = {
  CategoryIconSelectScreen: { shopID: string };
  CategoryPictureSelectScreen: { shopID: string };
  Products: { categoryId: string, categoryName: string, shopId: string };
  CannabisProductDetailScreen: { productId: string };
  HorecaProductDetailScreen: { productId: string };
  ImageDetailScreen: { image: string };
};

export type FavoritesParamList = {
  FavoritesScreen: undefined;
};

export type SettingsParamList = {
  Settings: undefined;
};

export type CheckoutParamList = {
  Checkout: undefined;
};

export const instanceOfNavigationTitle = (object: any): object is INavigationTitle => {
  return 'title' in object;
}

export const instanceOfNavigationLanguage = (object: any): object is INavigationLanguage => {
  return 'lang' in object;
}

export const instanceOfNavigationFilter = (object: any): object is INavigationFilter => {
  return 'filterValue' in object;
}

export const instanceOfNavigationRenderSwitch = (object: any): object is INavigationLocalRenderSwitchItem => {
  return 'renderOption' in object;
}

export const instanceOfNavigationOrder = (object: any): object is INavigationOrderList => {
  return 'orders' in object;
}

/* End of navigation/stack/route types */

export type AppLanguage = "nl" | "en";

export type ShoppingCartItem = {
  key?: string;
  description: string;
  price: number | null; // Todo: probably better to have this more strict.
  kind_id: string | null;
  kind_name: string | null;
  product_id: string | null;
  product_name: string | null;
  internal_product_id: number;
  quantity: number;
}

export type CartItem = {
  amount: number,
  item: ShoppingCartItem
}

export class FavoriteKindItem {
  /**
   *
   */
  public kind_id: string | null;

  constructor(price: Price) {
    this.kind_id = price?.kind_id;
  }
}

export class FavoriteProductItem {
  public product_id: string | null;

  /**
   *
   */
  constructor(price: Price) {
    this.product_id = price.product_id;
  }
}

export type FavoriteItem = {
  product_id?: string | null;
  product_name?: string;
  product_image: string | null;
  internal_product_id: number;
}

export type PriceQuantity = "half" | "one" | "two_five" | "five" | "joint" | "piece"

export type OrderStatus = "cancelled" | "complete" | "pending"

export type BottomTabParamList = {
  CategorySelect: undefined;
  Favorites: undefined;
  // Todo: rene -> not sure what to do here: read more docs...
  Settings: undefined;
  Checkout: undefined;
  // Products: NavigatorScreenParams<RootStackParamList>;
};



export type StrainInfo = {
  name: string;
};

/* API responses */
export interface Price {
  key?: string;
  id: string;
  // API detail store it under wrong key.
  strains?: StrainInfo[];
  internal_product_id: number;
  active: boolean;
  new: boolean;
  category_id: string;
  category_name: string;
  category_name_en: string | null;
  category_icon: string | null;
  category_order_number: number;
  category_color: string;
  main_category_id: string;
  main_category_name: string;
  main_category_name_en: string | null;
  main_category_icon: string;
  main_category_order_number: number;
  kind: string;
  kind_id: string | null;
  kind_image: string | null;
  kind_name: string;
  kind_short_description_nl: string;
  kind_short_description_en: string;
  kind_product: string;
  product_id: string | null;
  product_image: string | null;
  product_name: string;
  product_short_description_nl: string;
  product_short_description_en: string;
  // TODO: add strains
  kind_c: boolean;
  kind_h: boolean;
  kind_i: boolean;
  kind_s: boolean;
  half: number | null;
  one: number | null;
  two_five: number | null;
  five: number | null;
  joint: number | null;
  piece: number | null;
  created_at: string;
  modified_at: string;
}

export interface OrderToken {
  id: string,
  customer_order_id: string,
}

export interface OrderMessage {
  message: string,
}

export type OrderResponse = OrderMessage | OrderToken;

export interface OrderStatusItem {
  key?: string;
  id: string,
  customer_order_id: string,
  total: number,
  status: OrderStatus,
  created_at: string | null,
  completed_at: string | null
  table_id: string,
  table_name: string | null,
}

export interface ThemeMode {
  text: string,
  background: string,
  tint: string,
  tabBar: string,
  tabIconDefault: string,
  tabIconSelected: string,
  searchBarBackground: string,
  orderButtonBackground: string,
  orderButtonText: string,
  actionButton: string,
  imageBorder: string,
  topBarNavigationElements: string,
  statusBar: string,
  appStore?: {
    textColor?: string,
    shadowColor?: string,
    backgroundColor?: string,
    subtitleColor?: string,
    button?: {
      backgroundColor: string,
      color: string,
    }
  },
  qr: {
    page: {
      backgroundColor: string,
      textColor: string,
      backArrowColor: string,
    },
    scan: {
      borderColor: string,
      backgroundColor: string,
      textColor: string,
    },
    design: {
      backgroundColor: string,
      bottomBackgroundColor: string,
    }
  },
  filter: {
    list: {
      backgroundColor: string,
      borderColor: string,
    },
    options: {
      backgroundColorNotSelected: string,
      backgroundColorSelected: string,
      textColor: string,
    }
  },
  navigation: {
    topBar: {
      backgroundColor: string,
      burgerIcon: string,
      cardIcon: string,
    },
    backgroundColor: string,
    closeButtonColor: string,
    navigationItemBackgroundColor: string,
    navigationItemIconColor: string,
    navigationItemTextColor: string,
    navigationTitle: {
      color: string,
    }
    navigationOrderList: {
      backgroundColor: string,
      textColor: string
    }
  },
  search: {
    backgroundColor: string,
    textColor: string,
    searchIconColor: string,
    searchInputBackgroundColor: string,
    placeholderColor: string,
  },
  category: {
    head: {
      textColor: string,
      backgroundColor: string,
    }
    body: {
      backgroundColor: string,
    }
  },
  product: {
    image: {
      color: string
      icon: string
    }
    favorite: {
      color: string
      border: string
    }
    title: {
      color: string
    }
    subtitle: {
      color: string
    }
    price: {
      color: string
      background: string
    }
  }
  card: {
    backgroundColor: string,
    textColor: string,
    image: {
      borderColor: string,
    },
    favorite: {
      backgroundColor: string
    }
  },
  bottomBatNavigation: string,
}


export interface Theme {
  light: ThemeMode,
  dark: ThemeMode,
}

export type SupportedTheme = "default" | "mississippi" | "easyGoing" | "formatics" | "testShop" | "testImage"

export type SupportedImages = "appStoreImage" | "googlePlayStoreAppUrl" | "appAppStoreUrl" | "playStoreImage"

export interface ShopAndTableStore {
  tableId: string,
  shopId: string
}

// SUGGESTION: Move some interfaces to class based objects due to different keys for shops
// Example =>   kind_id: string | null;
//              kind_image: string | null,
//              kind_name: string,
//              kind_short_description_nl: string,
//              kind_short_description_en: string
export interface Flavor {
  id: string;
  color: string;
  icon: string;
  name: string;
}

export interface Tag {
  id: string;
  name: string;
  amount: number;
}

interface INavigationIcon {
  name: IconDefinition;
  size: number;
  color: string;
}

interface INavigationText {
  color: string;
  fontSize: number;
  input: string;
}

interface INavigationWrapper {
  backgroundColor: string;
}

interface INavigationRoute {
  route: string,
  params: any
}

interface INavBarTitle {
  text: string,
  color: string,
  backgroundColor: string
}

interface INavigationLang {
  text: string
  key: AppLanguage
}

export abstract class INavigationBaseItem {
  public id: number;


  constructor(id: number) {
    this.id = id;
  }
}

export interface INavigationOrderList extends INavigationBaseItem {
  orders?: OrderStatusItem[];
}

export interface INavigationTitle extends INavigationBaseItem {
  title?: INavBarTitle;
}

export interface INavigationLanguage extends INavigationBaseItem {
  icon: INavigationIcon;
  text: INavigationText;
  lang: INavigationLang;
  wrapper: INavigationWrapper;
  navigation?: INavigationRoute;
}

export interface INavigationItem extends INavigationBaseItem {
  icon: INavigationIcon;
  text: INavigationText;
  wrapper: INavigationWrapper;
  navigation?: INavigationRoute;
}

export interface INavigationLocalRenderSwitchItem extends INavigationBaseItem {
  icon: INavigationIcon;
  text: INavigationText;
  wrapper: INavigationWrapper;
  renderOption?: RenderOptions;
}

export interface INavigationFilter extends INavigationBaseItem {
  icon: INavigationIcon;
  text: INavigationText;
  wrapper: INavigationWrapper;
  filterKey: string;
  filterValue: string | boolean;
  navigation?: INavigationRoute;
}

export class CannabisProduct {


  public id?: string;

  public image_1?: keyof string;
  public image_2?: keyof string;
  public image_3?: keyof string;
  public image_4?: keyof string;
  public image_5?: keyof string;
  public image_6?: keyof string;
  public images_amount?: number;
  public modified_at?: string;
  public name?: string;
  public prices?: Price[];
  public short_description_en?: string;
  public short_description_nl?: string;

  // Hybrid, Indica, CBD, Sativa
  public h?: boolean;
  public i?: boolean;
  public c?: boolean;
  public s?: boolean;

  // Not sure avout this 
  public strains?: StrainInfo[];
  public strains_amount?: number;
  public tags?: Tag[];
  public tags_amount?: number;
  public flavors?: Flavor[];
  public flavors_amount?: number;
  description_nl: ReactNode;

  /**
   *
   */
  // Todo: make the constructor more intelligent? E.g. `kind_h` in api response won't map on `h`
  constructor(json: any) {
    Object.assign(this, json);
  }
}

export class HorecaProduct {
  public id?: string;
  public image_1?: keyof string;
  public image_2?: keyof string;
  public image_3?: keyof string;
  public image_4?: keyof string;
  public image_5?: keyof string;
  public image_6?: keyof string;
  public images_amount?: number;
  public modified_at?: string;
  public name?: string;
  public prices?: Price[];
  public short_description_en?: string;
  public short_description_nl?: string;

  /**
   *
   */
  constructor(json: any) {
    Object.assign(this, json);
  }
}

export class Filter {
  title: string;
  key: string;
  value: string | boolean;
  icon: IconDefinition | string;
  /**
   *
   */
  constructor(title: string, key: string, value: string | boolean, icon: IconDefinition | string) {
    this.title = title;
    this.key = key;
    this.icon = icon;
    this.value = value;
  }
}

export class Sort {
  title: string;
  value: string;
  /**
   *
   */
  constructor(title: string, value: string) {
    this.title = title;
    this.value = value;
  }
}

export interface MediaQueryConstants {
  desktop: {
    width: number;
  }
  smallDesktop: {
    width: number;
  }
  largeTablet: {
    width: number;
  }
  tablet: {
    width: number;
  } 
  largePhone: {
    width: number;
  }
  mediumPhone: {
    width: number;
  }
  smallPhone: {
    width: number;
  }
}
