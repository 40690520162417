import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useContext, useReducer } from "react";
import { Platform } from "react-native";

import { STORE_VERSION } from "../../constants/Constants";
import { set_shop_dispatch } from "../actions/dispatches";
import { ShopState } from "../actions/types/ShopTypes";
import { ShopReducer, initialShopState } from "../reducers";

export interface ShopContextInterface {
  values: ShopState;
  helpers: {
    setShopContext: (args: ShopState) => void;
    getShopContext: () => void;
  };
}

export const ShopContext = React.createContext<ShopContextInterface>({
  values: initialShopState,
  helpers: {
    getShopContext: () => {},
    setShopContext: (args: ShopState) => {},
  },
});

// Here is where we can seperate the shop fucntions from App.tsx
const ShopProvider = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [state, dispatch] = useReducer(ShopReducer, initialShopState);
  const setShopContext = async (args: ShopState): Promise<void> => {
    const jsonState = JSON.stringify(args);
    if (Platform.OS === "web") {
      window.localStorage.setItem(`${STORE_VERSION}-shopContext`, jsonState);
      dispatch(set_shop_dispatch(args));
    } else {
      try {
        await AsyncStorage.setItem(`${STORE_VERSION}-shopContext`, jsonState);
        console.log("IS THIS => ", args);
        dispatch(set_shop_dispatch(args));
      } catch (e) {
        // save error
        alert("Failed to store the data to device storage");
      }
    }
  };

  const getShopContext = async (): Promise<void> => {
    if (Platform.OS === "web") {
      const shopStateRaw = window.localStorage.getItem(
        `${STORE_VERSION}-shopContext`
      );
      if (shopStateRaw !== null) {
        const shopState = JSON.parse(shopStateRaw);
        dispatch(set_shop_dispatch(shopState));
      }
      // here is an issue the system
    } else {
      try {
        const shopStateRaw = await AsyncStorage.getItem(
          `${STORE_VERSION}-shopContext`
        );
        if (shopStateRaw !== null) {
          const shopState = JSON.parse(shopStateRaw);
          dispatch(set_shop_dispatch(shopState));
        }
      } catch (e) {
        alert(`Failed to fetch the data from device storage ${e}`);
      }
    }
  };

  return (
    <ShopContext.Provider
      value={{
        // values are all state properties
        values: {
          ...state,
        },
        // helpers are dispatch actions and context functions
        helpers: {
          getShopContext,
          setShopContext,
        },
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopProvider;
