import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ControlPointDuplicateOutlined } from "@material-ui/icons";
import {
  DrawerContentScrollView,
  createDrawerNavigator,
} from "@react-navigation/drawer";
import { DrawerActions, useIsFocused } from "@react-navigation/native";
import {
  StackScreenProps,
  createStackNavigator,
} from "@react-navigation/stack";
import I18n from "i18n-js";
import React, { useContext, useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
  useWindowDimensions,
} from "react-native";
import { ActivityIndicator } from "react-native-paper";

import { NavigationFilterItem, OrderNavList } from "../../components";
import HeaderMenuBar from "../../components/Header";
import { HeaderTitle } from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "../../components/Themed";
import { LOGOOS, navigationItems } from "../../constants/Constants";
import useColorScheme from "../../hooks/useColorScheme";
import ImageDetailScreen from "../../screens/ImageDetailScreen";
import { ProductScreen } from "../../screens/new";
import CategorySelectScreen from "../../screens/new/CategorySelectScreen";
import CheckoutScreen from "../../screens/new/CheckoutScreen";
import OrdersScreen from "../../screens/OrdersScreen";
import {
  CartItem,
  OrderStatusItem,
  RootStackParamList,
  instanceOfNavigationFilter,
  instanceOfNavigationLanguage,
  instanceOfNavigationOrder,
  instanceOfNavigationRenderSwitch,
  instanceOfNavigationTitle,
} from "../../types";
import { BaseContext, ShopContext, ThemeContext } from "../../utils/contexts";
import { LanguageContext } from "../../utils/Language";
import { getOrders, getTheme, mergeShoppingCartList } from "../../utils/Utils";

function DrawerContent({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, "NotFound">) {
  const width = useWindowDimensions().width * 0.3;
  const shopContext = useContext(ShopContext);
  const colorTheme = useColorScheme();
  const baseContext = useContext(BaseContext);
  const languageContext = useContext(LanguageContext);
  const themeContext = useContext(ThemeContext);

  const { setRenderOptions } = baseContext.helpers;

  const isFocused = useIsFocused();
  const { shopId, theme } = shopContext.values;
  const currentTheme = getTheme(theme)[colorTheme];
  const [shoppingCart, setShoppingCart] = useState<CartItem[]>([]);
  const [orderList, setOrderList] = useState<OrderStatusItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { getStyle } = themeContext.helpers;
  const styles = getStyle("Navigation");
  const sheet = styles?.sheet;

  async function loadOrders() {
    const orders = await getOrders(shopId);
    setLoading(false);
    if (orders) setOrderList(orders);
  }

  async function loadCart() {
    const cart = await mergeShoppingCartList(shopId);
    if (cart) {
      setShoppingCart(cart);
    } else {
      setShoppingCart([]);
    }
  }
  // cart items

  const changeScreen = (item: any) => {
    if (item.navigation?.params)
      navigation.navigate(
        item.navigation.route as any,
        item.navigation?.params as any
      );
    else navigation.navigate(item?.navigation?.route as any);
  };

  useEffect(() => {
    loadCart();
    loadOrders();
  }, [isFocused]);

  return (
    <DrawerContentScrollView>
      <View
        style={{
          ...sheet?.closeWrapper,
          backgroundColor: currentTheme.navigation.backgroundColor,
        }}
      >
        <TouchableHighlight
          onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
        >
          <FontAwesomeIcon
            style={sheet?.closeIcon}
            icon={faTimes}
            onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
            color={currentTheme.navigation.closeButtonColor}
            size={45}
          />
        </TouchableHighlight>
      </View>
      {navigationItems.map((item, index) => {
        if (instanceOfNavigationTitle(item)) {
          return (
            <View
              key={`${item.id}_${index}`}
              style={{
                ...sheet?.menuContainer,
              }}
            >
              <Text
                style={{
                  ...sheet?.navigationTitle,
                  color: currentTheme.navigation.navigationTitle.color,
                }}
              >
                {item.title?.text === "I18N_LAST_ORDERS"
                  ? I18n.t("drawerNavigation.lastOrders")
                  : item.title?.text}
              </Text>
            </View>
          );
        } else if (instanceOfNavigationLanguage(item)) {
          if (item.lang.key === languageContext.appLanguage) {
            return null;
          }
          return (
            <View
              style={{
                ...sheet?.menuContainer,
                marginTop: 10,
                backgroundColor:
                  currentTheme.navigation.navigationItemBackgroundColor,
              }}
              key={`${item.id}_${index}`}
              onTouchEnd={() => {
                languageContext.setAppLanguage(item.lang.key);
                navigation.dispatch(DrawerActions.closeDrawer());
              }}
            >
              <View
                style={{
                  height: width / 2,
                  backgroundColor: item.wrapper.backgroundColor
                    ? item.wrapper.backgroundColor
                    : currentTheme.navigation.navigationItemBackgroundColor,
                  ...sheet?.menuItemsCard,
                }}
              >
                <View style={[sheet?.circleContainer]}>
                  <Text>{item.lang.text}</Text>
                </View>
                <View style={[sheet?.circleContainer]}>
                  <Text
                    style={{
                      fontSize: item.text.fontSize,
                      color: item.text.color
                        ? item.text.color
                        : currentTheme.navigation.navigationItemTextColor,
                    }}
                  >
                    {item.text.input}
                  </Text>
                </View>
              </View>
            </View>
          );
        } else if (instanceOfNavigationFilter(item)) {
          return (
            <NavigationFilterItem key={`${item.id}_${index}`} item={item} />
          );
        } else if (instanceOfNavigationOrder(item)) {
          return (
            <View
              key={`${item.id}_${index}`}
              style={{
                ...sheet?.cartList,
                backgroundColor:
                  currentTheme.navigation.navigationOrderList.backgroundColor,
              }}
            >
              {loading ? (
                <ActivityIndicator />
              ) : (
                <OrderNavList items={orderList} />
              )}
            </View>
          );
        } else if (instanceOfNavigationRenderSwitch(item)) {
          return (
            <View
              style={{
                ...sheet?.menuContainer,
                marginTop: 10,
                backgroundColor:
                  currentTheme.navigation.navigationItemBackgroundColor,
              }}
              key={`${item.id}_${index}`}
              onTouchEnd={() => {
                if (item.renderOption) setRenderOptions(item.renderOption);
              }}
            >
              <View
                style={[
                  sheet?.menuItemsCard,
                  {
                    backgroundColor: item.wrapper.backgroundColor
                      ? item.wrapper.backgroundColor
                      : currentTheme.navigation.navigationItemBackgroundColor,
                    width: "100%",
                    height: width / 2,
                  },
                ]}
              >
                <View style={[sheet?.circleContainer]}>
                  <FontAwesomeIcon
                    icon={item.icon.name}
                    size={item.icon.size}
                    color={
                      item.icon.color
                        ? item.icon.color
                        : currentTheme.navigation.navigationItemIconColor
                    }
                  />
                </View>
                <View style={[sheet?.circleContainer]}>
                  <Text
                    style={{
                      fontSize: item.text.fontSize,
                      color: item.text.color
                        ? item.text.color
                        : currentTheme.navigation.navigationItemTextColor,
                    }}
                  >
                    {item.text.input}
                  </Text>
                </View>
              </View>
            </View>
          );
        } else
          return (
            <View
              style={{
                ...sheet?.menuContainer,
                marginTop: 10,
                backgroundColor:
                  currentTheme.navigation.navigationItemBackgroundColor,
              }}
              key={`${item.id}_${index}`}
              onTouchEnd={() => changeScreen(item)}
            >
              <View
                style={[
                  sheet?.menuItemsCard,
                  {
                    backgroundColor: item.wrapper.backgroundColor
                      ? item.wrapper.backgroundColor
                      : currentTheme.navigation.navigationItemBackgroundColor,
                    width: "100%",
                    height: width / 2,
                  },
                ]}
              >
                <View style={[sheet?.circleContainer]}>
                  <FontAwesomeIcon
                    icon={item.icon.name}
                    size={item.icon.size}
                    color={
                      item.icon.color
                        ? item.icon.color
                        : currentTheme.navigation.navigationItemIconColor
                    }
                  />
                </View>
                <View style={[sheet?.circleContainer]}>
                  <Text
                    style={{
                      fontSize: item.text.fontSize,
                      color: item.text.color
                        ? item.text.color
                        : currentTheme.navigation.navigationItemTextColor,
                    }}
                  >
                    {item.text.input}
                  </Text>
                </View>
              </View>
            </View>
          );
      })}
    </DrawerContentScrollView>
  );
}

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const CategoryNavigation = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, "CategoryScreenMain">) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: true,
      }}
    >
      <Stack.Screen name="CategoryScreen" component={CategorySelectScreen} />
    </Stack.Navigator>
  );
};

export const DrawerNavigation = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, "CategoryScreenMain">) => {
  const shopContext = useContext(ShopContext);
  const colorTheme = useColorScheme();

  const { theme } = shopContext.values;
  const currentTheme = getTheme(theme)[colorTheme];

  return (
    <Drawer.Navigator
      drawerStyle={{
        marginTop: "1%",
        borderTopRightRadius: 20,
        backgroundColor: currentTheme.navigation.backgroundColor,
      }}
      drawerContent={({ props }: { props: any }) => (
        <DrawerContent {...props} navigation={navigation} />
      )}
    >
      <Drawer.Screen
        name="CategoryDrawerScreen"
        component={CategoryNavigation}
      />
      {/* <Drawer.Screen name="DetailScreen" component={ProductScreen} />
        <Drawer.Screen name="ImageDetailScreen" component={ImageDetailScreen} /> */}
    </Drawer.Navigator>
  );
};

const MainStack = createStackNavigator<RootStackParamList>();

export const MainStackNavigator = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, "CategoryScreen">) => {
  const shopContext = useContext(ShopContext);
  const colorTheme = useColorScheme();

  const { theme } = shopContext.values;
  const currentTheme = getTheme(theme)[colorTheme];

  const logo = LOGOOS[theme];
  return (
    <MainStack.Navigator screenOptions={{ headerShown: true }}>
      <MainStack.Screen
        name="CategoryDrawerScreen"
        component={DrawerNavigation}
        options={{
          headerStyle: {
            backgroundColor: currentTheme.navigation.topBar.backgroundColor,
          },
          title: "",
          headerLeft: (
            props // App Logo
          ) => (
            <HeaderMenuBar
              hideOptions={{
                logo: true,
                cart: true,
                back: undefined,
              }}
              showBackButton={true}
              navigation={navigation}
            />
          ),
        }}
      />
      <MainStack.Screen
        name="DetailScreen"
        component={ProductScreen}
        options={{
          headerStyle: {
            backgroundColor: currentTheme.navigation.topBar.backgroundColor,
          },
          title: "",
          headerLeft: (
            props // App Logo
          ) => (
            <HeaderMenuBar
              hideOptions={{
                logo: true,
                cart: true,
                back: undefined,
              }}
              showBackButton={false}
              navigation={navigation}
            />
          ),
        }}
      />
      <MainStack.Screen
        name="ImageDetailScreen"
        component={ImageDetailScreen}
        options={{
          headerStyle: {
            backgroundColor: currentTheme.navigation.topBar.backgroundColor,
          },
          title: "",
          headerLeft: (
            props // App Logo
          ) => (
            <HeaderMenuBar
              hideOptions={{
                logo: true,
                cart: true,
                back: undefined,
              }}
              showBackButton={false}
              navigation={navigation}
            />
          ),
        }}
      />
      <MainStack.Screen
        name="CheckoutScreen"
        component={CheckoutScreen}
        options={{
          headerTitleStyle: {
            color: currentTheme.navigation.topBar.cardIcon,
          },
          headerStyle: {
            backgroundColor: currentTheme.navigation.topBar.backgroundColor,
          },
          title: I18n.t("checkoutPage.title"),
          headerLeft: (
            props // App Logo
          ) => (
            <HeaderMenuBar
              hideOptions={{
                logo: false,
                cart: false,
                back: faTimes,
              }}
              showBackButton={false}
              navigation={navigation}
            />
          ),
        }}
      />
      <MainStack.Screen
        name="OrdersScreen"
        component={OrdersScreen}
        options={{
          headerStyle: {
            backgroundColor: currentTheme.navigation.topBar.backgroundColor,
          },
          title: "Order",
          headerLeft: (
            props // App Logo
          ) => (
            <HeaderMenuBar
              hideOptions={{
                logo: true,
                cart: true,
                back: undefined,
              }}
              showBackButton={false}
              navigation={navigation}
            />
          ),
        }}
      />
    </MainStack.Navigator>
  );
};
