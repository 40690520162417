import { CssSheet } from "../../actions/types";

export const NavigationStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "Navigation",
  sheet: {
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    menuContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 40,
      paddingTop: 20,
    },
    menuItemsCard: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      borderRadius: 10,
      width: "100%",
    },
    circleContainer: {
      paddingLeft: "10%",
      justifyContent: "flex-start",
      padding: 10,
    },
    closeIcon: {
      alignContent: "flex-end",
      alignItems: "flex-end",
      marginRight: 20,
      marginBottom: 30,
    },
    closeWrapper: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: "10%",
    },
    logo: {
      width: 270,
      height: 40,
      marginBottom: 5,
    },
    headerContainer: {
      flex: 1,
      flexDirection: "row",
    },
    burgerMenu: {
      justifyContent: "center",
      alignItems: "flex-start",
      marginLeft: 15,
    },
    shoppingCart: {
      justifyContent: "center",
      alignItems: "flex-start",
      marginLeft: 15,
      fontFamily: "space-mono",
    },
    navigationTitle: {
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      fontSize: 26,
    },
    logoContainer: {
      justifyContent: "center",
      alignItems: "center",
      width: "85%",
    },
    cartList: {
      borderWidth: 2,
      justifyContent: "center",
      alignItems: "center",
    },
    item: {
      padding: 10,
      fontSize: 26,
      height: "20%",
    },
  },
};

export const NavigationMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "Navigation",
  sheet: {
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    menuContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
    menuItemsCard: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      borderRadius: 10,
    },
    circleContainer: {
      paddingLeft: "10%",
      justifyContent: "flex-start",
      padding: 10,
    },
    closeIcon: {
      alignContent: "flex-end",
      alignItems: "flex-end",
      marginRight: 20,
      marginBottom: 30,
    },
    closeWrapper: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: -10,
    },
    logo: {
      width: 270,
      height: 40,
      marginBottom: 5,
    },
    headerContainer: {
      flex: 1,
      flexDirection: "row",
    },
    burgerMenu: {
      justifyContent: "center",
      alignItems: "flex-start",
      marginLeft: "5%",
    },
    shoppingCart: {
      justifyContent: "center",
      alignItems: "flex-start",
      marginLeft: "5%",
      fontFamily: "space-mono",
    },
    navigationTitle: {
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      fontSize: 20,
    },
    logoContainer: {
      justifyContent: "center",
      alignItems: "center",
      width: "85%",
    },
    cartList: {
      borderWidth: 2,
      justifyContent: "center",
      alignItems: "center",
    },
    item: {
      padding: 10,
      fontSize: 18,
      height: 44,
    },
  },
};
