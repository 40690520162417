// reducer will manage the state based on actions
import {
  FilterAction,
  FilterCatAction,
  FilterFavAction,
  FilterOptAction,
  FilterState,
  SET_CATEGORIES,
  SET_FAVORITES,
  SET_FILTERS,
  SET_FILTER_OPTION,
  SET_RESULTS,
} from "../actions/types";

export const initialFilterState: FilterState = {
  results: [],
  filters: [],
  activeFilter: {
    id: "1",
    name: "",
    index: 1,
    from: 1,
    to: 1,
  },
  categories: [],
  favorites: [],
  option: null,
};

const FilterReducer = (
  state: FilterState = initialFilterState,
  action:
    | FilterAction
    | FilterCatAction
    | FilterFavAction
    | FilterOptAction
    | any
): FilterState => {
  switch (action.type) {
    case SET_RESULTS: {
      return {
        results: action.data,
        filters: state.filters,
        categories: state.categories,
        favorites: state.favorites,
        activeFilter: state.activeFilter,
        option: action.data <= 0 ? null : state.option,
      };
    }
    case SET_FILTERS:
      return {
        results: state.results,
        filters: action.data,
        categories: state.categories,
        favorites: state.favorites,
        activeFilter: action.filter,
        option: action.data <= 0 ? null : state.option,
      };
    case SET_CATEGORIES:
      return {
        results: state.results,
        filters: state.filters,
        categories: action.categories,
        favorites: state.favorites,
        activeFilter: state.activeFilter,
        option: state.option,
      };
    case SET_FAVORITES:
      return {
        results: state.results,
        filters: state.filters,
        categories: state.categories,
        favorites: action.favorites,
        activeFilter: state.activeFilter,
        option: state.option,
      };
    case SET_FILTER_OPTION:
      return {
        results: state.results,
        filters: state.filters,
        categories: state.categories,
        favorites: state.favorites,
        activeFilter: state.activeFilter,
        option: action.option,
      };
    default:
      return {
        ...state,
      };
  }
};

export default FilterReducer;
