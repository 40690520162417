import { SET_DIMENTION, SET_ORIENTATION, SET_THEME } from "../types";
import {
  CssSheet,
  SET_SHEET,
  ThemeColor,
  ThemeState,
} from "../types/ThemeTypes";

const set_theme_dispatch = (themeState: ThemeState) => ({
  type: SET_THEME,
  data: themeState,
});

const set_color_theme_dispatch = (themeColor: ThemeColor) => ({
  type: SET_THEME,
  data: themeColor,
});

const set_dimention_dispatch = (width: number, height: number) => ({
  type: SET_DIMENTION,
  data: { width: width, height: height },
});

const set_orientation_dispatch = (isPortrait: boolean) => ({
  type: SET_ORIENTATION,
  data: isPortrait,
});

const set_style_sheets = (sheets: CssSheet[]) => ({
  type: SET_SHEET,
  data: sheets,
});

export {
  set_theme_dispatch,
  set_color_theme_dispatch,
  set_dimention_dispatch,
  set_style_sheets,
  set_orientation_dispatch,
};
