import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation, useRoute } from "@react-navigation/native";
import React, { FC, useContext, useEffect, useReducer } from "react";
import { Platform } from "react-native";

import { STORE_VERSION } from "../../constants/Constants";
import { set_stack } from "../actions/dispatches";
import { Route, RouteState } from "../actions/types/RouteTypes";
import { RouteReducer, initialRouteState } from "../reducers";
import { ThemeContext } from ".";

export interface RouteContextInterface {
  values: RouteState;
  helpers: {
    saveStack: (state: any) => void;
    getStack: () => Promise<any>;
    setCurrentRoute: (route: string) => void;
    getCurrentRoute: (route: string) => Promise<string>;
  };
}

export const RouteContext = React.createContext<Partial<RouteContextInterface>>(
  {
    values: initialRouteState,
    helpers: {
      saveStack: (state: any): void => {
        if (Platform.OS === "web") {
          window.localStorage.setItem(
            `${STORE_VERSION}-router-stack`,
            JSON.stringify(state)
          );
        }
      },
      getStack: async () => {
        if (Platform.OS == "web") {
          const jsonState = window.localStorage.getItem(
            `${STORE_VERSION}-router-stack`
          );
          if (jsonState !== null) {
            const stackState = JSON.parse(jsonState);
            return Promise.resolve(stackState);
          }
        } else {
          const rawStack = await AsyncStorage.getItem(
            `${STORE_VERSION}-router-stack`
          );
          if (rawStack != null) {
            const stackState = JSON.parse(rawStack);
            return Promise.resolve(stackState);
          }
          return Promise.resolve(rawStack);
        }
      },
      setCurrentRoute: (route: string) => {
        if (Platform.OS === "web") {
          window.localStorage.setItem(
            `${STORE_VERSION}-current-route`,
            JSON.stringify(route)
          );
        } else {
          AsyncStorage.setItem(
            `${STORE_VERSION}-current-route`,
            JSON.stringify(route)
          );
        }
      },
      getCurrentRoute: async (route: string) => {
        if (Platform.OS == "web") {
          const jsonState = window.localStorage.getItem(
            `${STORE_VERSION}-current-route`
          );
          if (jsonState !== null) {
            const stackState = JSON.parse(jsonState);
            return Promise.resolve(stackState);
          }
        } else {
          const rawStack = await AsyncStorage.getItem(
            `${STORE_VERSION}-current-route`
          );
          if (rawStack != null) {
            const stackState = JSON.parse(rawStack);
            return Promise.resolve(stackState);
          }
          return Promise.resolve(rawStack);
        }
      },
    },
  }
);

const RouteProvider = (props: {
  children:
    | boolean
    | React.ReactNode
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [state, dispatch] = useReducer(RouteReducer, initialRouteState);

  const navigation = useNavigation();

  const themeContext = useContext(ThemeContext);

  const { setStyle } = themeContext.helpers;
  const getStack = async () => {
    if (Platform.OS == "web") {
      const jsonState = window.localStorage.getItem(
        `${STORE_VERSION}-router-stack`
      );
      if (jsonState !== null) {
        const stackState = JSON.parse(jsonState);
        dispatch(set_stack(stackState));
      }
    } else {
      const rawStack = await AsyncStorage.getItem(
        `${STORE_VERSION}-router-stack`
      );
      if (rawStack != null) {
        const stackState = JSON.parse(rawStack);
        dispatch(set_stack(stackState));
        return Promise.resolve(stackState);
      }
      return Promise.resolve(rawStack);
    }
  };

  useEffect(() => {
    setStyle("", {});
  }, []);

  // useEffect(() => {
  //   // Register a listener for state changes in the navigation
  //   const unsubscribe = navigation.addListener("state", (e) => {
  //     // check if the stack is present in the navigator to update local storage
  //     if (e.data?.state?.routes[1]?.state?.routes[0]?.state?.routes) {
  //       saveStack(e.data.state?.routes[1].state?.routes[0].state?.routes);
  //     }
  //   });

  //   return unsubscribe;
  // }, []);

  const saveStack = (state: any): void => {
    if (Platform.OS === "web") {
      window.localStorage.setItem(
        `${STORE_VERSION}-router-stack`,
        JSON.stringify(state)
      );
    } else {
      AsyncStorage.setItem(
        `${STORE_VERSION}-router-stack`,
        JSON.stringify(state)
      );
    }
  };

  const setCurrentRoute = (route: string) => {
    if (Platform.OS === "web") {
      window.localStorage.setItem(
        `${STORE_VERSION}-current-route`,
        JSON.stringify(route)
      );
    } else {
      AsyncStorage.setItem(
        `${STORE_VERSION}-current-route`,
        JSON.stringify(route)
      );
    }
  };

  const getCurrentRoute = async (route: string) => {
    if (Platform.OS == "web") {
      const jsonState = window.localStorage.getItem(
        `${STORE_VERSION}-router-stack`
      );
      if (jsonState !== null) {
        const stackState = JSON.parse(jsonState);
        return Promise.resolve(stackState);
      }
    } else {
      const rawStack = await AsyncStorage.getItem(
        `${STORE_VERSION}-router-stack`
      );
      if (rawStack != null) {
        const stackState = JSON.parse(rawStack);
        return Promise.resolve(stackState);
      }
      return Promise.resolve(rawStack);
    }
  };

  return (
    <RouteContext.Provider
      value={{
        // values are all state properties
        values: {
          ...state,
        },
        // helpers are dispatch actions and context functions
        helpers: {
          saveStack,
          getStack,
          setCurrentRoute,
          getCurrentRoute,
        },
      }}
    >
      {props.children}
    </RouteContext.Provider>
  );
};

export default RouteProvider;
