import React from "react";

import { AppLanguage } from "../types";

export interface LanguageContextInterface {
  appLanguage: AppLanguage;
  setAppLanguage: (appLanguage: AppLanguage) => void;
}

// create the language context with default selected language
export const LanguageContext = React.createContext<LanguageContextInterface>({
  appLanguage: "nl",
  setAppLanguage: (appLanguage: AppLanguage) => {},
});
