import { SectionListSet } from "../../../components";
import { Filter, Price } from "../../../types";
import {
  SET_CATEGORIES,
  SET_FAVORITES,
  SET_FILTERS,
  SET_FILTER_OPTION,
  SET_RESULTS,
} from "../types";

const set_results_dispatch = (price: Price[]) => ({
  type: SET_RESULTS,
  data: price,
});

const set_filter_dispatch = (price: Price[], activeFilter: Filter) => ({
  type: SET_FILTERS,
  filter: activeFilter,
  data: price,
});

const set_categories_dispatch = (data: SectionListSet[]) => ({
  type: SET_CATEGORIES,
  categories: data,
});

const set_favorites_dispatch = (favorites: any[]) => ({
  type: SET_FAVORITES,
  favorites: favorites,
});

const set_filter_option_dispatch = (filterOption: Filter) => ({
  type: SET_FILTER_OPTION,
  option: filterOption,
});

export {
  set_filter_dispatch,
  set_categories_dispatch,
  set_favorites_dispatch,
  set_filter_option_dispatch,
  set_results_dispatch,
};
