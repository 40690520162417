import { ControlPointSharp } from "@material-ui/icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Dimensions, Platform } from "react-native";
import { Console } from "react-query/types/core/utils";

import { MainCategory, SubCategory } from "../components";
import {
  defaultTheme,
  easyGoingTheme,
  formaticsTheme,
  mississippiTheme,
  testShopTheme,
} from "../constants/Colors";
import {
  getFavoritesStore,
  getOrdersStore,
  getShopAndTableStore,
  getShoppingCartStore,
  mediaQueryTypes,
} from "../constants/Constants";
import {
  AppLanguage,
  CannabisProduct,
  CartItem,
  FavoriteKindItem,
  FavoriteProductItem,
  OrderStatusItem,
  Price,
  PriceQuantity,
  ShopAndTableStore,
  ShoppingCartItem,
  SupportedTheme,
} from "../types";
import { PlatformState, mediaTypes } from "./actions/types";

export function uuid(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (char) => {
    let random = (Math.random() * 16) | 0;
    let value = char === "x" ? random : (random % 4) + 8;
    return value.toString(16); // Hex
  });
}

export function isFunction(functionToCheck: any) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

export function imageUrlForShop(shopId: string) {
  return `https://api.prijslijst.info/qr/shop/${shopId}`;
}

export function imageUrlForCategory(shopId: string, categoryId: string) {
  return `https://api.prijslijst.info/qr/shop/${shopId}/category/${categoryId}`;
}

export function imageUrlForProduct(
  shopId: string,
  categoryId: string,
  productId: string
) {
  return `https://api.prijslijst.info/qr/shop/${shopId}/category/${categoryId}/product/${productId}`;
}

export function endsWithAny(suffixes: string[], string: string) {
  return suffixes.some(function (suffix) {
    return string.endsWith(suffix);
  });
}

export function searchInArray(
  targetArray: any[],
  lookupArray: any[],
  caseSensitiveSearch: boolean
) {
  return targetArray.filter(function (x) {
    return lookupArray.every(function (lookup) {
      if (x[lookup.key] === undefined)
        throw new Error("No " + lookup.key + " property in object " + x);

      if (typeof x[lookup.key] !== typeof lookup.value)
        throw new Error(
          "Type mismatch on property " + lookup.key + " + in object " + x
        );

      if (typeof lookup.value === "string" && caseSensitiveSearch)
        return x[lookup.key].toLowerCase() === lookup.value.toLowerCase();
      else return x[lookup.key] === lookup.value;
    });
  });
}

export const getShoppingCart = async (shopId: string) => {
  const store = getShoppingCartStore(shopId);
  if (Platform.OS === "web") {
    const cart = window.localStorage.getItem(store);
    // Parse stored json or if none return initialValue
    return cart ? JSON.parse(cart) : [];
  } else {
    try {
      const cart = await AsyncStorage.getItem(store);

      if (cart !== null) {
        const cart_parsed = JSON.parse(cart);
        // console.log("Loaded cart", cart_parsed);
        console.log("Loaded cart");
        return cart_parsed;
      }
    } catch (e) {
      alert("Failed to fetch the data from device storage");
    }
  }
};

export const mergeShoppingCartList = async (
  shopId: string
): Promise<CartItem[]> =>
  new Promise(async (reslove, reject) => {
    const items = await getShoppingCart(shopId);
    // sorting the array before hand so i dont loop extra
    if (!items) return [];
    if (items.length <= 0) return [];
    const ordered = items.sort((a: ShoppingCartItem, b: ShoppingCartItem) => {
      if (a.kind_id && b.kind_id) {
        if (a.kind_id < b.kind_id) {
          return -1;
        }
        if (a.kind_id > b.kind_id) {
          return 1;
        }
      }
      if (a.product_id && b.product_id) {
        if (a.product_id < b.product_id) {
          return -1;
        }
        if (a.product_id > b.product_id) {
          return 1;
        }
      }
      return 0;
    });
    // setting up variables and entering first element
    const array: CartItem[] = [];
    let id: string | undefined = ordered[0].kind_id
      ? ordered[0].kind_id
      : ordered[0].product_id;
    array.push({ amount: 1, item: ordered[0] });

    // getting groups with amount
    for (let index = 0; index < ordered.length; index++) {
      const element: ShoppingCartItem = ordered[index];
      const tempId = element.kind_id ? element.kind_id : element.product_id;
      console.log(tempId != id);
      if (tempId != id) {
        id = tempId ? tempId : "";
        array.push({ amount: 1, item: ordered[index] });
      } else {
        array[array.length - 1].amount += 1;
      }
    }
    reslove(array);
  });

export const setShoppingCartStore = async (
  cart: ShoppingCartItem[],
  shopId: string
) => {
  const store = getShoppingCartStore(shopId);
  console.log(store);
  if (Platform.OS === "web") {
    window.localStorage.setItem(store, JSON.stringify(cart));
  } else {
    try {
      const cart_json = JSON.stringify(cart);
      await AsyncStorage.setItem(store, cart_json);
      console.log("Writing cart", cart_json);
    } catch (e) {
      // save error
    }
  }
};

export function getOriginAbbreviation(origin: string) {
  const origins = { indica: "IND", sativa: "SAT", hybrid: "HYB", CBD: "CBD" };
  if (origins.hasOwnProperty(origin)) {
    return origins[origin];
  }
}

export const resetShoppingCart = async (shopId: string) => {
  const store = getShoppingCartStore(shopId);
  if (Platform.OS === "web") {
    window.localStorage.removeItem(store);
  } else {
    try {
      await AsyncStorage.removeItem(store);
      console.log("Resetted cart");
    } catch (e) {
      // save error
    }
  }
};

export const isFavorite = (
  price: Price | any,
  favorites: (FavoriteKindItem | FavoriteProductItem)[]
): boolean => {
  for (const fav of favorites) {
    if (fav instanceof FavoriteKindItem) {
      if (fav.kind_id === price.kind_id) {
        return true;
      }
    }
    if (fav instanceof FavoriteProductItem) {
      if (fav.product_id === price.product_id) {
        return true;
      }
    }
  }
  return false;
};

export const isFavoritePromise = (price: Price | any, shopId: string) => {
  return getFavorites(shopId).then((favorites) => {
    for (const fav of favorites) {
      if (fav instanceof FavoriteKindItem) {
        if (fav.kind_id === price.kind_id) {
          return true;
        }
      }
      if (fav instanceof FavoriteProductItem) {
        if (fav.product_id === price.product_id) {
          return true;
        }
      }
    }
    return false;
  });
};

export const createFavorite = (
  price: any
): FavoriteKindItem | FavoriteProductItem => {
  console.log("TODO Martin FIX favorite type", price);
  if (price.kind_id) {
    // This one is never called
    console.log("Kind favorite saved");
    return new FavoriteKindItem(price);
  } else {
    console.log("Product favorite saved");
    return new FavoriteProductItem(price);
  }
};

export const getFavoriteId = (y: FavoriteKindItem | FavoriteProductItem) => {
  if (y instanceof FavoriteKindItem) {
    if (y.kind_id) {
      return y.kind_id;
    }
  }
  if (y instanceof FavoriteProductItem) {
    if (y.product_id) {
      return y.product_id;
    }
  }
};

export const addFavorite = async (
  favorites: (FavoriteKindItem | FavoriteProductItem)[],
  fav: FavoriteKindItem | FavoriteProductItem,
  shopId: string
) => {
  favorites.push(fav);
  await setFavorites(shopId, favorites);
  return favorites;
};

export const removeFavorite = async (
  favorites: (FavoriteKindItem | FavoriteProductItem)[],
  index: number,
  shopId: string
) => {
  await setFavorites(shopId, favorites.slice(0, index));
  return favorites;
};

export const handleFavorite = (
  fav: FavoriteKindItem | FavoriteProductItem,
  shopId: string
) => {
  return getFavorites(shopId)
    .then((favorites: (FavoriteKindItem | FavoriteProductItem)[]) => {
      if (favorites.length == 0) {
        return addFavorite(favorites, fav, shopId);
      }
      const foundIndex = favorites.findIndex(
        (f: FavoriteKindItem | FavoriteProductItem) => {
          if (
            fav instanceof FavoriteKindItem &&
            f instanceof FavoriteKindItem
          ) {
            if (f.kind_id == fav.kind_id) {
              return f;
            }
          }
          if (
            fav instanceof FavoriteProductItem &&
            f instanceof FavoriteProductItem
          ) {
            if (f.product_id == fav.product_id) {
              return f;
            }
          }
        }
      );

      if (foundIndex != -1) {
        return removeFavorite(favorites, foundIndex, shopId);
      } else {
        return addFavorite(favorites, fav, shopId);
      }
    })
    .catch(() => {
      console.error("Favorites where not fetched");
      return [];
    });
};

export const handleAddProduct = async (
  item: ShoppingCartItem,
  shopId: string
) => {
  let newShoppingCart = await getShoppingCart(shopId);
  if (!newShoppingCart) {
    newShoppingCart = [];
  }
  let index = -1;
  if (item.hasOwnProperty("kind_id") && item.kind_id) {
    index = newShoppingCart.findIndex(
      (x: any) =>
        x.description === item.description &&
        x.internal_product_id === item.internal_product_id &&
        x.kind_id === item.kind_id &&
        x.price === item.price
    );
  } else {
    index = newShoppingCart.findIndex(
      (x: any) =>
        x.description === item.description &&
        x.internal_product_id === item.internal_product_id &&
        x.product_id === item.product_id &&
        x.price === item.price
    );
  }
  if (index === -1) {
    item.quantity = 1;
    item.key = String(newShoppingCart.length + 1);
    newShoppingCart.push(item);
  } else {
    newShoppingCart[index].quantity += 1;
  }
  await setShoppingCartStore(newShoppingCart, shopId);
};

export const handleInternalClickAddCart = (
  item: Price,
  label: string,
  priceOption: PriceQuantity,
  shopId: string
) => {
  // console.log("Handle internal cart click=>", item)

  // Test for cannabis product
  // Todo Martin: I understand that you want to get rid of the 2 product types. But it broke the checkout
  // Todo Martin: fix types : Price type (as it is now defined) is very confusing
  if (item.hasOwnProperty("h")) {
    console.log("Adding Kind to cart");
    handleAddProduct(
      {
        description: label,
        price: item.prices[0][priceOption],
        kind_id: item.id,
        kind_name: item.name,
        internal_product_id: item.internal_product_id,
        quantity: 1,
      },
      shopId
    );
  } else {
    console.log("Adding Product to cart");
    handleAddProduct(
      {
        description: label,
        price: item.prices[0][priceOption],
        product_id: item.id,
        product_name: item.name,
        internal_product_id: item.internal_product_id,
        quantity: 1,
      },
      shopId
    );
  }
};

export const getOrders = async (shopId: string) => {
  const store = getOrdersStore(shopId);
  console.log("STORE => ", store);
  if (Platform.OS === "web") {
    const orders = window.localStorage.getItem(store);
    // Parse stored json or if none return initialValue
    return orders ? JSON.parse(orders) : [];
  } else {
    try {
      const orders = await AsyncStorage.getItem(store);
      console.log("RAW ORDERS => ", orders);
      if (orders !== null) {
        const orders_parsed = JSON.parse(orders);
        console.log("Loaded orders");
        // console.log(orders);
        return orders_parsed;
      }
    } catch (e) {
      alert("Failed to fetch the data from device storage");
    }
  }
};

export const setOrders = async (shopId: string, orders: OrderStatusItem[]) => {
  const store = getOrdersStore(shopId);
  if (Platform.OS === "web") {
    window.localStorage.setItem(store, JSON.stringify(orders));
  } else {
    try {
      const orders_json = JSON.stringify(orders);
      await AsyncStorage.setItem(store, orders_json);
      console.log("Writing orders", orders_json);
    } catch (e) {
      // save error
    }
  }
};

const parseJsonToFavs = (
  json: string | null
): (FavoriteKindItem | FavoriteProductItem)[] => {
  if (json !== null && json !== undefined) {
    const favorites_parsed = JSON.parse(json || "");
    const favs: (FavoriteKindItem | FavoriteProductItem)[] = [];
    for (const f of favorites_parsed) {
      if (f.kind_id) {
        favs.push(new FavoriteKindItem(f));
      } else {
        favs.push(new FavoriteProductItem(f));
      }
    }
    return favs;
  } else {
    // Parse stored json or if none return initialValue
    return [] as (FavoriteKindItem | FavoriteProductItem)[];
  }
};

export const getFavorites = async (
  shopId: string
): Promise<(FavoriteKindItem | FavoriteProductItem)[]> =>
  new Promise((resolve, reject) => {
    const store = getFavoritesStore(shopId);
    if (Platform.OS === "web") {
      try {
        const favorites = window.localStorage.getItem(store);
        resolve(parseJsonToFavs(favorites));
      } catch (e) {
        alert("Failed to fetch the data from device storage");
        reject(false);
      }
    } else {
      try {
        AsyncStorage.getItem(store).then((data) => {
          const favorites = data;
          resolve(parseJsonToFavs(favorites));
        });
      } catch (e) {
        alert("Failed to fetch the data from device storage");
        reject(false);
      }
    }
  });

export const setFavorites = async (
  shopId: string,
  favorites: (FavoriteKindItem | FavoriteProductItem)[]
) => {
  const store = getFavoritesStore(shopId);
  if (Platform.OS === "web") {
    window.localStorage.setItem(store, JSON.stringify(favorites));
  } else {
    try {
      const favorite_json = JSON.stringify(favorites);
      await AsyncStorage.setItem(store, favorite_json);
    } catch (e) {
      // save error
      console.log("Error in favorite store for shopId", shopId);
    }
  }
};

export const resetFavorites = async (shopId: string) => {
  const store = getFavoritesStore(shopId);
  if (Platform.OS === "web") {
    window.localStorage.removeItem(store);
  } else {
    try {
      await AsyncStorage.removeItem(store);
    } catch (e) {
      // save error
    }
  }
};

export function formatMoney(
  amount: string | number,
  decimalCount = 2,
  decimal = ",",
  thousands = "."
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          // @ts-ignore
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export const getShopAndTable = async () => {
  const store = getShopAndTableStore();
  if (Platform.OS === "web") {
    const shopAndTable = window.localStorage.getItem(store);
    const shopAndTableParsed = JSON.parse(shopAndTable ? shopAndTable : "");
    console.log(
      `Loaded shopId: ${shopAndTableParsed.shopId} and table ${shopAndTableParsed.tableId}`
    );
    return shopAndTableParsed;
  } else {
    try {
      const shopAndTable = await AsyncStorage.getItem(store);
      if (shopAndTable !== null) {
        const shopAndTableParsed = JSON.parse(shopAndTable);
        console.log(shopAndTableParsed);
        console.log(
          `Loaded shopId: ${shopAndTableParsed.shopId} and table ${shopAndTableParsed.tableId}`
        );
        return shopAndTableParsed;
      }
    } catch (e) {
      alert("Failed to fetch the data from device storage");
    }
  }
};

export async function loadShopAndTable() {
  const shopAndTable = await getShopAndTable().then();
  if (shopAndTable) {
    console.log("SHOP_ID from bottomnavigator: ", shopAndTable.shopId);
    return shopAndTable.shopId;
  }
}

export const setShopAndTable = async (shopAndTable: ShopAndTableStore) => {
  const store = getShopAndTableStore();

  if (Platform.OS === "web") {
    window.localStorage.setItem(store, JSON.stringify(shopAndTable));
  } else {
    try {
      const shopAndTableJson = JSON.stringify(shopAndTable);
      await AsyncStorage.setItem(store, shopAndTableJson);
      console.log("Writing shopAndTable", shopAndTableJson);
    } catch (e) {
      // save error
    }
  }
};

export function getNumberOfCartItems(cart: ShoppingCartItem[]) {
  let numberOfCartItems = 0;
  for (const item of cart) {
    numberOfCartItems += item.quantity;
  }
  return numberOfCartItems;
}

export function getTheme(name: SupportedTheme) {
  switch (name) {
    case "mississippi":
      return mississippiTheme;
    case "easyGoing":
      return easyGoingTheme;
    case "formatics":
      return formaticsTheme;
    case "testShop":
      return testShopTheme;
    default:
      return defaultTheme;
  }
}

export function getProductInfo(
  item: Price | SubCategory | undefined,
  language: AppLanguage
) {
  // Handle horeca product info first
  // console.log("item => ", item);
  if (!item) {
    return "no content";
  }
  if (item.product_name) {
    if (
      language === "en" &&
      item.product_short_description_en &&
      item.product_short_description_en !== ""
    ) {
      return item.product_short_description_en;
    }
    // when no EN translation is available: return NL content
    return item.product_short_description_nl;
  }

  // If strain is found: use it
  if (item.strains && item.strains.length) {
    return item.strains.map(
      (strain, index) =>
        `${strain.name}${index + 1 !== item.strains?.length ? " - " : ""}`
    );
  }

  // else: check if language is EN and we have an EN variant in the content
  if (
    language === "en" &&
    item.kind_short_description_en &&
    item.kind_short_description_en !== ""
  ) {
    return item.kind_short_description_en;
  }
  // when no EN translation and strain info is available: return NL content
  return item.kind_short_description_nl;
}

export const formatImage = (product: any): string[] => {
  const images: string[] = [];
  Object.keys(product).forEach((key: string, index: number) => {
    if (key.includes(`image_`)) if (product[key]) images.push(product[key]);
  });
  return images;
};

export const switchOnLanguage = (
  enValue: any,
  nlValue: any,
  lang: AppLanguage,
  strains?: any
): any => {
  if (strains) {
    if (strains.length > 0) {
      return strains
        .map((strain: any, index: number) => {
          return `${strain.name}${index + 1 !== strains?.length ? " - " : ""}`;
        })
        .join("");
    } else {
      if (lang == "en") {
        if (enValue) return enValue;
        else return nlValue;
      } else {
        return nlValue;
      }
    }
  } else {
    if (lang == "en") {
      if (enValue) return enValue;
      else return nlValue;
    } else {
      return nlValue;
    }
  }
};

export const mediaQueries = (): string => {
  const width = Dimensions.get("window").width;
  if (width <= mediaQueryTypes.smallPhone.width) {
    return mediaTypes.smallPhone;
  } else if (width <= mediaQueryTypes.mediumPhone.width) {
    return mediaTypes.mediumPhone;
  } else if (width <= mediaQueryTypes.largePhone.width) {
    return mediaTypes.largePhone;
  } else if (width <= mediaQueryTypes.tablet.width) {
    return mediaTypes.tablet;
  } else if (width <= mediaQueryTypes.largeTablet.width) {
    return mediaTypes.largeTablet;
  } else if (width <= mediaQueryTypes.smallDesktop.width) {
    return mediaTypes.smallDesktop;
  } else {
    return mediaTypes.desktop;
  }
};
