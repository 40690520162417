import { RenderOptions } from "../../../types";
import { Endpoints, ShopQuery } from "./RouteTypes";

export const SET_CONFIG = "SET_APP_CONFIG";
export const SET_VERSION = "SET_APP_VERSION";

export type BaseState = {
  version: string;
  config: ShopConfig;
  api: Endpoints;
  hideBackButton: boolean;
  baseRoutes: string[];
  renderOptions: RenderOptions;
};

export type ShopConfig = {
  [key: string]: {
    name: string;
    showShoppingCart: boolean;
  };
};

export type BaseAction =
  | {
      type: string;
      data: ShopConfig;
    }
  | {
      type: string;
      data: BaseState;
    }
  | {
      type: string;
      data: RenderOptions;
    }
  | {
      type: string;
      data: any;
    }
  | {
      type: string;
      data: boolean;
    };
