import { faChevronLeft, faQrcode } from "@fortawesome/free-solid-svg-icons";
// import { useIsFocused } from "@react-navigation/native";
import I18n from "i18n-js";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  View,
  useColorScheme,
} from "react-native";
import { Image } from "react-native-elements";

import Intro from "../../components/Intro";
import QRCodeScanner from "../../components/QrCodeScanner";
import { FontAwesomeIcon } from "../../components/Themed";
import {
  CHECKOUT_TEST,
  SHOP_ID,
  STORE_VERSION,
} from "../../constants/Constants";
import { RenderOptions, RootStackParamList } from "../../types";
import { ShopContext, ThemeContext } from "../../utils/contexts";
import { getTheme } from "../../utils/Utils";
import { getNumberOfCartItems, getShoppingCart } from "../../utils/Utils";

export default function QRCodeScannerScreen({
  renderCallback,
}: {
  renderCallback: (renderOptions: RenderOptions) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [introVisible, setIntroVisible] = useState(true);
  const colorTheme = useColorScheme();

  const shopContext = useContext(ShopContext);
  const themeContext = useContext(ThemeContext);

  const { setStyle, getStyle } = themeContext.helpers;
  const { styles } = themeContext.values;

  useEffect(() => {
    if (styles.length == 0) {
      setStyle("", {});
    }
  }, [styles]);

  const style = getStyle("QrCodeScannerScreen");
  const sheet = style?.sheet;

  const width = Dimensions.get("window").width / 1.4;
  const height = Dimensions.get("window").height / 2.5;

  const { setShopContext } = shopContext.helpers;
  const { theme } = shopContext.values;

  const currentTheme = getTheme(theme)[colorTheme];
  // const isFocused = useIsFocused();

  async function loadCart(shopId: string) {
    return await getShoppingCart(shopId);
  }

  const calculateAspectHeightRatio = (
    val: number,
    ratioWidth: number,
    ratioHeight: number
  ) => {
    return Math.round((val / ratioWidth) * ratioHeight);
  };

  const deviceWidth = calculateAspectHeightRatio(width * 2.7, 5, 2.2);
  const deviceHeight = calculateAspectHeightRatio(height * 2.7, 5, 2.1);

  // Ensure QR code scanner state is initial when screen is focused again
  // React.useEffect(() => {
  //   if (isFocused) {
  //     setLoading(false);
  //   }
  // }, [isFocused]);

  const navigateMississippi = async (
    shopId: string,
    tableId: string,
    cart: number
  ) => {
    setShopContext({
      theme: "mississippi",
      shopId: shopId,
      tableId: tableId,
      shoppingCartItems: cart,
    });
    renderCallback({
      renderQrScreen: false,
      renderWebQrScreen: false,
      renderDownloadAppScreen: false,
    });
  };

  const navigateEasyGoing = async (
    shopId: string,
    tableId: string,
    cart: number
  ) => {
    setShopContext({
      theme: "easyGoing",
      shopId: shopId,
      tableId: tableId,
      shoppingCartItems: cart,
    });
    renderCallback({
      renderQrScreen: false,
      renderWebQrScreen: false,
      renderDownloadAppScreen: false,
    });
  };

  const navigateTestShop = async (
    shopId: string,
    tableId: string,
    cart: number
  ) => {
    setShopContext({
      theme: "testShop",
      shopId: shopId,
      tableId: tableId,
      shoppingCartItems: cart,
    });
    renderCallback({
      renderQrScreen: false,
      renderWebQrScreen: false,
      renderDownloadAppScreen: false,
    });
  };

  const handleQrCode = (shopId: string, tableId: string) => {
    // shopContext.setShopContext("default", "", "", 0)

    setLoading(true);
    loadCart(shopId).then((cart) => {
      console.log("Loaded shopping cart with result:", cart);
      if (shopId === SHOP_ID) {
        navigateMississippi(
          shopId,
          tableId,
          cart ? getNumberOfCartItems(cart) : 0
        );
      } else if (shopId === "ddd70853-5e9d-45ff-82b3-24c9d81d4e32") {
        navigateEasyGoing(
          shopId,
          tableId,
          cart ? getNumberOfCartItems(cart) : 0
        );
      } else if (shopId === "66f15412-440f-4dcd-9f71-e2df4a7f9333") {
        navigateTestShop(
          shopId,
          tableId,
          cart ? getNumberOfCartItems(cart) : 0
        );
      } else {
        console.log(`Unsupported shopId`);
      }
    });
  };

  // @ts-ignore
  return (
    <View
      style={{
        ...sheet?.container,
        backgroundColor: currentTheme.qr.page.backgroundColor,
      }}
    >
      {introVisible && (
        <Intro
          backgroundColor={currentTheme.qr.page.backgroundColor}
          iconName={"aperture"}
          title={"Info icon"}
          handleClick={() => setIntroVisible(!introVisible)}
        />
      )}
      {!introVisible && (
        <View
          style={{
            ...sheet?.content,
            backgroundColor: currentTheme.qr.page.backgroundColor,
          }}
        >
          <View style={sheet?.header}>
            <View
              style={{
                ...sheet?.headerContent,
                backgroundColor: currentTheme.qr.design.backgroundColor,
              }}
            >
              <View style={sheet?.backIconContainer}>
                <FontAwesomeIcon
                  style={{
                    ...sheet?.backIcon,
                  }}
                  icon={faChevronLeft}
                  size={40}
                  onPress={() => setIntroVisible(!introVisible)}
                  color={currentTheme.qr.page.textColor}
                />
              </View>
              <View style={sheet?.qrCodeIcon}>
                <FontAwesomeIcon
                  style={{
                    justifyContent: "center",
                  }}
                  icon={faQrcode}
                  size={100}
                  color={currentTheme.qr.page.textColor}
                />
              </View>
            </View>
          </View>

          {Platform.OS !== "web" && (
            <View
              style={{
                ...sheet?.barcodeScanner,
                // width: width,
                // height: height,
                borderColor: currentTheme.qr.scan.borderColor,
              }}
            >
              {loading && <Text>Loading...</Text>}
              {!loading && (
                <QRCodeScanner
                  handleQrCode={handleQrCode}
                  width={deviceWidth}
                  height={deviceHeight}
                />
              )}
            </View>
          )}
          {CHECKOUT_TEST ? (
            <View>
              <Text style={{ margin: 5 }}>
                DEV_MODE active; so you can manually choose a shop:
              </Text>
              <View style={sheet?.button}>
                <Button
                  onPress={() =>
                    handleQrCode(
                      SHOP_ID,
                      "f9277df7-8116-488b-8f72-ba2e7de410fc"
                    )
                  }
                  title={"Mississippi"}
                />
              </View>
              <View style={sheet?.button}>
                <Button
                  onPress={() =>
                    handleQrCode(
                      "ddd70853-5e9d-45ff-82b3-24c9d81d4e32",
                      "da39d11a-79a7-4cea-ab89-52868e4b152a"
                    )
                  }
                  title={"Easy Going"}
                />
              </View>
              <View style={sheet?.button}>
                <Button
                  onPress={() =>
                    handleQrCode(
                      "66f15412-440f-4dcd-9f71-e2df4a7f9333",
                      "cd9d4dd1-6a0e-49e0-9f1f-f8e246237633"
                    )
                  }
                  title={"TEST SHOP"}
                />
              </View>
            </View>
          ) : (
            <View
              style={{
                ...sheet?.footer,
                backgroundColor: currentTheme.qr.design.bottomBackgroundColor,
              }}
            >
              <View
                style={{
                  ...sheet?.footerContent,
                  backgroundColor: currentTheme.qr.design.bottomBackgroundColor,
                }}
              >
                <Text
                  style={{
                    ...sheet?.info,
                    color: currentTheme.qr.page.textColor,
                  }}
                >
                  {" "}
                  {I18n.t("qrCodeScreen.info")}
                </Text>
                <View
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <Image
                    style={{
                      ...sheet?.logo,
                    }}
                    source={require("../../assets/images/icon_android.png")}
                  />
                </View>
              </View>
            </View>
          )}
        </View>
      )}
    </View>
  );
}
