// Interpolation works as follows:
//
// Make a key with the translation and enclose the variable with {{}}
// ie "Hello {{name}}" Do not add any spaces around the variable name.
// Provide the values as: I18n.t("key", {name: "John Doe"})
import I18n from "i18n-js";

I18n.translations.en = {
  code: "EN",
  name: "English",
  select_locale: "Select English",
  loading: "Loading...",
  EntityId: "",
  header: {
    shoppingCartPay: "Pay",
  },
  introScreen: {
    title: "Prijslijst - QR code info",
    text:
      "To use this app, you'll need to scan a QR code. You will find them near establishments or on websites that participate with the pricelist app.",
    scanButton: "Scan QR Code",
  },
  chooseProductKindPage: {
    title: "Choose a category",
  },
  SettingsScreen: {
    title: "Settings",
    currentLanguage: "Current language",
    currentTheme: "Current Theme",
  },
  shopPage: {
    title: "Prices",
    category: "Category",
    filter: "Filter",
  },
  drawerNavigation: {
    lastOrders: "Previous orders",
  },
  Screens: {
    Products: "Products",
    ProductInfo: "Products info",
    Settings: "Settings",
    Favorites: "Favorites",
    Checkout: "Checkout",
    AddToCart: "Add to cart",
    QrCodeScanner: "Scan QR code",
    ZoomImage: "Zoom photo",
  },
  productDetailScreen: {
    description: "Description",
    flavors: "Flavors",
    effects: "Effects",
    addToCart: "Add to cart",
    prices: "Prices",
    price: "Price",
    strains: "Strains",
  },
  checkoutPage: {
    title: "Checkout",
    tableHeader: "Products",
    productColumnName: "Item",
    priceColumnName: "Price",
    orderButton: "Order",
    orderStatus: "Order status",
    processingHeader: "Order in progress",
    customerOrderId: "Order-number",
    noTableFound:
      "No table found. Please scan the QR code of a table in the shop to continue.",
    orderProcessHelpText:
      "Patience, your order is being prepared. This page will reload when the order is ready.",
    MAX_5_GRAMS_ALLOWED:
      "The maximum amount of cannabis in an order is 5 grams!",
    OUT_OF_STOCK: "isn't in stock currently. Please remove and try again.",
    ORDER_OK: "Order succeeded!",
    table1: "Table 1",
    table2: "Table 2",
    table3: "Table 3",
    table4: "Table 4",
    table5: "Table 5",
    table6: "Table 6",
    table7: "Table 7",
    table8: "Table 8",
    table9: "Table 9",
    table10: "Table 10",
    resetCart: "Reset cart",
    total: "Total",
    reset: "Reset cart",
    browse: "Browse products",
    gramTotal: "Grams",
    subTotal: "Subtotal",
  },
  ordersScreen: {
    title: "Order status",
    total: "Total",
    cancelled: "Cancelled",
    pending: "Pending",
    complete: "Complete",
  },
  shoppingCartModal: {
    selectQuantity: "Select a quantity",
    add: "Add",
    close: "Close",
    title: "Order",
  },
  productsScreen: {
    piece: "per piece",
  },
  favoritesScreen: {
    title: "Favorites",
    resetFavorites: "Reset favorites",
    useSwipe: "use swipe to delete",
  },
  imageCarouselDetailScreen: {
    useSwipe: "use pinch to zoom",
  },
  qrCodeScreen: {
    info: "Scan a QR code to load a pricelist and order something",
  },
  imageCarouselScreen: {
    photo: "Photo",
  },
  filters: {
    reset: "Reset filters",
    favorites: "Favorite",
    cbd: "CBD",
    indica: "Indica",
    hybride: "Hybrid",
    sativa: "Sativa",
    new: "New",
  },
  cart: {
    list: {
      amount: "Nr: ",
    },
  },
  sorting: {
    califlower: "Califlowers",
    flower: "Flowers",
    hash: "Hash",
    specialties: "Specialties",
    smoke: "Smoking Supplies",
    food: "Food",
    fuzzyDrinks: "Soft drink",
    sweets: "Sweets",
    milkshakeNsmoothy: "Milkshakes & Smoothies",
    warmDrinks: "Hot beverage",
  },
  order: {
    list: {
      tableNr: "Nr: ",
      noOrders: "No orders were made yet",
    },
    table: {
      name: "Table",
    },
  },
  appStore: {
    title: "Download the app",
    subTitle: "Choose a restaurant, order, and eat!",
    button: {
      apple: {
        title: "App Store",
        subtitle: "Download on the",
      },
      google: {
        title: "Google Play",
        subtitle: "Get it on",
      },
    },
  },
};

export default I18n.translations.en;
