import { FontAwesome } from "@expo/vector-icons";
import {
  faMinusCircle,
  faPlusCircle,
  faShoppingBag,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useIsFocused, useRoute, useTheme } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import I18n from "i18n-js";
import * as React from "react";
import { useContext } from "react";
import { ActivityIndicator } from "react-native";
import { SwipeListView } from "react-native-swipe-list-view";

import CheckoutCard from "../../components/CheckoutCard";
import { FontAwesomeIcon, Text, View } from "../../components/Themed";
import { API_URL, CHECKOUT_TEST } from "../../constants/Constants";
import useColorScheme from "../../hooks/useColorScheme";
import {
  OrderResponse,
  OrderStatusItem,
  OrderTokenStore,
  Price,
  RootStackParamList,
  ShoppingCartItem,
} from "../../types";
import { ShopContext, ThemeContext } from "../../utils/contexts";
import {
  formatMoney,
  getFavorites,
  getNumberOfCartItems,
  getOrders,
  getShoppingCart,
  getTheme,
  resetShoppingCart,
  setFavorites,
  setOrders,
  setShoppingCartStore,
} from "../../utils/Utils";

export default function CheckoutScreen({
  navigation,
}: StackScreenProps<RootStackParamList, "NotFound">) {
  const route = useRoute();

  const shopContext = useContext(ShopContext);

  const { theme, shopId, tableId, shoppingCartItems } = shopContext.values;

  const { setShopContext } = shopContext.helpers;

  const themeContext = useContext(ThemeContext);
  const { getStyle } = themeContext.helpers;

  const styles = getStyle(route.name);
  const sheet = styles?.sheet;

  const isFocused = useIsFocused();
  const [shoppingCart, setShoppingCart] = React.useState<ShoppingCartItem[]>(
    []
  );
  const [orderMessage, setOrderMessage] = React.useState("");
  const [orderMessageType, setOrderMessageType] = React.useState("warning");
  const [checkoutToken, setCheckoutToken] = React.useState<
    undefined | OrderTokenStore
  >(undefined);
  const [customerOrderId, setCustomerOrderId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  async function loadCart() {
    const cart = await getShoppingCart(shopId);
    console.log("THE CART", cart);
    if (cart) {
      setShoppingCart(cart);
    }
  }

  React.useEffect(() => {
    loadCart();
  }, [isFocused]);

  const total = shoppingCart
    .map((item) => item.price * item.quantity)
    .reduce((a, b) => a + b, 0);

  let translatedOrderMessage = orderMessage;
  if (orderMessage !== "" && translatedOrderMessage.includes(",")) {
    const messageParts = translatedOrderMessage.split(",");
    const translationPart = I18n.t(`checkoutPage.${messageParts[1].trim()}`);
    translatedOrderMessage = `${messageParts[0]}, ${translationPart}`;
  } else if (orderMessage !== "") {
    translatedOrderMessage = I18n.t(`checkoutPage.${orderMessage}`);
  }

  const onChange = (id: any, quantity: number, sum: number) => {
    const index = findItemIndex(id);
    if (index == -1) return;
    shoppingCart[index].quantity = quantity;
    setShoppingCartStore(shoppingCart, shopId).then((res) => {
      // Update GUI
      // @ts-ignore
      setShoppingCart(shoppingCart);
      setShopContext({
        theme: theme,
        shopId: shopId,
        tableId: tableId,
        shoppingCartItems: getNumberOfCartItems(shoppingCart),
      });
      console.log("CARD STATE +> ", res);
    });
  };

  const findItemIndex = (id: any) => {
    return shoppingCart.findIndex((x: any) => {
      if (x.kind_id) {
        if (x.kind_id === id) {
          return x;
        }
      } else {
        if (x.product_id === id) {
          return x;
        }
      }
    });
  };

  const setTimedOrderMessage = (message: string, type: string = "warning") => {
    setOrderMessage(message);
    setOrderMessageType(type);
    setTimeout(() => {
      setOrderMessage("");
    }, 2000);
  };

  const createOrder = () => {
    if (total === 0) {
      return;
    }

    setIsLoading(true);
    // @ts-ignore
    const order_info = shoppingCart.map(({ key, ...keepAttrs }) => keepAttrs);
    console.log(order_info);
    const order = {
      shop_id: shopId,
      table_id: tableId,
      order_info: order_info,
      total: total,
    };

    fetch(`${API_URL}/v1/orders`, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    })
      .then((response) => response.json())
      .then((data: OrderResponse) => {
        if ("message" in data) {
          setTimedOrderMessage(data.message);
          setIsLoading(false);
        } else {
          setTimedOrderMessage("ORDER_OK", "info");
          const orderStatusItem: OrderStatusItem = {
            id: data.id,
            key: data.id,
            customer_order_id: data.customer_order_id,
            status: "pending",
            total: total,
            table_id: tableId,
          };
          handleAddOrder(orderStatusItem).then();
          resetShoppingCart(shopId).then();
          setShoppingCartStore([], shopId).then();
          setShopContext({
            theme: theme,
            shopId: shopId,
            tableId: tableId,
            shoppingCartItems: getNumberOfCartItems([]),
          });
          setTimeout(() => {
            setIsLoading(false);
            navigation.navigate("OrdersScreen");
          }, 2000);
        }
      });
  };

  const handleAddOrder = async (order: OrderStatusItem) => {
    let newOrders = await getOrders(shopId);
    if (!newOrders) {
      newOrders = [];
    }
    order.key = order.id;
    newOrders.push(order);
    if (newOrders.length > 10) {
      newOrders.shift();
    }
    await setOrders(shopId, newOrders);
  };

  const calculateTotalGrams = () => {
    /// TODO implement gram sum
  };

  const { colors } = useTheme();

  const colorTheme = useColorScheme();
  // const themeContext = useContext(ThemeContext);
  const currentTheme = getTheme(theme)[colorTheme];

  console.log("shoppingCart", shoppingCart);
  if (shoppingCart.length <= 0) {
    return (
      <View
        style={{
          justifyContent: "center",
          ...sheet?.container,
        }}
      ></View>
    );
  }

  return (
    <View style={sheet?.container}>
      <>
        {orderMessage !== "" && (
          <Text
            style={
              orderMessageType === "warning"
                ? sheet?.orderMessageWarning
                : sheet?.orderMessageInfo
            }
          >
            {translatedOrderMessage}
          </Text>
        )}
        <SwipeListView
          useFlatList
          style={sheet?.list}
          data={shoppingCart}
          renderItem={({ item }) => {
            return (
              <CheckoutCard
                item={item}
                kind_i={item?.kind_i}
                onChange={onChange}
                // title={item.kind_name ? item.kind_name : item.product_name}
                units={"€"}
              />
            );
          }}
          leftOpenValue={0}
          rightOpenValue={-75}
        />
        <View style={sheet?.line} />
        <View style={sheet?.overview}>
          <View style={sheet?.totalsRow}>
            <Text style={sheet?.gramsText}>{I18n.t("checkoutPage.total")}</Text>
            <Text style={sheet?.totalsText}>&euro; {formatMoney(total)}</Text>
          </View>
        </View>
        <View style={sheet?.orderButtonContainer}>
          <FontAwesome.Button
            style={sheet?.resetOrderButton}
            name="trash"
            borderRadius={50}
            backgroundColor={currentTheme.orderButtonBackground}
            onPress={() => {
              setShoppingCart([]);
              setShoppingCartStore([], shopId).then();
              setShopContext({
                theme: theme,
                shopId: shopId,
                tableId: tableId,
                shoppingCartItems: getNumberOfCartItems([]),
              });
            }}
          />
          <View style={{ marginRight: 10 }}></View>
          <FontAwesome.Button
            style={sheet?.previousOrderButton}
            name="history"
            borderRadius={50}
            backgroundColor={currentTheme.orderButtonBackground}
            onPress={() => navigation.navigate("OrdersScreen")}
          >
            {I18n.t("checkoutPage.orderStatus")}
          </FontAwesome.Button>
          <View style={{ marginRight: 10 }}></View>
          {isLoading && <ActivityIndicator />}
          {!isLoading && (
            <FontAwesome.Button
              style={sheet?.orderButton}
              name="shopping-cart"
              borderRadius={50}
              backgroundColor={currentTheme.orderButtonBackground}
              onPress={() => createOrder()}
            >
              {I18n.t("checkoutPage.orderButton")}
            </FontAwesome.Button>
          )}
        </View>
      </>
    </View>
  );
}
