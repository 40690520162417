import { CssSheet, mediaTypes } from "../../actions/types";

export const CardStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  queries: [
    {
      view: mediaTypes.smallPhone,
      sheet: {
        cardWrapper: {
          height: 100,
          paddingTop: 0,
        },
        cardImage: {
          width: 100,
          height: 100,
          borderRadius: 20,
          borderWidth: 1,
        },
        title: {
          fontSize: 16,
          fontFamily: "Roboto_400Regular",
        },
        ind: {
          fontSize: 12,
          marginTop: 0,
          marginRight: -8,
          borderRadius: 4,
          borderWidth: 1,
          borderStyle: "solid",
          padding: 3,
        },
        imageWrapper: {
          top: "5%",
          left: 5,
          width: "100%",
          height: "90%",
          position: "relative",
        },
        gramPrice: {
          fontSize: 12,
          marginLeft: 5,
          fontFamily: "Roboto_400Regular",
        },
        joint: {
          fontSize: 9,
          fontFamily: "Roboto_400Regular",
        },
        jointPrice: {
          position: "relative",
          fontSize: 14,
          fontFamily: "Roboto_400Regular",
        },
      },
    },
    {
      view: mediaTypes.mediumPhone,
      sheet: {
        cardWrapper: {
          height: 120,
          paddingTop: 0,
        },
        ind: {
          fontSize: 14,
          marginTop: 0,
          marginRight: -8,
          borderRadius: 4,
          borderWidth: 1,
          borderStyle: "solid",
          padding: 3,
        },
        imageWrapper: {
          top: "5%",
          left: 5,
          width: "100%",
          height: "90%",
          position: "relative",
        },
        gramPrice: {
          fontSize: 12,
          marginLeft: 5,
          fontFamily: "Roboto_400Regular",
        },
        joint: {
          fontSize: 9,
          fontFamily: "Roboto_400Regular",
        },
        jointPrice: {
          position: "relative",
          fontSize: 14,
          fontFamily: "Roboto_400Regular",
        },
      },
    },
  ],
  route: "Card",
  sheet: {
    flexContainer: {
      flex: 1,
      flexDirection: "row",
    },
    cardWrapper: {
      height: 120,
      paddingTop: 0,
    },
    card: {
      width: "100%",
      height: "100%",
      elevation: 18,
      overflow: "hidden",
      top: "1%",
    },
    image: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    rect: {
      height: "100%",
      width: "60%",
      marginLeft: 2,
      opacity: 1,
      justifyContent: "space-between",
    },
    imageWrapper: {
      top: "5%",
      width: "100%",
      height: "90%",
      position: "relative",
    },
    image1: {
      position: "relative",
      bottom: 10,
    },
    title: {
      fontSize: 16,
      fontFamily: "Roboto_400Regular",
    },
    subTitle: {
      position: "relative",
      fontSize: 10,
      paddingTop: "5%",
      fontFamily: "Roboto_400Regular",
    },
    loremIpsum: {
      left: 109,
      position: "relative",
      fontSize: 9,
      fontFamily: "Roboto_400Regular",
    },
    footerContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    ind: {
      fontSize: 14,
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: "solid",
      padding: 3,
    },
    gram: {
      fontSize: 9,
      fontFamily: "Roboto_400Regular",
    },
    gramPrice: {
      fontSize: 16,
      fontFamily: "Roboto_400Regular",
    },
    joint: {
      fontSize: 9,
      fontFamily: "Roboto_400Regular",
    },
    jointPrice: {
      position: "relative",
      fontSize: 14,
      fontFamily: "Roboto_400Regular",
    },
    text2Stack: {
      position: "relative",
    },
    containerWrapper: {
      width: "30%",
      position: "relative",
      justifyContent: "flex-start",
    },
  },
};

export const CardMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "Card",
  sheet: {
    flexContainer: {
      flex: 1,
      flexDirection: "row",
    },
    cardWrapper: {
      height: 120,
      paddingTop: 0,
    },
    card: {
      width: "100%",
      height: "100%",
      elevation: 18,
      overflow: "hidden",
      top: "1%",
    },
    image: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    rect: {
      left: "20%",
      height: "100%",
      width: "65%",
      marginLeft: 2,
      opacity: 1,
    },
    imageWrapper: {
      top: "5%",
      left: 12,
      width: "100%",
      height: "90%",
      position: "relative",
    },
    image1: {
      position: "relative",
      bottom: 10,
    },
    title: {
      fontSize: 17,
      fontFamily: "Roboto_400Regular",
    },
    subTitle: {
      marginTop: -8,
      fontSize: 14,
      fontFamily: "Roboto_400Regular",
    },
    loremIpsum: {
      left: 109,
      position: "relative",
      fontSize: 9,
      fontFamily: "Roboto_400Regular",
    },
    footerContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    ind: {
      marginTop: -5,
      marginRight: -4,
      fontSize: 12,
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: "solid",
      padding: 3,
    },
    amountController: {
      flex: 1,
      padding: 3,
    },
    amountContent: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlignVertical: "center",
    },
    gram: {
      fontSize: 11,
      fontFamily: "Roboto_400Regular",
    },
    gramPrice: {
      fontSize: 14,
      marginLeft: -10,
      fontFamily: "Roboto_400Regular",
    },
    joint: {
      fontSize: 11,
      fontFamily: "Roboto_400Regular",
    },
    jointPrice: {
      fontSize: 14,
      marginLeft: -10,
      fontFamily: "Roboto_400Regular",
    },
    text2Stack: {
      position: "relative",
    },
    containerWrapper: {
      width: "25%",
      position: "relative",
      justifyContent: "flex-start",
      zIndex: 2,
    },
  },
};
