import { CssSheet } from "../../actions/types";

export const SearchInputStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "SearchInputComponent",
  sheet: {
    wrapper: {
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      padding: 5,
    },
    searchContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: 5,
      width: "100%",
      height: "45%",
    },
    inputBox: {
      flexDirection: "row",
      alignItems: "flex-start",
      borderRadius: 20,
      width: "45%",
      height: "100%",
    },
    inputLeftIcon: {
      alignSelf: "center",
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 20,
    },
    inputStyle: {
      height: "100%",
      alignSelf: "flex-start",
      fontSize: 15,
      fontFamily: "Roboto_400Regular",
      lineHeight: 15,
      width: 168,
    },
    recordButton: {
      width: 24,
      height: 24,
      backgroundColor: "rgba(0,0,0,0.4)",
      borderRadius: 13,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      marginRight: 8,
    },
    inputRightIcon: {
      alignSelf: "center",
      opacity: 0.7,
      paddingRight: 5,
      paddingLeft: 5,
      color: "#FFF",
      fontSize: 20,
    },
    image: {
      paddingLeft: "5%",
      position: "relative",
    },
    imageClose: {
      paddingLeft: "5%",
      position: "relative",
    },
    weed: {
      position: "relative",
      color: "rgba(251,251,251,1)",
      fontSize: 18,
    },
  },
};

export const SearchInputMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "SearchInputComponent",
  sheet: {
    wrapper: {
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#000",
    },
    container: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "#000",
      height: "50%",
      padding: 5,
    },
    inputBox: {
      flexDirection: "row",
      alignItems: "flex-start",
      borderRadius: 20,
      width: "65%",
      height: "100%",
    },
    inputLeftIcon: {
      alignSelf: "center",
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 20,
    },
    inputStyle: {
      height: "100%",
      alignSelf: "flex-start",
      fontSize: 15,
      fontFamily: "Roboto_400Regular",
      lineHeight: 15,
      width: 168,
    },
    recordButton: {
      width: 24,
      height: 24,
      backgroundColor: "rgba(0,0,0,0.4)",
      borderRadius: 13,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      marginRight: 8,
    },
    inputRightIcon: {
      alignSelf: "center",
      opacity: 0.7,
      paddingRight: 5,
      paddingLeft: 5,
      color: "#FFF",
      fontSize: 20,
    },
    image: {
      paddingLeft: "5%",
      position: "relative",
    },
    imageClose: {
      paddingLeft: "5%",
      position: "relative",
    },
    weed: {
      position: "relative",
      color: "rgba(251,251,251,1)",
      fontSize: 18,
    },
  },
};
