import { CssSheet } from "../../actions/types";

export const AddToCartModalStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "AddToCartModalScreen",
  sheet: {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    productName: {
      fontSize: 20,
      fontWeight: "bold",
      marginTop: "5%",
    },
    title: {
      fontSize: 30,
    },
    marginButtons: {
      marginTop: 20,
    },
  },
};

export const AddToCartModalMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "AddToCartModalScreen",
  sheet: {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    productName: {
      fontSize: 20,
      fontWeight: "bold",
      marginTop: "5%",
    },
    title: {
      fontSize: 30,
    },
    marginButtons: {
      marginTop: 20,
    },
  },
};
