import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { encode } from "base-64";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  View,
  useColorScheme,
} from "react-native";
import { Image } from "react-native-elements";

import { FILTEROPTIONS } from "../constants/Constants";
import { Filter, Price } from "../types";
import { ShopContext, ThemeContext } from "../utils/contexts";
import { filterOnFavorite, filterOnKey } from "../utils/Filters";
import { getTheme, uuid } from "../utils/Utils";
import { Text } from "./Themed";
import { SectionListSet } from ".";

export const FilterList = ({
  setSearchFilters,
  setFilterOpened,
  selectedOption,
  data,
  favorites,
}: {
  setSearchFilters: (args: Price[], activeFilter: Filter) => void;
  setFilterOpened: Dispatch<SetStateAction<boolean>>;
  selectedOption: Filter;
  data: SectionListSet[];
  favorites: any[];
}) => {
  const colorTheme = useColorScheme();

  const options = {
    bucket: "images-prijslijst-info",
    key: "name",
    edits: {
      resize: {
        width: 100,
        height: 100,
        fit: "contain",
      },
    },
  };

  const strRequest = JSON.stringify(options);
  const encRequest = encode(strRequest);

  const shopContext = useContext(ShopContext);
  const themeContext = useContext(ThemeContext);

  const { shopId, theme } = shopContext.values;
  const { getStyle } = themeContext.helpers;

  const [filters] = useState<Filter[]>(FILTEROPTIONS(shopId));

  const currentTheme = getTheme(theme)[colorTheme];

  const [loading, setLoading] = useState<boolean>(false);
  const styles = getStyle("Filter");
  const sheet = styles?.sheet;

  const keyExtractor = useCallback((item: any) => {
    return item.id ? item.id.toString() : uuid();
  }, []);

  const onFilterPress = (item: Filter) => {
    if (item.key == "reset") {
      setSearchFilters([], item);
    }
    if (item.key == "fav") {
      filterOnFavorite(data, favorites)
        .then((value: Price[]) => {
          setSearchFilters(value, item);
        })
        .finally(() => {
          setFilterOpened(false);
        });
    } else {
      filterOnKey(item.key, item.value, data)
        .then((value: Price[]) => {
          setSearchFilters(value, item);
        })
        .finally(() => {
          setFilterOpened(false);
        });
    }
  };

  // deselect

  return (
    <View
      style={{
        ...sheet?.filter,
        backgroundColor: currentTheme.filter.list.backgroundColor,
        borderColor: currentTheme.filter.list.borderColor,
      }}
    >
      <FlatList
        style={sheet?.filterList}
        data={filters}
        keyExtractor={keyExtractor}
        renderItem={({ item, index, separators }) => (
          <TouchableOpacity
            key={`${uuid()}`}
            style={{
              backgroundColor:
                item.value == selectedOption.value
                  ? currentTheme.filter.options.backgroundColorSelected
                  : currentTheme.filter.options.backgroundColorNotSelected,
              marginHorizontal: 15,
              marginBottom: 5,
              padding: 10,
              zIndex: 4,
            }}
            onPress={() => onFilterPress(item)}
            disabled={
              !selectedOption ? false : item.value == selectedOption.value
            }
            onShowUnderlay={separators.highlight}
            onHideUnderlay={separators.unhighlight}
          >
            <View style={sheet?.filterField}>
              {typeof item.icon == "string" ? (
                <Image
                  source={{
                    uri: `https://d3sticxdmgvhkp.cloudfront.net/${encRequest}`,
                  }}
                  style={{
                    alignSelf: "center",
                    zIndex: 0,
                    marginTop: 0,
                    width: 40,
                    // Todo: Martin : fix product image for real -> uncommenting this leads to artefact on iOS
                    // height: Math.round(imageMap?.height || 0),
                    height: 40,
                    // borderRadius: imageMap?.borderRadius,
                    // borderWidth: imageMap?.borderWidth,
                    // borderColor: imageMap?.borderColor,
                  }}
                  onLoadEnd={() => {
                    // setIsLoading(false)
                  }}
                />
              ) : loading ? (
                <ActivityIndicator />
              ) : (
                <FontAwesomeIcon
                  icon={item.icon}
                  size={20}
                  //colorTheme
                  color={currentTheme.search.textColor}
                />
              )}
              <Text
                key={`${uuid()}`}
                style={{
                  color: currentTheme.filter.options.textColor,
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.title}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      />
    </View>
  );
};
