import { CssSheet } from "../../actions/types";

export const ActionButtonStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "ActionButton",
  sheet: {
    container: {
      flexDirection: "row",
      alignItems: "center",
      padding: 10,
      marginRight: -15,
      width: "100%",
      textAlign: "center",
    },
    containerFavorite: {
      padding: 10,
      marginRight: -15,
    },
    description: {
      backgroundColor: "transparent",
    },
    descTitle: {
      fontSize: 15,
      textAlign: "center",
      width: "35%",
    },
    price: {
      fontSize: 18,
      textAlign: "center",
      width: "35%",
    },
    quantity: {
      fontSize: 15,
      width: "33%",
      textAlign: "center",
    },
    actionButton: {
      width: "27%",
      marginRight: 0,
      textAlign: "center",
    },
    row: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  },
};

export const ActionButtonMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "ActionButton",
  sheet: {
    container: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: 10,
      width: "100%",
    },
    containerFavorite: {
      padding: 10,
    },
    description: {
      backgroundColor: "transparent",
    },
    descTitle: {
      fontSize: 15,
      textAlign: "right",
      justifyContent: "center",
      width: "35%",
    },
    currency: {
      fontSize: 18,
      textAlign: "right",
      marginRight: "4%",
      justifyContent: "flex-end",
      textAlignVertical: "center",
      width: "5%",
    },
    price: {
      fontSize: 18,
      textAlign: "left",
      justifyContent: "flex-start",
      textAlignVertical: "center",
      width: "25%",
    },
    quantity: {
      fontSize: 18,
      textAlign: "left",
      justifyContent: "flex-start",
      textAlignVertical: "center",
      marginRight: "2%",
      width: "8%",
    },
    quantityType: {
      fontSize: 18,
      textAlign: "left",
      justifyContent: "flex-start",
      textAlignVertical: "center",
      width: "30%",
    },
    actionButton: {
      width: "30%",
      justifyContent: "flex-start",
      textAlign: "left",
      textAlignVertical: "center",
    },
    row: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      textAlignVertical: "center",
      width: "100%",
    },
  },
};
