import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { TouchableOpacity } from "react-native";

import useColorScheme from "../hooks/useColorScheme";
import {
  FilterContext,
  ShopContext,
  ThemeContext as StyleContext,
} from "../utils/contexts";
import { ThemeContext } from "../utils/Theme";
import { formatMoney, getTheme, isFavoritePromise } from "../utils/Utils";
import { FontAwesomeIcon, Text, View } from "./Themed";

export function FavoriteActionButton({
  loadingIcon,
  isFavorite,
  feedbackTime,
  color,
  size,
  handleClick,
  navigate,
  waitForLoading,
  item,
  price,
  description,
}: {
  loadingIcon: any;
  feedbackTime: number;
  color?: string;
  size?: number;
  handleClick: any;
  navigate?: any;
  isFavorite?: boolean | null;
  waitForLoading?: boolean;
  item?: any;
  price?: any;
  description?: string;
  id?: string;
}) {
  const themeContext = useContext(ThemeContext);
  const filterContext = useContext(FilterContext);
  const { favorites } = filterContext.values;

  const styleContext = useContext(StyleContext);
  const { getStyle } = styleContext.helpers;
  const styles = getStyle("FavoriteActionButton");
  const sheet = styles?.sheet;

  const shopContext = useContext(ShopContext);
  const colorTheme = useColorScheme();

  const { shopId, theme } = shopContext.values;
  const currentTheme = getTheme(theme)[colorTheme];

  const [fav, setFav] = useState(isFavorite);

  useEffect(() => {
    isFavoritePromise(item, shopId).then((state) => {
      setFav(state);
    });
  }, [favorites]);

  const handleClickButton = () => {
    handleClick();
    setFav(!fav);
  };

  return (
    <TouchableOpacity
      style={sheet?.containerFavorite}
      onPress={() => {
        handleClickButton();
      }}
    >
      <View style={sheet?.row}>
        <FontAwesomeIcon
          style={{
            ...sheet?.actionButton,
          }}
          icon={fav ? faHeart : faHeartOutline}
          color={currentTheme.product.favorite.color}
          size={size}
        />
      </View>
    </TouchableOpacity>
  );
}
