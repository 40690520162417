import { CssSheet } from "../../actions/types";

export const CategoryStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "CategorySelectScreen",
  sheet: {
    // web
    view: {
      margin: 5,
      alignSelf: "center",
      borderRadius: 5,
      borderWidth: 3,
    },
    container: {
      width: "100%",
      height: "100%",
      // alignItems: "baseline",
      flex: 1,
      // overflow: "scroll",
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
    },
    backgroundImage: {
      flex: 1,
      resizeMode: "contain",
      justifyContent: "center",
    },
    subCategoryTitle: {
      fontWeight: "bold",
      fontSize: 12,
      textAlign: "center",
      justifyContent: "center",
      alignSelf: "center",
      paddingVertical: 0,
      paddingHorizontal: 0,
      textAlignVertical: "center",
      paddingTop: 10,
      height: 35,
      width: "100%",
    },
    button: {
      borderRadius: 100,
      height: 100,
      width: 100,
      alignItems: "center",
      justifyContent: "center",
    },
    row: {
      flexWrap: "wrap",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    wrapper: {
      flex: 1,
      flexDirection: "row",
      // overflow: "hidden"
    },
    categoryImage: {
      // alignSelf: "center",
      // marginVertical: 3,
    },
  },
};

export const CategoryMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android"],
  },
  route: "CategorySelectScreen",
  sheet: {
    view: {
      margin: 5,
      alignItems: "center",
      alignSelf: "center",
      borderRadius: 10,
      borderWidth: 3,
    },
    container: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    list: {
      width: "100%",
      marginLeft: 15,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
    },
    backgroundImage: {
      flex: 1,
      resizeMode: "contain",
      justifyContent: "center",
    },
    subCategoryTitle: {
      fontWeight: "bold",
      flexWrap: "wrap",
      fontSize: 12,
      textAlign: "center",
      justifyContent: "center",
      alignSelf: "center",
      margin: "auto",
      borderWidth: 0,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      height: 25,
      width: "100%",
    },
    button: {
      borderRadius: 100,
      height: 100,
      width: 100,
      alignItems: "center",
      justifyContent: "center",
    },
    row: {
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
    wrapper: {
      paddingTop: 20,
      paddingLeft: "5%",
    },
    categoryImage: {
      // alignSelf: "center",
      // marginVertical: 3,
    },
  },
};

export const CategoryMobileIosStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["ios"],
  },
  route: "CategorySelectScreen",
  sheet: {
    view: {
      margin: 7,
      alignItems: "center",
      alignSelf: "center",
      borderRadius: 10,
      borderWidth: 3,
    },
    container: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
      paddingTop: 20,
    },
    list: {
      width: "100%",
      marginLeft: 15,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
    },
    backgroundImage: {
      flex: 1,
      resizeMode: "contain",
      justifyContent: "center",
    },
    subCategoryTitle: {
      fontWeight: "bold",
      flexWrap: "wrap",
      fontSize: 12,
      textAlign: "center",
      justifyContent: "center",
      alignSelf: "center",
      margin: "auto",
      borderWidth: 0,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      height: 25,
      width: "100%",
    },
    button: {
      borderRadius: 100,
      height: 100,
      width: 100,
      alignItems: "center",
      justifyContent: "center",
    },
    row: {
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
    wrapper: {
      paddingTop: 20,
      paddingLeft: "5%",
    },
    categoryImage: {
      // alignSelf: "center",
      // marginVertical: 3,
    },
  },
};
