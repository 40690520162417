import { faHamburger, faHeartbeat } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useNavigation } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useContext, useEffect } from "react";
import {
  ActivityIndicator,
  FlatList,
  StatusBar,
  StyleSheet,
  useColorScheme,
} from "react-native";
import { useQuery } from "react-query";

import { Text, View } from "../components/Themed";
import { API_URL, CATEGORY_ICONS } from "../constants/Constants";
import { Price, StrainInfo } from "../types";
import { ShopContext } from "../utils/contexts";
import { LanguageContext } from "../utils/Language";
import { getTheme } from "../utils/Utils";

export interface MainCategory {
  id: string;
  name: string;
  name_en?: string;
  order_number: number;
  icon?: any;
  category_id?: string;
  category_color: string;
  category_image_1: number;
  category_image_2: number;
  // subCategories: SubCategory[] | undefined[];
}

export interface SubCategory {
  id: string;
  name: string;
  name_en?: string | null;
  order_number: number;
  icon?: string | null;
  main_category_id: string;
  category_id?: string;
  category_color: string;
  category_order_number: number;
  main_category_order_number: number;
  category_image_1: number;
  category_image_2: number;
  kind_name: string;
  product_name: string;
  active: boolean;
  image?: string | null;
  kind_product?: string;
  kind_id?: string | null;
  product_id?: string | null;
  internal_product_id: number | null;
  product_short_description_en?: string;
  product_short_description_nl?: string;
  kind_short_description_nl?: string;
  kind_short_description_en?: string;
  strains?: StrainInfo[];
  title?: string;
  subTitle?: string | string[];
  gramPrice?: string;
  jointPrice?: string;
  kind?: string;
  sortTitle?: string;
}

export interface FilterMainCategory {
  id: string;
  name: string | undefined;
  index: number;
  from: number;
  to: number;
}

export interface SectionListSet {
  title: SubCategory;
  data: Price[];
  index: number;
}

export interface SearchResult {
  mains: string[];
  categories: string[];
  ids: string[];
}

export interface SearchElement {
  main_id: string;
  category_id: string;
  id: string;
  index: number;
  searchable: String[];
}

export interface Searchable {
  [key: string]: String;
}

export function CategoryIconList() {
  const navigation = useNavigation<any>();

  const theme = useColorScheme();
  const shopContext = useContext(ShopContext);

  const { shopId } = shopContext.values;

  React.useEffect(() => {
    // navigation.navigate("ProductsScreen", );
  }, [shopId]);

  // @ts-ignore
  const currentTheme = getTheme(shopContext.theme)[theme];
  const languageContext = useContext(LanguageContext);

  const api = `${API_URL}/v1/shops/${shopId}`;
  console.log("Fetching", api);
  const { isLoading, data, error } = useQuery(`shopData-${shopId}`, () =>
    fetch(api).then((res) => res.json())
  );

  if (error) {
    // @ts-ignore
    return <Text>An error has occurred: {error.message}</Text>;
  }

  let mainCategoriesIDs: string[] = [];
  if (!isLoading) {
    mainCategoriesIDs = Array.from(
      new Set(data?.prices?.map((s: Price) => s.main_category_id))
    );
  }

  const mainCategories = mainCategoriesIDs.map(
    (i): MainCategory => {
      const category = data.prices.find((p: Price) => p.main_category_id === i);
      return {
        category_color: "",
        id: i,
        name: category.main_category_name,
        name_en: category.main_category_name_en,
        order_number: category.main_category_order_number,
        category_image_1: category.category_image_1,
        category_image_2: category.category_image_2,
      };
    }
  );

  // Enable only for debugging:

  let subCategoriesIDs: string[] = [];
  if (!isLoading) {
    subCategoriesIDs = Array.from(
      new Set(data.prices.map((s: Price) => s.category_id))
    );
  }

  const subCategories = subCategoriesIDs.map(
    (i): SubCategory => {
      const category = data.prices.find((p: Price) => p.category_id === i);
      return {
        id: i,
        name: category.category_name,
        name_en: category.category_name_en,
        order_number: category.category_order_number,
        icon: CATEGORY_ICONS.find(
          (icon) => icon.iconName === category.category_icon
        ),
        main_category_id: category.main_category_id,
        category_color: category.category_color,
      };
    }
  );

  // @ts-ignore
  return (
    <View style={styles.container}>
      <StatusBar barStyle={currentTheme.statusBar} />

      {!isLoading && (
        <FlatList
          numColumns={2}
          columnWrapperStyle={styles.row}
          data={mainCategories}
          renderItem={({ item, index }) => (
            <React.Fragment key={index}>
              {subCategories
                .filter((category) => category.main_category_id === item.id)
                .map((category, catIndex) => (
                  <View style={styles.containerSubCategory} key={catIndex}>
                    <Text
                      style={styles.subCategoryTitle}
                      onPress={() => {
                        navigation.navigate("ProductsScreen", {
                          categoryId: category.id,
                          categoryName: category.name,
                        });
                      }}
                    >
                      <View
                        style={[
                          styles.button,
                          { backgroundColor: category.category_color },
                        ]}
                      >
                        <FontAwesomeIcon
                          icon={
                            category.icon ? category.icon.icon : faHeartbeat
                          }
                          size={62}
                          color="white"
                        />
                      </View>
                      <View style={styles.alignCategoryText}>
                        <Text style={styles.subCategoryTitle}>
                          {languageContext.appLanguage === "nl"
                            ? category.name
                            : category.name_en
                            ? category.name_en
                            : category.name}
                        </Text>
                      </View>
                    </Text>
                  </View>
                ))}
            </React.Fragment>
          )}
        />
      )}
      {isLoading && <ActivityIndicator />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  containerSubCategory: {
    backgroundColor: "transparent",
    marginLeft: 15,
    marginTop: 10,
    width: 100,
  },
  list: {
    width: "100%",
    marginLeft: 15,
    marginTop: 25,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  subCategoryTitle: {
    fontWeight: "bold",
    fontSize: 12,
    width: 100,
    marginTop: 10,
    textAlign: "center",
  },
  button: {
    borderRadius: 100,
    height: 100,
    width: 100,
    alignItems: "center",
    justifyContent: "center",
  },
  row: {
    marginLeft: 3,
    flexWrap: "wrap",
  },
  alignCategoryText: {
    alignItems: "center",
  },
});
