import React, { useReducer } from "react";

import { RenderOptions } from "../../types";
import {
  set_config_dispatch,
  set_hide_button,
  set_render_options,
} from "../actions/dispatches/BaseActions";
import { BaseState, ShopConfig } from "../actions/types";
import BaseReducer, { initialBaseState } from "../reducers/BaseReducer";

export type BaseContextInterface = {
  values: BaseState;
  helpers: {
    setConfig: (config: ShopConfig) => void;
    setHideBackButton: (state: boolean) => void;
    setRenderOptions: (options: RenderOptions) => void;
  };
};

export const BaseContext = React.createContext<BaseContextInterface>({
  values: initialBaseState,
  helpers: {
    setConfig: (config: ShopConfig) => {},
    setHideBackButton: () => {},
    setRenderOptions: (options: RenderOptions) => {},
  },
});

// This provider will deside which version and config will the front end use
const BaseProvider = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [state, dispatch] = useReducer(BaseReducer, initialBaseState);

  const setConfig = (config: ShopConfig) => {
    dispatch(set_config_dispatch(config));
  };

  const setRenderOptions = (options: RenderOptions) => {
    dispatch(set_render_options(options));
  };

  const setHideBackButton = (state: boolean) => {
    // dispatch(set_hide_button(state));
  };

  return (
    <BaseContext.Provider
      value={{
        values: state,
        helpers: {
          setConfig: setConfig,
          setHideBackButton: setHideBackButton,
          setRenderOptions: setRenderOptions,
        },
      }}
    >
      {props.children}
    </BaseContext.Provider>
  );
};

export default BaseProvider;
