// reducer will manage the state based on actions
import { POP_ROUTE, PUSH_ROUTE, SET_STACK } from "../actions/types";
import { RouteAction, RouteState } from "../actions/types/RouteTypes";

export const initialRouteState: RouteState = {
  lastRoute: {
    name: "",
    params: {},
  },
  routerStackHistory: [],
  hideBackButton: true,
};

const RouteReducer = (
  state: RouteState = initialRouteState,
  action: RouteAction
): RouteState => {
  switch (action.type) {
    case PUSH_ROUTE:
      return {
        ...state,
        lastRoute: action.data,
      };
    case SET_STACK:
      return {
        ...state,
        routerStackHistory: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default RouteReducer;
