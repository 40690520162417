export const PUSH_ROUTE = "PUSH_ROUTE";
export const POP_ROUTE = "POP_ROUTE";
export const SET_LAST_ROUTE = "SET_LAST_ROUTE";
export const SET_STACK = "SET_STACK";
export const DISABLE_BACK = "DISABLE_BACK";
export const SET_RENDER_OPTIONS = "SET_RENDER_OPTIONS";

export type Route = {
  name: string;
  params: any;
};

export type RouteState = {
  lastRoute: Route;
  routerStackHistory: Route[];
  hideBackButton: boolean;
};

export type RouteAction =
  | {
      type: string;
      data: RouteState;
    }
  | {
      type: string;
      data: Route;
    }
  | {
      type: string;
      data: any;
    }
  | {
      type: string;
      data: boolean;
    };

export interface Endpoints {
  v1: {
    order: {
      check: {
        get: ShopQuery;
      };
      post: ShopQuery;
    };
    shops: {
      get: ShopQuery;
      key: string;
    };
    kinds: {
      get: ShopQuery;
    };
    products: {
      get: ShopQuery;
      key: string;
    };
  };
}

export class ShopQuery {
  public full_url: string | undefined;
  private root: string;
  private model: string;
  private params: string;
  private key: string;

  constructor(rootUrl: string) {
    this.root = rootUrl;
    this.model = this.root;
    this.params = "";
    this.key = "";
  }

  public route(route: string): ShopQuery {
    if (route[0] == "/") this.model = `${this.model}${route}`;
    else this.model = `${this.model}/${route}`;
    return this;
  }

  public id(id: string, hash: string = ":id"): ShopQuery {
    this.model = this.model.replace(hash, id);
    return this;
  }

  public setKey(k: string): ShopQuery {
    this.key = k;
    return this;
  }

  private validateForwardSlash(model: string, route: string): string {
    if (route[0] == "/") {
      if (model[model.length - 1] == "/") {
        model = `${model}${route.slice(0, 1)}`;
      }
    } else {
      if (model[model.length - 1] != "/") {
        model = `${model}${route}`;
      }
    }
    return model;
  }

  public urlExtension(route: string | string[]): ShopQuery {
    if (Array.isArray(route)) {
      for (let index = 0; index < route.length; index++) {
        if (index === 0) {
          this.validateForwardSlash(this.model, route[index]);
        } else {
          this.model = `${this.model},${route[index]}`;
        }
      }
    } else this.validateForwardSlash(this.model, route);
    return this;
  }

  public keyId(id: string, hash: string = ":id"): ShopQuery {
    this.key = this.key.replace(hash, id);
    return this;
  }

  public getKey(): string {
    return this.key;
  }

  public getUrl(): string {
    return this.model + this.params;
  }

  public clear(): ShopQuery {
    this.model = this.full_url ? this.full_url : this.root;
    return this;
  }

  public search(key: string, value: string): ShopQuery {
    if (this.model.includes("?")) {
      this.params = `${key}=${value}`;
    } else {
      this.params = `?${key}=${value}`;
    }
    return this;
  }
}

export class QueryParser {
  private query: ShopQuery;
  constructor(query: ShopQuery) {
    this.query = query;
  }

  public parse() {
    // this.includes();

    return this.query.getUrl();
  }
}
