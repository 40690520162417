import {
  faHeart as faHeartOutline,
  faMinusSquare,
  faPlusSquare,
} from "@fortawesome/free-regular-svg-icons";
import React, { useContext, useState } from "react";
import { ActivityIndicator, Text } from "react-native";

import useColorScheme from "../hooks/useColorScheme";
import { Price, ShoppingCartItem } from "../types";
import { ShopContext, ThemeContext } from "../utils/contexts";
import { formatMoney, getTheme } from "../utils/Utils";
import { FontAwesomeIcon, TouchableOpacity, View } from "./Themed";

interface IProps {
  item?: ShoppingCartItem;
  units?: string;
  kind_i: string;
  onChange: (id: any, quantity: number, sum: number) => void;
}

interface IPlaceholderProps {
  children:
    | boolean
    | JSX.Element
    | JSX.Element[]
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}

export const PlaceholderCard = ({ children }: IPlaceholderProps) => {
  const themeContext = useContext(ThemeContext);
  const { getStyle } = themeContext.helpers;

  const styles = getStyle("PlaceholderCard");
  const sheet = styles?.sheet;

  return <View style={[sheet?.card]}>{children}</View>;
};

function CheckoutCard(props: IProps) {
  const colorTheme = useColorScheme();
  const themeContext = useContext(ThemeContext);

  const shopContext = useContext(ShopContext);
  const { theme } = shopContext.values;
  const { getStyle } = themeContext.helpers;

  const styles = getStyle("CheckoutCard");
  const sheet = styles?.sheet;
  const currentTheme = getTheme(theme)[colorTheme];

  const [item, setItem] = useState(props.item);
  const [quantity, setQuantity] = useState(props.item?.quantity || 1);
  const [units] = useState(props.units);
  const [totalItemPrice, setTotalItemPrice] = useState(
    formatMoney(Number(props.item?.price) * quantity)
  );

  const increaseItemQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    const newItemTotal = formatMoney(
      Number(props.item?.price) * (quantity + 1)
    );
    setTotalItemPrice(newItemTotal);
    const id = item?.kind_id ? item?.kind_id : item?.product_id;
    console.log(id);
    props.onChange(id, newQuantity, Number(newItemTotal));
  };

  const decreaseItemQuantity = () => {
    const newQuantity = quantity - 1;
    setQuantity(newQuantity);
    const newItemTotal = formatMoney(
      Number(props.item?.price) * (quantity - 1)
    );
    setTotalItemPrice(newItemTotal);
    props.onChange(
      item?.kind_id ? item?.kind_id : item?.product_id,
      newQuantity,
      Number(newItemTotal)
    );
  };

  console.log("RERENDER", item?.price);

  if (item === undefined) {
    return <ActivityIndicator />;
  }

  return (
    <View
      style={{
        ...sheet?.container,
        backgroundColor: currentTheme.category.body.backgroundColor,
      }}
    >
      <View
        style={{
          ...sheet?.card,
          backgroundColor: currentTheme.card.backgroundColor,
        }}
      >
        <View
          style={{
            ...sheet?.flexContainer,
            backgroundColor: currentTheme.card.backgroundColor,
          }}
        >
          <Text
            style={{
              ...sheet?.quantityWrapper,
              color: currentTheme.card.textColor,
            }}
          >
            {quantity} x
          </Text>
          <View
            style={{
              ...sheet?.rect,
              backgroundColor: currentTheme.card.backgroundColor,
            }}
          >
            <View
              style={{
                ...sheet?.headerContainer,
                backgroundColor: currentTheme.card.backgroundColor,
              }}
            >
              <Text
                style={{
                  ...sheet?.title,
                  color: currentTheme.card.textColor,
                }}
              >
                {item.kind_name ? item.kind_name : item.product_name}
              </Text>
              <Text
                style={{
                  ...sheet?.gramPrice,
                  color: currentTheme.card.textColor,
                }}
              >
                {units} {totalItemPrice}
              </Text>
            </View>
            <Text
              style={{ ...sheet?.subTitle, color: currentTheme.card.textColor }}
            >
              {item?.description}
            </Text>

            <View
              style={{
                ...sheet?.footerContainer,
                backgroundColor: currentTheme.card.backgroundColor,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  decreaseItemQuantity();
                }}
                style={{
                  padding: "2%",
                  backgroundColor: currentTheme.card.favorite.backgroundColor,
                }}
              >
                <Text
                  style={{
                    ...sheet?.amountController,
                    color: currentTheme.card.textColor,
                    borderColor: currentTheme.card.textColor,
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      ...sheet?.amountContent,
                      borderColor: currentTheme.card.textColor,
                    }}
                    icon={faMinusSquare}
                    color={currentTheme.card.textColor}
                    size={35}
                  />
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  increaseItemQuantity();
                }}
                style={{
                  padding: "2%",
                  backgroundColor: currentTheme.card.favorite.backgroundColor,
                }}
              >
                <Text
                  style={{
                    ...sheet?.amountController,
                    color: currentTheme.card.textColor,
                    borderColor: currentTheme.card.textColor,
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      ...sheet?.amountContent,
                      borderColor: currentTheme.card.textColor,
                    }}
                    icon={faPlusSquare}
                    color={currentTheme.card.textColor}
                    size={35}
                  />
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

export default CheckoutCard;
