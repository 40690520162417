import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from "react-native";

import { Theme } from "../../../types";

export const SET_THEME = "SET_THEME";
export const SET_COLOR_THEME = "SET_COLOR_THEME";
export const SET_DIMENTION = "SET_DIMENTION";
export const SET_SHEET = "SET_SHEET";
export const SET_ORIENTATION = "SET_ORIENTATION";

export type PlatformState = {
  os: string[];
  viewPort?: string;
  isWeb: boolean;
  isAndroid: boolean;
  isIos: boolean;
  isWindows: boolean;
};

export const mediaTypes = {
  desktop: "desktop",
  smallDesktop: "smallDesktop",
  largeTablet: "largeTablet",
  tablet: "tablet",
  largePhone: "largePhone",
  mediumPhone: "mediumPhone",
  smallPhone: "smallPhone",
};

export type DisplaySize = {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
};

export type WindowSize = {
  width: number;
  height: number;
};

export type MobileWindowSize = {
  width: number;
  height: number;
  isPortrait: boolean;
};

export type ThemeColor = {
  pallet: Theme;
  isDark: boolean;
};

export type ThemeState = {
  platform: PlatformState;
  displaySize: WindowSize;
  mobileSize: MobileWindowSize;
  theme: ThemeColor;
  styles: CssSheet[];
};

export type CssMediaQuerySheet = {
  view: string;
  sheet: StyleSheet.NamedStyles<any>;
};

// export function create<T extends NamedStyles<T> | NamedStyles<any>>(styles: T | NamedStyles<T>): T
export type CssSheet = {
  sheet: StyleSheet.NamedStyles<any>;
  route: string;
  platform: PlatformState;
  queries?: (CssMediaQuerySheet | null)[];
};

export type ThemeAction =
  | {
      type: string;
      data: ThemeState;
    }
  | {
      type: string;
      data: ThemeColor;
    }
  | {
      type: string;
      data: WindowSize;
    }
  | {
      type: string;
      data: DisplaySize;
    }
  | {
      type: string;
      data: MobileWindowSize;
    }
  | {
      type: string;
      data: PlatformState;
    }
  | {
      type: string;
      data: CssSheet;
    }
  | {
      type: string;
      data: CssSheet[];
    }
  | {
      type: string;
      data: boolean;
    };
