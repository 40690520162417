import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React, { useContext } from "react";
import { View, useColorScheme, useWindowDimensions } from "react-native";
import { Text } from "react-native-elements";

import { INavigationFilter, Price } from "../types";
import { FilterContext, ShopContext, ThemeContext } from "../utils/contexts";
import { filterOnFavorite, filterOnKey } from "../utils/Filters";
import { getTheme } from "../utils/Utils";
import { TouchableOpacity } from "./Themed";

interface IProps {
  item: INavigationFilter;
}

export const NavigationFilterItem = ({ item }: IProps) => {
  const width = useWindowDimensions().width * 0.3;
  const shopContext = useContext(ShopContext);
  const themeContext = useContext(ThemeContext);
  const filterContext = useContext(FilterContext);

  const colorTheme = useColorScheme();

  const { categories, favorites } = filterContext.values;
  const { setResults } = filterContext.helpers;
  const { theme } = shopContext.values;
  const currentTheme = getTheme(theme)[colorTheme];
  // styles
  const { getStyle } = themeContext.helpers;
  const styles = getStyle("NavigationFilter");

  const sheet = styles?.sheet;

  // filter
  const onFilterPress = (key: string, value: string | boolean) => {
    if (key == "reset") {
      setResults([]);
    }
    if (key == "fav") {
      filterOnFavorite(categories, favorites)
        .then((value: Price[]) => {
          setResults(value);
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      filterOnKey(key, value, categories)
        .then((value: Price[]) => {
          setResults(value);
        })
        .finally(() => {
          // setLoading(false);
        });
    }
  };

  return (
    <TouchableOpacity
      style={{
        ...sheet?.menuContainer,
        marginTop: 10,
        backgroundColor: currentTheme.navigation.navigationItemBackgroundColor,
      }}
      key={`navigation_filter_${item.id}`}
      onPress={() => onFilterPress(item.filterKey, item.filterValue)}
    >
      <View
        style={[
          sheet?.menuItemsCard,
          {
            backgroundColor: item.wrapper.backgroundColor
              ? item.wrapper.backgroundColor
              : currentTheme.navigation.navigationItemBackgroundColor,
            width: "100%",
            height: width / 2,
          },
        ]}
      >
        <View style={[sheet?.circleContainer]}>
          <FontAwesomeIcon
            icon={item.icon.name}
            size={item.icon.size}
            color={
              item.icon.color
                ? item.icon.color
                : currentTheme.navigation.navigationItemIconColor
            }
          />
        </View>
        <View style={[sheet?.circleContainer]}>
          <Text
            style={{
              fontSize: item.text.fontSize,
              color: item.text.color
                ? item.text.color
                : currentTheme.navigation.navigationItemTextColor,
            }}
          >
            {item.text.input}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};
