import { CssSheet } from "../../actions/types";

export const CannabisProductDetailStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "ProductDetail",
  sheet: {
    actionButton: {
      flex: 1,
      flexDirection: "row",
    },
    container: {
      flex: 1,
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
    },
    productImage: {
      height: 371,
    },
    carouselImage: {
      height: 371,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      marginTop: "3%",
      marginBottom: "3%",
      justifyContent: "center",
    },
    favoriteButton: {
      marginLeft: 30,
      justifyContent: "flex-end",
    },
    subTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginTop: "10%",
      marginLeft: "5%",
    },
    link: {
      marginTop: 15,
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      color: "#2e78b7",
    },
    button: {
      alignItems: "center",
      padding: 10,
      borderRadius: 25,
      marginTop: "5%",
    },
    buttonText: {
      fontWeight: "bold",
    },
    descriptionShort: {
      width: "80%",
      textAlign: "center",
      marginTop: "3%",
      marginLeft: "10%",
    },
    tag: {
      position: "relative",
      backgroundColor: "gray",
      borderRadius: 300,
      marginRight: 30,
      width: 65,
      height: 30,
      left: 0,
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    progressBar: {
      marginTop: 20,
      width: "100%",
      height: 100,
      marginRight: 30,
      marginLeft: "5%",
    },
    progressBarText: {
      marginBottom: -20,
      marginTop: 15,
      marginLeft: "5%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    separator2: {
      height: 1,
      width: "100%",
    },
    marginButtons: {
      marginTop: 20,
    },
    flavorsRow: {
      flex: 1,
      flexDirection: "row",
      width: "90%",
      marginLeft: "5%",
      alignItems: "center",
      justifyContent: "center",
    },
    flavorsIconRows: {
      flex: 1,
      flexDirection: "row",
      margin: 20,
      backgroundColor: "pink",
    },
    row: {
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "3%",
    },
    list: {
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: "3%",
    },
    addButtons: {
      width: "90%",
      alignItems: "center",
      justifyContent: "center",
    },
    flavor: {
      padding: 20,
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    flavorText: {
      paddingTop: 10,
    },
  },
};

export const CannabisProductDetailMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "ProductDetail",
  sheet: {
    ActionButton: {
      flex: 1,
      flexDirection: "row",
    },
    container: {
      flex: 1,
    },
    productImage: {
      height: 371,
    },
    carouselImage: {
      height: 371,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      marginTop: "3%",
      marginBottom: "3%",
      justifyContent: "flex-start",
    },
    subTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginTop: "10%",
      marginLeft: "5%",
    },
    favoriteButton: {
      marginLeft: "10%",
      justifyContent: "center",
    },
    link: {
      marginTop: 15,
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      color: "#2e78b7",
    },
    button: {
      alignItems: "center",
      padding: 10,
      borderRadius: 25,
      marginTop: "5%",
    },
    buttonText: {
      fontWeight: "bold",
    },
    descriptionShort: {
      width: "80%",
      textAlign: "center",
      marginTop: "3%",
      marginLeft: "10%",
    },
    tag: {
      position: "relative",
      backgroundColor: "gray",
      borderRadius: 300,
      width: 65,
      height: 30,
      marginRight: "10%",
      left: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    progressBar: {
      marginTop: 20,
      width: "90%",
      height: 5,
      minHeight: 50,
      marginLeft: "5%",
      marginRight: "5%",
    },
    progressWraper: {
      flex: 1,
    },
    progressBarText: {
      marginBottom: -20,
      marginTop: 15,
      marginLeft: "5%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    separator2: {
      height: 1,
      width: "100%",
    },
    marginButtons: {
      marginTop: 20,
    },
    flavorsRow: {
      flex: 1,
      flexDirection: "row",
      width: "90%",
      marginLeft: "5%",
      alignItems: "center",
      justifyContent: "center",
    },
    flavorsIconRows: {
      margin: 20,
      backgroundColor: "pink",
    },
    row: {
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "3%",
    },
    list: {
      width: "100%",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: "3%",
    },
    lastElement: {
      paddingBottom: 0,
    },
    addButtons: {
      width: "90%",
      alignItems: "center",
      justifyContent: "center",
    },
    flavor: {
      padding: 10,
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    icon: {
      width: 200,
    },
    flavorText: {
      paddingTop: 10,
    },
  },
};
