import { CssSheet } from "../../actions/types";

export const CannabisProductNewDetailStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "ProductDetailNew",
  sheet: {
    actionButton: {
      flex: 1,
      flexDirection: "row",
    },
    container: {
      flex: 1,
    },
    productImage: {
      height: 371,
    },
    carouselImage: {
      height: 371,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      marginTop: "3%",
      marginBottom: "3%",
      marginLeft: "5%",
      justifyContent: "center",
      fontFamily: "Roboto_400Regular",
    },
    favoriteButton: {
      marginLeft: 30,
      justifyContent: "flex-end",
    },
    subTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginTop: "10%",
      marginLeft: "5%",
    },
    link: {
      marginTop: 15,
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      color: "#2e78b7",
    },
    button: {
      alignItems: "center",
      padding: 10,
      borderRadius: 25,
      marginTop: "5%",
    },
    buttonText: {
      fontWeight: "bold",
    },
    descriptionShort: {
      width: "80%",
      textAlign: "center",
      marginLeft: "10%",
    },
    tag: {
      position: "relative",
      backgroundColor: "gray",
      borderRadius: 300,
      marginRight: 30,
      width: 65,
      height: 30,
      left: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    progressBar: {
      marginTop: 20,
      width: "90%",
      marginLeft: "5%",
    },
    progressBarText: {
      marginBottom: -20,
      marginTop: 15,
      marginLeft: "5%",
      fontFamily: "Roboto_400Regular",
    },
    separator2: {
      height: 1,
      width: "100%",
    },
    marginButtons: {
      marginTop: 20,
    },
    flavorsRow: {
      flex: 1,
      flexDirection: "row",
      width: "90%",
      marginLeft: "5%",
      alignItems: "center",
      justifyContent: "center",
    },
    flavorsIconRows: {
      flex: 1,
      flexDirection: "row",
      margin: 20,
      backgroundColor: "pink",
    },
    row: {
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "3%",
    },
    list: {
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
      marginTop: "3%",
    },
    addButtons: {
      width: "90%",
      alignItems: "center",
      justifyContent: "center",
    },
    titleWrapper: {
      margin: 0,
    },
    flavor: {
      padding: 20,
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    flavorText: {
      paddingTop: 10,
      fontFamily: "Roboto_400Regular",
    },
  },
};

export const CannabisProductNewDetailMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "ProductDetailNew",
  sheet: {
    ActionButton: {
      flex: 1,
      flexDirection: "row",
    },
    container: {
      flex: 1,
    },
    productImage: {
      height: 371,
    },
    carouselImage: {
      height: 371,
    },
    title: {
      fontSize: 24,
      fontWeight: "bold",
      justifyContent: "center",
      fontFamily: "Roboto_400Regular",
    },
    subTitle: {
      fontSize: 22,
      fontWeight: "bold",
      marginTop: "10%",
      marginLeft: "5%",
      alignItems: "flex-end",
      justifyContent: "flex-start",
      fontFamily: "Roboto_400Regular",
    },
    favoriteButton: {
      justifyContent: "center",
      marginRight: 20,
    },
    link: {
      marginTop: 15,
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      color: "#2e78b7",
      fontFamily: "Roboto_400Regular",
    },
    button: {
      alignItems: "center",
      padding: 10,
      borderRadius: 25,
      marginTop: "5%",
    },
    buttonText: {
      fontWeight: "bold",
      fontFamily: "Roboto_400Regular",
    },
    descriptionShort: {
      width: "80%",
      textAlign: "left",
      overflow: "hidden",
    },
    tag: {
      position: "relative",
      borderRadius: 300,
      width: 85,
      height: 40,
      marginLeft: 20,
      marginRight: 20,
      justifyContent: "center",
      alignItems: "center",
    },
    progressBar: {
      marginTop: 20,
      width: "90%",
      height: 13,
      borderRadius: 30,
      marginLeft: "5%",
    },
    progressBarText: {
      marginBottom: -20,
      marginTop: 15,
      justifyContent: "center",
      textAlign: "center",
      fontFamily: "Roboto_400Regular",
    },
    separator2: {
      height: 1,
      width: "100%",
    },
    marginButtons: {
      marginTop: 20,
    },
    flavorsRow: {
      flex: 1,
      flexDirection: "row",
      width: "90%",
      marginLeft: "5%",
      alignItems: "center",
      justifyContent: "center",
    },
    favoriteWrapper: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    row: {
      flex: 1,
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "3%",
    },
    rowEnd: {
      flex: 1,
      flexDirection: "row",
      width: "100%",
      paddingRight: "6%",
      justifyContent: "flex-end",
    },
    titleWrapper: {
      margin: 20,
      width: "80%",
    },
    list: {
      width: "100%",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      marginTop: "3%",
    },
    lastElement: {
      paddingBottom: 0,
    },
    addButtons: {
      width: "90%",
      alignItems: "center",
      justifyContent: "center",
    },
    flavor: {
      padding: 15,
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    flavorText: {
      paddingTop: 10,
    },
  },
};
