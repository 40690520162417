import { CssSheet } from "../../actions/types";

export const SettingsScreenStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "SettingsScreen",
  sheet: {
    container: {
      height: "100%",
      padding: 20,
    },
    button: {
      marginTop: 10,
    },
    buttonReset: {
      marginTop: 20,
      fontWeight: "bold",
    },
    title: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 22,
      marginBottom: 10,
    },
    text: {
      fontSize: 14,
      fontWeight: "bold",
      marginTop: 30,
    },
    content: {
      alignItems: "center",
      justifyContent: "center",
    },
    flags: {
      fontSize: 60,
      marginTop: "2%",
    },
    flagsContainer: {
      flexDirection: "row",
    },
  },
};

export const SettingsScreenMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "SettingsScreen",
  sheet: {
    container: {
      height: "100%",
      padding: 20,
    },
    button: {
      marginTop: 10,
    },
    buttonReset: {
      marginTop: 20,
      fontWeight: "bold",
    },
    title: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 22,
      marginBottom: 10,
    },
    text: {
      fontSize: 14,
      fontWeight: "bold",
      marginTop: 30,
    },
    content: {
      alignItems: "center",
      justifyContent: "center",
    },
    flags: {
      fontSize: 60,
      marginTop: "2%",
    },
    flagsContainer: {
      flexDirection: "row",
    },
  },
};
