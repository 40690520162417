import { CssSheet } from "../../actions/types";

export const OrderNavStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "OrderNavList",
  sheet: {
    orderListItem: {
      padding: "2%",
    },
  },
};

export const OrderNavMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "OrderNavList",
  sheet: {
    orderListContainer: {
      flex: 1,
      flexDirection: "column",
    },
    orderListItem: {
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "flex-start",
    },
    orderNrItem: {
      // alignItems: "center"
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "flex-start",
    },
  },
};
