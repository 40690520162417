import React, { useContext, useState } from "react";
import { TouchableOpacity } from "react-native";

import useColorScheme from "../hooks/useColorScheme";
import { ShopContext, ThemeContext as StyleContext } from "../utils/contexts";
import { ThemeContext } from "../utils/Theme";
import { formatMoney, getTheme } from "../utils/Utils";
import { FontAwesomeIcon, Text, View } from "./Themed";

export default function ActionButton({
  iconName,
  loadingIcon,
  iconAfterClick,
  feedbackTime,
  color,
  quantity,
  size,
  handleClick,
  navigate,
  waitForLoading,
  item,
  price,
  description,
  favorite,
}: {
  iconName: any;
  loadingIcon: any;
  iconAfterClick?: any;
  feedbackTime: number;
  color?: string;
  size?: number;
  quantity?: string;
  handleClick: any;
  navigate?: any;
  waitForLoading?: boolean;
  item?: any;
  price?: any;
  description?: string;
  favorite?: boolean;
}) {
  const [clicked, setClicked] = useState(false);
  const [iconIsClicked, setIconIsClicked] = useState(false);

  const colorTheme = useColorScheme();
  const themeContext = useContext(ThemeContext);

  const styleContext = useContext(StyleContext);
  const shopContext = useContext(ShopContext);
  const { getStyle } = styleContext.helpers;
  const styles = getStyle("ActionButton");
  const sheet = styles?.sheet;

  const { theme } = shopContext.values;
  const currentTheme = getTheme(theme)[colorTheme];

  const { mobileSize } = styleContext.values;

  const handleClickButton = () => {
    {
      waitForLoading
        ? setTimeout(() => {
            handleClick();
            {
              navigate && navigate();
            }
          }, feedbackTime)
        : handleClick();
    }
    {
      iconAfterClick && setIconIsClicked(true);
    }
  };

  return (
    <TouchableOpacity
      style={favorite ? sheet?.containerFavorite : sheet?.container}
      onPress={() => {
        setClicked(true);
        setTimeout(() => {
          setClicked(false);
        }, feedbackTime);
        handleClickButton();
      }}
    >
      <View
        style={{
          ...sheet?.row,
          width: mobileSize.width,
        }}
      >
        {description && (
          <View
            style={{
              ...sheet?.row,
              backgroundColor: currentTheme.product.price.background,
              width: mobileSize.width,
            }}
          >
            <Text style={sheet?.quantity}>{quantity}</Text>
            <Text style={sheet?.quantityType}>{description}</Text>
            <Text style={sheet?.currency}>€</Text>
            <Text style={sheet?.price}>{formatMoney(item[price])}</Text>
            {!clicked ? (
              <FontAwesomeIcon
                style={{
                  ...sheet?.actionButton,
                }}
                icon={iconIsClicked ? iconAfterClick : iconName}
                color={currentTheme.product.favorite.color}
                size={size}
              />
            ) : (
              <FontAwesomeIcon
                style={sheet?.actionButton}
                icon={loadingIcon}
                color={currentTheme.product.favorite.color}
                size={size}
              />
            )}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}
