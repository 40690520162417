import { CssSheet, mediaTypes } from "../../actions/types";

export const HeaderStyles: CssSheet = {
  platform: {
    isAndroid: false,
    isIos: false,
    isWeb: true,
    isWindows: false,
    os: ["web"],
  },
  route: "Header",
  queries: [
    {
      view: mediaTypes.smallPhone,
      sheet: {
        headerContainer: {
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        },
        logoContainer: {
          alignSelf: "center",
          alignItems: "flex-end",
        },
        burgerMenu: {
          alignSelf: "center",
          alignItems: "center",
          marginLeft: "8%",
          width: "10%",
        },
        shoppingCart: {
          alignSelf: "center",
          alignItems: "center",
          width: "10%",
        },
        logo: {
          width: "50%",
          height: "50%",
          paddingLeft: "5%",
          paddingRight: "5%",
        },
      },
    },
    {
      view: mediaTypes.mediumPhone,
      sheet: {
        headerContainer: {
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        },
        logoContainer: {
          alignSelf: "center",
          alignItems: "flex-end",
        },
        burgerMenu: {
          alignSelf: "center",
          alignItems: "flex-end",
          marginLeft: "3%",
          width: "10%",
        },
        shoppingCart: {
          alignSelf: "center",
          alignItems: "flex-end",
          paddingRight: "2%",
          width: "10%",
        },
        logo: {
          width: "50%",
          height: "50%",
        },
      },
    },
    {
      view: mediaTypes.largePhone,
      sheet: {
        headerContainer: {
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        },
        logoContainer: {
          alignSelf: "center",
          alignItems: "flex-end",
        },
        burgerMenu: {
          alignSelf: "center",
          alignItems: "flex-end",
          marginLeft: "3%",
          width: "10%",
        },
        shoppingCart: {
          alignSelf: "center",
          alignItems: "flex-end",
          paddingRight: "2%",
          width: "10%",
        },
        logo: {
          width: "50%",
          height: "50%",
        },
      },
    },
  ],
  sheet: {
    logo: {
      width: "80%",
      height: "80%",
      maxHeight: 50,
    },
    leftIcon: {
      marginLeft: "5%",
    },
    rightIcon: {
      padding: "5%",
      position: "relative",
    },
    headerContainer: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-evenly",
      width: "100%",
    },
    burgerMenu: {
      alignItems: "center",
      width: "10%",
    },
    shoppingCart: {
      alignItems: "center",
      width: "10%",
    },
    badge: {
      position: "absolute",
      top: "40%",
      left: "40%",
    },
    logoContainer: {
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
};

export const HeaderMobileStyles: CssSheet = {
  platform: {
    isAndroid: true,
    isIos: true,
    isWeb: false,
    isWindows: false,
    os: ["android", "ios"],
  },
  route: "Header",
  sheet: {
    logo: {
      width: "80%",
      height: "80%",
      marginBottom: 5,
    },
    leftIcon: {},
    wrapper: {
      justifyContent: "center",
      // alignItems: "center",
      // alignContent: 'center',
      // alignSelf: 'center',
    },
    rightIcon: {
      padding: "5%",
      position: "relative",
    },
    headerContainer: {
      flex: 1,
      flexDirection: "row",
      width: "100%",
    },
    burgerMenu: {
      justifyContent: "center",
      alignItems: "flex-start",
      paddingLeft: "3%",
    },
    shoppingCart: {
      justifyContent: "center",
      // alignItems: "center",
      textAlignVertical: "center",
      marginLeft: -5,
    },
    badge: {
      position: "absolute",
      margin: 20,
      // top: "40%",
      // left: "40%",
    },
    logoContainer: {
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
};
